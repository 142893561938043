import React, { useState } from 'react';
// import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from 'src/assets/images/icons/search-settings.svg';

// Styled MUI components
const SearchInput = styled(InputBase)({
  flex: 1,
  fontSize: '16px',
  marginLeft: '16px',
  height: '42px',
  '& input': {
    padding: '8px 0',
    '&::placeholder': {
      color: '#9E9E9E',
      opacity: 1,
    },
  },
});

interface LegalEntitiesActionsProps {
  onSearch?: (query: string) => void;
  // onSave?: () => void;
}

export const LegalEntitiesActions: React.FC<LegalEntitiesActionsProps> = ({
  onSearch,
  // onSave,
}) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchValue = event.target.value;
    setSearchQuery(newSearchValue);

    // Automatically search as the user types
    if (onSearch) {
      onSearch(newSearchValue);
    }
  };

  const handleClearSearch = () => {
    setSearchQuery('');
    if (onSearch) {
      onSearch('');
    }
  };

  return (
    <div className='flex justify-between items-center w-full mb-6 gap-24'>
      <div className='flex-1 flex items-center w-[320px] h-[44px] bg-white rounded-[8px] border border-neutral-20 px-10'>
        <div className='flex items-center justify-center w-20'>
          <img src={SearchIcon} alt='Search' className='w-20' />
        </div>
        <SearchInput placeholder='Search by name' value={searchQuery} onChange={handleSearchChange} fullWidth />
        {searchQuery && (
          <IconButton aria-label='clear search' onClick={handleClearSearch} size='small' edge='end' sx={{ mr: 1 }}>
            <ClearIcon fontSize='small' />
          </IconButton>
        )}
      </div>
      {/* <Button 
        variant="contained" 
        color="primary"
        size="large"
        onClick={onSave}
      >
        Save Update
      </Button> */}
    </div>
  );
};
