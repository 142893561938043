/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ResponseDtoObject {
  success?: boolean;
  message?: string;
  data?: object;
  /** @format int32 */
  statusCode?: number;
}

export interface AddressDto {
  address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  country?: string;
  email?: string;
  fax?: string;
  phone?: string;
  locationName?: string;
}

export interface ChargeMessageDto {
  name?: string;
  quantity?: number;
  currency?: string;
  unitPrice?: number;
  exchangeRate?: number;
  amountBeforeTax?: number;
  amountAfterTax?: number;
  taxAmount?: number;
  discountAmount?: number;
  taxPercentage?: number;
  code?: string;
  chargeUnit?: string;
}

export interface InvoiceBankDetailMessageDto {
  bankName?: string;
  bankBranch?: string;
  swiftCode?: string;
  accountName?: string;
  accountNumber?: string;
}

export interface InvoiceMessageDto {
  invoiceNumber?: string;
  /** @format date */
  invoiceDate?: string;
  /** @format date */
  dueDate?: string;
  terms?: string;
  accountNo?: string;
  jobNumber?: string;
  businessUnit?: string;
  modality?: string;
  customerCode?: string;
  serviceProviderName?: string;
  serviceProviderAddresses?: AddressDto[];
  billedPartyName?: string;
  billedPartyAddresses?: AddressDto[];
  preparedBy?: string;
  notes?: string;
  important?: string;
  remarks?: string;
  invoiceCurrency?: string;
  totalAmountBeforeTax?: number;
  totalTaxAmount?: number;
  totalAmountAfterTax?: number;
  totalAmountAfterTaxInWords?: string;
  bankDetails?: InvoiceBankDetailMessageDto[];
  invoiceFileLink?: string;
  /** @format uuid */
  pipelineId?: string;
  configId?: string;
  incoterm?: string;
  pickUpPoint?: string;
  deliveryPoint?: string;
  totalAmountDiscount?: number;
  shipments?: ShipmentMessageDto[];
  salesPerson?: string;
  customerReference?: string;
  poNumber?: string;
  fapNumber?: string;
  companyRegistrationNumber?: string;
  gstRegistrationNumber?: string;
  lotNumber?: string;
}

export interface ShipmentMessageDto {
  internalTrackingNumber?: string;
  shipmentNumber?: string;
  shipperName?: string;
  shipperAddresses?: AddressDto[];
  consigneeName?: string;
  consigneeAddresses?: AddressDto[];
  placeOfLoading?: string;
  placeOfDischarge?: string;
  polAlias?: string;
  podAlias?: string;
  transportationReferenceNumber?: string;
  carrier?: string;
  /** @format int32 */
  numberOfPackages?: number;
  numberOfPackagesDescription?: string;
  serviceType?: string;
  specialType?: string;
  commodity?: string;
  containerNumbers?: string[];
  /** @format date */
  estimatedDepartureDate?: string;
  /** @format date */
  estimatedArrivalDate?: string;
  masterBillOfLading?: string;
  houseBillOfLading?: string;
  grossWeightInKg?: number;
  grossWeight?: string;
  chargeableWeightInKg?: number;
  chargeableWeight?: string;
  volumeInCbm?: number;
  netWeightInKg?: number;
  netWeight?: string;
  charges?: ChargeMessageDto[];
}

export interface ResponseDtoString {
  success?: boolean;
  message?: string;
  data?: string;
  /** @format int32 */
  statusCode?: number;
}

export interface CreatingAndUpdatingShippingServiceReq {
  code?: string;
  /** @format int64 */
  serviceProviderId: number;
  shipperCode?: string;
  consigneeCode?: string;
  /** @format int64 */
  billingPartyId?: number;
  incoterm?: 'EXW' | 'FCA' | 'FAS' | 'FOB' | 'CPT' | 'CIP' | 'CFR' | 'CIF' | 'DAP' | 'DPU' | 'DDP';
  dominantModality: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  route: string;
  service?: string;
  type?: string;
  carrier?: string;
  totalLeadTime?: number;
}

export interface GeoCoordinates {
  longitude?: number;
  latitude?: number;
}

export interface LegalEntityAddressDto {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  legalEntityId?: number;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  email?: string;
  fax?: string;
  phone?: string;
  locationName?: string;
  type: 'PICK_UP' | 'DELIVERY' | 'LEGAL' | 'BILLING' | 'OFFICE' | 'HEAD_OFFICE';
  active: boolean;
  origin?: GeoCoordinates;
  destination?: GeoCoordinates;
}

export interface LegalEntityDto {
  /** @format int64 */
  id?: number;
  name: string;
  code: string;
  active?: boolean;
  nationalCurrency?: string;
  /** @format int64 */
  parentId?: number;
  isBilledEntity?: boolean;
  addresses?: LegalEntityAddressDto[];
  nameAliases?: LegalEntityNameAliasDto[];
  subEntities?: LegalEntityDto[];
}

export interface LegalEntityNameAliasDto {
  /** @format int64 */
  id?: number;
  name?: string;
}

export interface ResponseDtoShippingServiceDto {
  success?: boolean;
  message?: string;
  data?: ShippingServiceDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ShippingServiceDto {
  /** @format uuid */
  id?: string;
  code?: string;
  /** @format int64 */
  serviceProviderId?: number;
  shipperInfo?: LegalEntityDto;
  consigneeInfo?: LegalEntityDto;
  /** @format int64 */
  billingPartyId?: number;
  incoterm?: 'EXW' | 'FCA' | 'FAS' | 'FOB' | 'CPT' | 'CIP' | 'CFR' | 'CIF' | 'DAP' | 'DPU' | 'DDP';
  active?: boolean;
  dominantModality?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  dataPipelineConfigId?: string;
  listZone?: ZoneDto[];
  route?: string;
  service?: string;
  type?: string;
  carrier?: string;
  totalLeadTime?: number;
  createdOn?: string;
}

export interface ZoneDto {
  pol: string;
  pod: string;
}

export interface Container {
  /** @format int32 */
  size: number;
  /** @format int32 */
  quantity: number;
  type?: 'STANDARD' | 'HIGH_CUBE' | 'OPEN_TOP' | 'FLAT_RACK';
}

export interface RawShipmentDto {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  contractId?: string;
  /** @format uuid */
  shippingServiceId?: string;
  shipmentNumber?: string;
  totalCo2EmissionInGram?: number;
  co2CalculatedWithDefaultOption?: boolean;
  co2CalculationFailureReason?: string;
  shipmentContainer?: ShipmentContainer;
  transportationReferenceNumber?: string;
  carrier?: string;
  /** @format int32 */
  numberOfPackages?: number;
  numberOfPackagesDescription?: string;
  commodity?: string;
  modality?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  grossWeightInKg?: number;
  grossWeight?: string;
  chargeableWeightInKg?: number;
  chargeableWeight?: string;
  netWeightInKg?: number;
  netWeight?: string;
  volumeInCbm?: number;
  /** @format date */
  estimatedDepartureDate?: string;
  /** @format date */
  estimatedArrivalDate?: string;
  masterBillOfLading?: string;
  houseBillOfLading?: string;
  containerNumbers?: string[];
  placeOfLoading?: string;
  placeOfDischarge?: string;
  serviceType?: string;
  specialType?: string;
  /** @format int64 */
  serviceProviderId?: number;
  /** @format int64 */
  billingPartyId?: number;
  /** @format int64 */
  shipperId?: number;
  /** @format int64 */
  consigneeId?: number;
  internalTrackingNumber?: string;
  /** @format date-time */
  createdOn?: string;
  createdBy?: string;
  /** @format date-time */
  updatedOn?: string;
  updatedBy?: string;
}

export interface ShipmentContainer {
  /** @uniqueItems true */
  containerNumbers?: string[];
  /** @uniqueItems true */
  containers?: Container[];
}

export interface ResponseDtoRawShipmentDto {
  success?: boolean;
  message?: string;
  data?: RawShipmentDto;
  /** @format int32 */
  statusCode?: number;
}

export interface DeviationWorkflowActionBodyDto {
  reason?: string;
}

export interface AffectedInvoiceInfo {
  /** @format uuid */
  invoiceId?: string;
  invoiceNumber?: string;
  invoiceStatus?: 'FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT';
  currentDeviationWorkflow?:
    | 'OPERATION_REVIEW'
    | 'PROCUREMENT_REVIEW'
    | 'PENDING_REVIEW'
    | 'DISPUTE_REVIEW'
    | 'PROCUREMENT_NO_CONTRACT_REVIEW'
    | 'FINANCE_REVIEW';
  finalResolution?:
    | 'NO_CONTRACT_REJECT'
    | 'REJECT_AFTER_DISPUTE'
    | 'UNKNOWN_BILLING_PARTY'
    | 'WRONG_BILLING_PARTY'
    | 'DUPLICATE_INVOICE'
    | 'NO_RATE_CARD_FOUND';
}

export interface AffectedShipmentAndInvoice {
  affectedInvoices?: AffectedInvoiceInfo[];
  resolvedShipmentInvoices?: AffectedShipmentInfo[];
  flaggedShipmentInvoices?: AffectedShipmentInfo[];
}

export interface AffectedShipmentInfo {
  /** @format uuid */
  shipmentInvoiceId?: string;
  /** @format uuid */
  invoiceId?: string;
  shipmentNumber?: string;
  status?: 'FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT';
  currentDeviationWorkflow?:
    | 'OPERATION_REVIEW'
    | 'PROCUREMENT_REVIEW'
    | 'PENDING_REVIEW'
    | 'DISPUTE_REVIEW'
    | 'PROCUREMENT_NO_CONTRACT_REVIEW'
    | 'FINANCE_REVIEW';
  deviationTypeCountMap?: Record<string, number>;
  invoiceNumber?: string;
  finalResolution?:
    | 'NO_CONTRACT_REJECT'
    | 'REJECT_AFTER_DISPUTE'
    | 'UNKNOWN_BILLING_PARTY'
    | 'WRONG_BILLING_PARTY'
    | 'DUPLICATE_INVOICE'
    | 'NO_RATE_CARD_FOUND';
}

export interface Amount {
  beforeTax?: number;
  afterTax?: number;
  currency?: string;
}

export interface BackgroundShipmentPerformResultDto {
  affectedShipmentAndInvoice?: AffectedShipmentAndInvoice;
}

export interface ChargeDto {
  /** @format uuid */
  id?: string;
  chargeName?: string;
  quantity?: number;
  totalAmount?: number;
  totalAmountCurrency?: string;
  accessorial?: boolean;
  approval?: boolean;
  outlay?: boolean;
  shipmentNumber?: string;
}

export type ContainerChargeDto = ContractChargeDto & {
  unitPrice20?: number;
  unitPrice40?: number;
  currency?: string;
  containerType?: 'STANDARD' | 'HIGH_CUBE' | 'OPEN_TOP' | 'FLAT_RACK';
};

/** Base class for contract charges */
export interface ContractChargeDto {
  /** @format uuid */
  id?: string;
  code?: string;
  chargeType?: 'SHIPMENT' | 'DECLARATION' | 'CONTAINER' | 'WEIGHT' | 'PERCENTAGE';
  /** @format int32 */
  number?: number;
  chargeName?: string;
  chargeCategory?:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
  legType?: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  active?: boolean;
  accessorial?: boolean;
  approval?: boolean;
  outlay?: boolean;
}

export type DeclarationChargeDto = ContractChargeDto & {
  unitPrice?: number;
  currency?: string;
};

export interface DeviationDto {
  /** @format uuid */
  id?: string;
  name?: string;
  shipmentNumber?: string;
  type?: 'UNIT_PRICE' | 'QUANTITY' | 'NEW_CHARGE' | 'DOUBLE_CHARGE' | 'APPROVAL_CHECK';
  subType?: 'NO_MAPPING_FOUND' | 'CURRENCY_NOT_MATCHED' | 'CONTAINER_TYPE_NOT_MATCHED' | 'WEIGHT_RANGE_NOT_MATCHED' | 'PERCENTAGE_DATE_NOT_MATCHED';
  status?: 'UNFILLED' | 'APPROVED_UNDONE' | 'REJECTED_UNDONE' | 'REJECTED_DONE' | 'DONE';
  legType?: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  chargeLegType?: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  chargeDomain?:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
  chargeSubDomain?: string;
  chargeDescription?: string;
  legCurrency?: string;
  deviationCurrency?: string;
  contractChargeUnit?: 'SHIPMENT' | 'DECLARATION' | 'CONTAINER' | 'WEIGHT' | 'PERCENTAGE';
  invoiceChargeUnit?: string;
  contractUnitPrice?: number;
  invoiceUnitPrice?: number;
  invoiceQuantity?: number;
  deviationQuantity?: number;
  deviationAmountPerUnit?: number;
  deviationAmount?: number;
  deviationAmountBeforeTax?: number;
  deviationAmountAfterTax?: number;
  accessorial?: boolean;
  approval?: boolean;
  outlay?: boolean;
  chargeQuantity?: number;
  chargeUnitPrice?: number;
  matchingRateCards?: (ContainerChargeDto | DeclarationChargeDto | PercentageChargeDto | ShipmentChargeDto | WeightChargeDto)[];
  rejectedByOperation?: boolean;
}

export interface InvoiceDto {
  /** @format uuid */
  id?: string;
  invoiceNumber?: string;
  invoiceDate?: string;
  paymentDate?: string;
  submissionDate?: string;
  pickupDate?: string;
  deliveryDate?: string;
  invoiceDueDate?: string;
  resolutionDate?: string;
  portArrivalDateEta?: string;
  freightForwarder?: string;
  /** @uniqueItems true */
  tradeLanes?: string[];
  deviations?: DeviationDto[];
  mblHbl?: string;
  /** @uniqueItems true */
  shipments?: string[];
  /** @uniqueItems true */
  services?: string[];
  status?: 'FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT';
  invoiceStatusOverview?: InvoiceStatusOverviewDto;
  modality?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  userResolutionStatus?: 'REVIEW_REQUIRED' | 'UNDER_REVIEW' | 'NOT_AN_INVOICE' | 'MISSING_REQUIRED_FIELD' | 'INCONSISTENT_SHIPMENT_DATA' | 'FIXED';
  userResolutionMessage?: string;
  /** @format double */
  totalAmount?: number;
  aggregateShipmentType?: 'IMPORT' | 'EXPORT' | 'MULTI';
  hasResponsibleWorkflow?: boolean;
}

export interface InvoiceStatusOverviewDto {
  overview?: string;
  details?: string[];
}

export interface LegDetail {
  type?: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  transportMedium?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  modality?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  paymentCurrency?: string;
  paymentCurrencyExchangeRate?: number;
  legCurrency?: string;
  legAmountAfterTax?: number;
  paymentAmountAfterTax?: number;
  legDeviationAmountAfterTax?: number;
  paymentDeviationAmountAfterTax?: number;
  co2Emission?: number;
  invoices?: InvoiceDto[];
}

export interface LegTransportDetailDto {
  startLocation?: string;
  /** @format date */
  startDate?: string;
  transportMedium?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  deliveryLocation?: string;
  /** @format date */
  deliveryDate?: string;
  route?: string;
  zones?: ZoneDto[];
}

export type PercentageChargeDto = ContractChargeDto & {
  value?: number;
  minimumPrice?: number;
  currency?: string;
  eligibleChargeDomain?: string;
  eligibleChargeSubDomain?: string;
  /** @format date */
  startDate?: string;
  /** @format date */
  endDate?: string;
};

export interface ResponseDtoShipmentInActionDetailDto {
  success?: boolean;
  message?: string;
  data?: ShipmentInActionDetailDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ServiceTypeDto {
  name?: string;
  specialType?: string;
  importExportType?: 'IMPORT' | 'EXPORT' | 'MULTI';
  modality?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  approval?: boolean;
}

export type ShipmentChargeDto = ContractChargeDto & {
  unitPrice?: number;
  currency?: string;
};

export interface ShipmentContainerDto {
  /** @format int32 */
  size?: number;
  /** @format int32 */
  quantity?: number;
  type?: string;
}

export interface ShipmentDetailDto {
  /** @format int64 */
  numberOfInvoices?: number;
  shipmentAmount?: Amount;
  deviationAmount?: Amount;
  /** @format int32 */
  containersCount?: number;
  containers?: ShipmentContainerDto[];
  incoterm?: 'EXW' | 'FCA' | 'FAS' | 'FOB' | 'CPT' | 'CIP' | 'CFR' | 'CIF' | 'DAP' | 'DPU' | 'DDP';
  serviceType?: ServiceTypeDto;
  numberOfPackagesDescription?: string;
  /** @format int32 */
  numberOfPackages?: number;
  shipmentNumber?: string;
  shipper?: string;
  shipperCode?: string;
  placeToPickup?: string;
  consignee?: string;
  consigneeCode?: string;
  deliveryPlace?: string;
  totalGrossWeight?: number;
  totalChargeableWeight?: number;
  volume?: number;
  totalCo2Emission?: number;
}

export interface ShipmentInActionDetailDto {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  shipmentInvoiceId?: string;
  mblHbl?: string;
  incoterm?: 'EXW' | 'FCA' | 'FAS' | 'FOB' | 'CPT' | 'CIP' | 'CFR' | 'CIF' | 'DAP' | 'DPU' | 'DDP';
  invoiceCharges?: ChargeDto[];
  approvalRequired?: boolean;
  deviations?: DeviationDto[];
  shipmentDetail?: ShipmentDetailDto;
  legDetails?: LegDetail[];
  transportDetail?: ShipmentTransportDetailDto;
  possibleWorkflowAction?:
    | 'SEND_TO_OPERATION'
    | 'SEND_TO_PROCUREMENT'
    | 'SEND_TO_FINANCE'
    | 'START_DISPUTE'
    | 'MARK_INVOICE_TO_REJECT'
    | 'MARK_INVOICE_TO_APPROVE'
    | 'ACCEPT_CREDIT_NOTE'
    | 'ACCEPT_APPROVAL'
    | 'READY_FOR_PAYMENT'
    | 'REJECT_INVOICE'
    | 'ACCEPT_INVOICE';
  nextWorkflowAction?:
    | 'SEND_TO_OPERATION'
    | 'SEND_TO_PROCUREMENT'
    | 'SEND_TO_FINANCE'
    | 'START_DISPUTE'
    | 'MARK_INVOICE_TO_REJECT'
    | 'MARK_INVOICE_TO_APPROVE'
    | 'ACCEPT_CREDIT_NOTE'
    | 'ACCEPT_APPROVAL'
    | 'READY_FOR_PAYMENT'
    | 'REJECT_INVOICE'
    | 'ACCEPT_INVOICE';
  currentDeviationWorkflow?:
    | 'OPERATION_REVIEW'
    | 'PROCUREMENT_REVIEW'
    | 'PENDING_REVIEW'
    | 'DISPUTE_REVIEW'
    | 'PROCUREMENT_NO_CONTRACT_REVIEW'
    | 'FINANCE_REVIEW';
  status?: 'FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT';
  approvalTypeApprovalStatusMap?: Record<string, 'PENDING' | 'APPROVED' | 'REJECTED'>;
  asyncProcessing?: boolean;
  backgroundShipmentPerformResultDto?: BackgroundShipmentPerformResultDto;
  /** @format uuid */
  shippingServiceId?: string;
  /** @format uuid */
  contractId?: string;
}

export interface ShipmentTransportDetailDto {
  legOne?: LegTransportDetailDto;
  legTwo?: LegTransportDetailDto;
  legThree?: LegTransportDetailDto;
}

export type WeightChargeDto = ContractChargeDto & {
  unitPrice?: number;
  currency?: string;
  chargeUnit?: 'KG' | 'CBM' | 'PKG';
  minimumPrice?: number;
  minimumChargeableWeight?: number;
  maximumChargeableWeight?: number;
};

export interface UpsertContractWeightChargeReq {
  /** @format uuid */
  id?: string;
  code?: string;
  /** @format uuid */
  contractFlowId: string;
  legType: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  chargeDomain:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
  chargeSubDomain: string;
  accessorial: boolean;
  approval: boolean;
  outlay: boolean;
  unitPrice?: number;
  currency: string;
  chargeUnit: 'KG' | 'CBM' | 'PKG';
  minimumPrice?: number;
  minimumChargeableWeight?: number;
  maximumChargeableWeight?: number;
}

export interface ResponseDtoWeightChargeDto {
  success?: boolean;
  message?: string;
  data?: WeightChargeDto;
  /** @format int32 */
  statusCode?: number;
}

export interface UpsertContractShipmentChargeReq {
  /** @format uuid */
  id?: string;
  code?: string;
  /** @format uuid */
  contractFlowId: string;
  legType: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  chargeDomain:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
  chargeSubDomain: string;
  accessorial: boolean;
  approval: boolean;
  outlay: boolean;
  currency: string;
  unitPrice: number;
}

export interface ResponseDtoShipmentChargeDto {
  success?: boolean;
  message?: string;
  data?: ShipmentChargeDto;
  /** @format int32 */
  statusCode?: number;
}

export interface UpsertContractPercentageChargeReq {
  /** @format uuid */
  id?: string;
  code?: string;
  /** @format uuid */
  contractFlowId: string;
  legType: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  chargeDomain:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
  chargeSubDomain: string;
  accessorial: boolean;
  approval: boolean;
  outlay: boolean;
  value: number;
  minimumPrice?: number;
  currency: string;
  eligibleChargeDomain?:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
  eligibleChargeSubDomain?: string;
  /** @format date */
  startDate?: string;
  /** @format date */
  endDate?: string;
}

export interface ResponseDtoPercentageChargeDto {
  success?: boolean;
  message?: string;
  data?: PercentageChargeDto;
  /** @format int32 */
  statusCode?: number;
}

export interface UpsertContractDeclarationChargeReq {
  /** @format uuid */
  id?: string;
  code?: string;
  /** @format uuid */
  contractFlowId: string;
  legType: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  chargeDomain:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
  chargeSubDomain: string;
  accessorial: boolean;
  approval: boolean;
  outlay: boolean;
  unitPrice: number;
  currency: string;
}

export interface ResponseDtoDeclarationChargeDto {
  success?: boolean;
  message?: string;
  data?: DeclarationChargeDto;
  /** @format int32 */
  statusCode?: number;
}

export interface UpsertContractContainerChargeReq {
  /** @format uuid */
  id?: string;
  code?: string;
  /** @format uuid */
  contractFlowId: string;
  legType: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  chargeDomain:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
  chargeSubDomain: string;
  accessorial: boolean;
  approval: boolean;
  outlay: boolean;
  containerType?: 'STANDARD' | 'HIGH_CUBE' | 'OPEN_TOP' | 'FLAT_RACK';
  unitPrice20?: number;
  unitPrice40?: number;
  currency?: string;
}

export interface ResponseDtoContainerChargeDto {
  success?: boolean;
  message?: string;
  data?: ContainerChargeDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoListLegalEntityDto {
  success?: boolean;
  message?: string;
  data?: LegalEntityDto[];
  /** @format int32 */
  statusCode?: number;
}

export interface UpsertLegalEntityAliasRequest {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  legalEntityId: number;
  name: string;
}

export interface ResponseDtoLegalEntityNameAliasDto {
  success?: boolean;
  message?: string;
  data?: LegalEntityNameAliasDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoLegalEntityAddressDto {
  success?: boolean;
  message?: string;
  data?: LegalEntityAddressDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoBoolean {
  success?: boolean;
  message?: string;
  data?: boolean;
  /** @format int32 */
  statusCode?: number;
}

export interface Attachment {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  invoiceId?: string;
  attachmentType?: 'APPROVAL' | 'RATE_CARD' | 'ADDITIONAL_ATTACHMENT';
  filePath?: string;
  fileName?: string;
  /** @format date-time */
  createdOn?: string;
  createdBy?: string;
}

export interface CreditNoteFileDto {
  fileName?: string;
  /** @format date-time */
  uploadedTime?: string;
}

export interface DisputeDeviationResolutionDto {
  /** @format uuid */
  deviationId?: string;
  /** @format date */
  endPeriod?: string;
  /** @format date */
  startPeriod?: string;
  expectedAmount?: number;
  exchangeRate?: number;
  periodType?: 'ONE_OFF' | 'RECURRING';
}

export interface FreightForwarderDto {
  name?: string;
  code?: string;
  country?: string;
}

export interface InvoiceDisputeDto {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  invoiceId?: string;
  currency?: string;
  lumpSumAmount?: number;
  lumpSumAmountBeforeTax?: number;
  expectedTotalAmount?: Amount;
  creditNoteAmount?: Amount;
  resolutionDescription?: string;
  status?: 'DRAFT' | 'PENDING_DOCUMENT' | 'DONE';
  creditNoteFile?: CreditNoteFileDto;
  rejectedDeviations?: DeviationDto[];
  deviations?: DeviationDto[];
  nextWorkflowAction?:
    | 'SEND_TO_OPERATION'
    | 'SEND_TO_PROCUREMENT'
    | 'SEND_TO_FINANCE'
    | 'START_DISPUTE'
    | 'MARK_INVOICE_TO_REJECT'
    | 'MARK_INVOICE_TO_APPROVE'
    | 'ACCEPT_CREDIT_NOTE'
    | 'ACCEPT_APPROVAL'
    | 'READY_FOR_PAYMENT'
    | 'REJECT_INVOICE'
    | 'ACCEPT_INVOICE';
  agreementResolution?:
    | 'ACCEPT_INVOICE_AS_IS'
    | 'AMEND_INVOICE_CREDIT_NOTE'
    | 'REJECT_INVOICE'
    | 'COMMERCIAL_SETTLEMENT_CREDIT_NOTE'
    | 'ACCEPT_INVOICE_WITH_CREDIT_NOTE';
  deviationResolutionType?: 'BREAK_DOWN_PER_DEVIATION' | 'LUMP_SUM';
  deviationResolutions?: DisputeDeviationResolutionDto[];
  canContinueDispute?: boolean;
}

export interface InvoiceMeta {
  rejectReason?: string;
}

export interface InvoiceSummaryDto {
  /** @format uuid */
  id?: string;
  invoiceNumber?: string;
  /** @format uuid */
  invoiceDisputeId?: string;
  serviceProvider?: string;
  freightForwarder?: FreightForwarderDto;
  status?: 'FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT';
  /** @uniqueItems true */
  permissions?: (
    | 'CAN_GIVE_PROCUREMENT_REVIEW'
    | 'CAN_GIVE_OPERATION_REVIEW'
    | 'CAN_GIVE_FINANCE_REVIEW'
    | 'CAN_GIVE_DISPUTE_REVIEW'
    | 'CAN_GIVE_NO_CONTRACT_REVIEW'
    | 'CAN_UPLOAD_INVOICE_FILE'
  )[];
  currentMainRole?: 'OPERATION' | 'PROCUREMENT' | 'FINANCE' | 'ADMIN' | 'SYSTEM_ADMIN';
  possibleWorkflowAction?:
    | 'SEND_TO_OPERATION'
    | 'SEND_TO_PROCUREMENT'
    | 'SEND_TO_FINANCE'
    | 'START_DISPUTE'
    | 'MARK_INVOICE_TO_REJECT'
    | 'MARK_INVOICE_TO_APPROVE'
    | 'ACCEPT_CREDIT_NOTE'
    | 'ACCEPT_APPROVAL'
    | 'READY_FOR_PAYMENT'
    | 'REJECT_INVOICE'
    | 'ACCEPT_INVOICE';
  nextWorkflowAction?:
    | 'SEND_TO_OPERATION'
    | 'SEND_TO_PROCUREMENT'
    | 'SEND_TO_FINANCE'
    | 'START_DISPUTE'
    | 'MARK_INVOICE_TO_REJECT'
    | 'MARK_INVOICE_TO_APPROVE'
    | 'ACCEPT_CREDIT_NOTE'
    | 'ACCEPT_APPROVAL'
    | 'READY_FOR_PAYMENT'
    | 'REJECT_INVOICE'
    | 'ACCEPT_INVOICE';
  currentDeviationWorkflow?:
    | 'OPERATION_REVIEW'
    | 'PROCUREMENT_REVIEW'
    | 'PENDING_REVIEW'
    | 'DISPUTE_REVIEW'
    | 'PROCUREMENT_NO_CONTRACT_REVIEW'
    | 'FINANCE_REVIEW';
  /** @format date */
  invoiceDate?: string;
  /** @format date */
  paymentDate?: string;
  invoicedParty?: string;
  invoiceAmount?: Amount;
  amountToBePaid?: Amount;
  deviations?: Record<string, number>;
  invoiceAmountDeviation?: Amount;
  servicesAndShipments?: Record<string, number>;
  totalChargeableWeight?: number;
  volume?: number;
  /** @format int32 */
  packageCount?: number;
  totalCo2Emission?: number;
  modality?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  invoiceAttachments?: Attachment[];
  serviceTypes?: ServiceTypeDto[];
  meta?: InvoiceMeta;
  finalResolution?:
    | 'NO_CONTRACT_REJECT'
    | 'REJECT_AFTER_DISPUTE'
    | 'UNKNOWN_BILLING_PARTY'
    | 'WRONG_BILLING_PARTY'
    | 'DUPLICATE_INVOICE'
    | 'NO_RATE_CARD_FOUND';
  approvalRequired?: boolean;
  invoiceDispute?: InvoiceDisputeDto;
  creditNoteAmount?: Amount;
  fapRef?: string;
  accountNumber?: string;
  shipmentList?: Record<string, Shipment[]>;
}

export interface ResponseDtoInvoiceSummaryDto {
  success?: boolean;
  message?: string;
  data?: InvoiceSummaryDto;
  /** @format int32 */
  statusCode?: number;
}

export interface Shipment {
  /** @format uuid */
  id?: string;
  shipmentNumber?: string;
  /** @format uuid */
  shipmentInvoiceId?: string;
  status?: 'FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT';
  currentDeviationWorkflow?:
    | 'OPERATION_REVIEW'
    | 'PROCUREMENT_REVIEW'
    | 'PENDING_REVIEW'
    | 'DISPUTE_REVIEW'
    | 'PROCUREMENT_NO_CONTRACT_REVIEW'
    | 'FINANCE_REVIEW';
  finalResolution?:
    | 'NO_CONTRACT_REJECT'
    | 'REJECT_AFTER_DISPUTE'
    | 'UNKNOWN_BILLING_PARTY'
    | 'WRONG_BILLING_PARTY'
    | 'DUPLICATE_INVOICE'
    | 'NO_RATE_CARD_FOUND';
  /** @format int32 */
  numberOfDeviations?: number;
  /** @format int32 */
  numberOfApprovals?: number;
}

export interface DeviationQuestionUserResponseDto {
  /** @format uuid */
  questionId?: string;
  questionType?: 'TEXT' | 'SELECT' | 'RADIO' | 'DATE' | 'DATE_RANGE' | 'DATE_TIME' | 'DATE_TIME_RANGE' | 'MULTIPLE_SELECT';
  questionName?: string;
  /** @format uuid */
  answerOptionId?: string;
  answerText?: string;
  answerType?: 'YES' | 'NO' | 'RECURRING' | 'ONE_OFF' | 'YES_BUT_WITH_CHANGE_RECURRENCE' | 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE' | 'NOT_REQUIRED' | 'OTHER';
  answerName?: string;
  approvalType?: 'ACCESSORIAL_CHARGE' | 'SERVICE_TYPE';
}

export interface ResponseDtoListDeviationQuestionUserResponseDto {
  success?: boolean;
  message?: string;
  data?: DeviationQuestionUserResponseDto[];
  /** @format int32 */
  statusCode?: number;
}

export interface InvoiceDisputeUpdateRequestDto {
  /** @format uuid */
  id?: string;
  lumpSumAmount?: number;
  resolutionDescription?: string;
  agreementResolution?:
    | 'ACCEPT_INVOICE_AS_IS'
    | 'AMEND_INVOICE_CREDIT_NOTE'
    | 'REJECT_INVOICE'
    | 'COMMERCIAL_SETTLEMENT_CREDIT_NOTE'
    | 'ACCEPT_INVOICE_WITH_CREDIT_NOTE';
  deviationResolutionType?: 'BREAK_DOWN_PER_DEVIATION' | 'LUMP_SUM';
  deviationResolutions?: DisputeDeviationResolutionDto[];
}

export interface ResponseDtoInvoiceDisputeDto {
  success?: boolean;
  message?: string;
  data?: InvoiceDisputeDto;
  /** @format int32 */
  statusCode?: number;
}

export interface CreditNoteDto {
  totalCreditAmountAfterTax?: number;
  currencyExchangeRates?: Record<string, number>;
  creditNoteNumber: string;
}

export interface ResponseDtoCreditNoteDto {
  success?: boolean;
  message?: string;
  data?: CreditNoteDto;
  /** @format int32 */
  statusCode?: number;
}

export interface UploadCreditNoteUrlRequestDto {
  creditNoteFileUrl: string;
}

export interface ResponseDtoCreditNoteFileDto {
  success?: boolean;
  message?: string;
  data?: CreditNoteFileDto;
  /** @format int32 */
  statusCode?: number;
}

export interface InvoiceFileProcessingStatusUpdateReq {
  message?: string;
  dataPipelineConfigId?: string;
  modality?: string;
  fileLink?: string;
  pipelineData?: JsonNode;
}

export type JsonNode = object;

export interface InvoiceFileProcessingDto {
  modality?: string;
  extractedPage?: string;
  modelUsed?: string;
  transformerUsed?: string;
  invoiceNumber?: string;
  serviceProviderName?: string;
  billedEntity?: string;
  /** @format uuid */
  id?: string;
  fileName?: string;
  /** @format uuid */
  invoiceDataPipelineConfigId?: string;
  status?:
    | 'BEING_UPLOADED'
    | 'COMPLETE'
    | 'OCR_PROCESSING'
    | 'OCR_COMPLETE'
    | 'OCR_ERROR'
    | 'TRANSFORM_ERROR'
    | 'TRANSFORM_COMPLETE'
    | 'MESSAGE_PROCESSING_FAILED'
    | 'UPLOAD_FROM_EMAIL_FAILED'
    | 'RESOLVED_AS_NOT_AN_INVOICE'
    | 'RESOLVED_AS_MISSING_REQUIRED_FIELD'
    | 'RESOLVED_AS_INCONSISTENT_SHIPMENT_DATA'
    | 'MARKED_MISSING_REQUIRED_FIELD_FOR_REVIEW'
    | 'MARKED_INCONSISTENT_SHIPMENT_DATA_FOR_REVIEW'
    | 'USER_MARK_AS_ERROR';
  /** @format date-time */
  createdOn?: string;
  createdBy?: string;
  /** @format date-time */
  updatedOn?: string;
  message?: string;
  /** @format uuid */
  batchId?: string;
  isBilled?: boolean;
  invoiceStatus?: 'FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT';
  /** @format date-time */
  lastApprovalEmailSentOn?: string;
  resolutionStatus?: 'REVIEW_REQUIRED' | 'UNDER_REVIEW' | 'NOT_AN_INVOICE' | 'MISSING_REQUIRED_FIELD' | 'INCONSISTENT_SHIPMENT_DATA' | 'FIXED';
}

export interface ResponseDtoInvoiceFileProcessingDto {
  success?: boolean;
  message?: string;
  data?: InvoiceFileProcessingDto;
  /** @format int32 */
  statusCode?: number;
}

export interface UpsertInvoiceChargeDomainMappingReq {
  /** @format int64 */
  id?: number;
  invoiceChargeName: string;
  chargeDomain:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
  chargeSubDomain: string;
  /** @format int64 */
  serviceProviderId: number;
  legType: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  contractChargeUnit: 'SHIPMENT' | 'DECLARATION' | 'CONTAINER' | 'WEIGHT' | 'PERCENTAGE';
}

export interface InvoiceChargeDomainMappingDto {
  /** @format int64 */
  id?: number;
  invoiceChargeName?: string;
  chargeDomain?:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
  chargeSubDomain?: string;
  /** @format int64 */
  serviceProviderId?: number;
  legType?: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  contractChargeUnit?: 'SHIPMENT' | 'DECLARATION' | 'CONTAINER' | 'WEIGHT' | 'PERCENTAGE';
  /** @format date-time */
  createdOn?: string;
  active?: boolean;
}

export interface ResponseDtoListInvoiceChargeDomainMappingDto {
  success?: boolean;
  message?: string;
  data?: InvoiceChargeDomainMappingDto[];
  /** @format int32 */
  statusCode?: number;
}

export interface DeviationQuestionnaireQuestionUserResponseDto {
  questionnaireAnswer?: DeviationQuestionUserResponseDto[];
  newChargeAnswer?: LegChargeDto;
}

export interface LegChargeDto {
  legType?: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  chargeDomain?:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
}

export interface InvoiceReTriggerRequest {
  invoiceIds?: string[];
}

export interface UpdatingContractReq {
  /** @format uuid */
  contractFlowId: string;
  code?: string;
  /** @format date */
  startDate: string;
  /** @format date */
  endDate: string;
  filePath?: string;
}

export interface ContractDto {
  /** @format uuid */
  id?: string;
  /** @format int64 */
  serviceProviderId?: number;
  active?: boolean;
  filePath?: string;
  /** @format date */
  startDate?: string;
  /** @format date */
  endDate?: string;
  /** @format date-time */
  createdOn?: string;
  createdBy?: string;
  /** @format date-time */
  updatedOn?: string;
  updatedBy?: string;
  code?: string;
  /** @format uuid */
  contractFlowId?: string;
}

export interface ResponseDtoContractDto {
  success?: boolean;
  message?: string;
  data?: ContractDto;
  /** @format int32 */
  statusCode?: number;
}

export interface CreatingAndUpdatingContractFlowReq {
  /** @format uuid */
  shippingServiceId: string;
  /** @format uuid */
  contractId: string;
  code?: string;
  leg1Currency?: string;
  leg2Currency?: string;
  leg3Currency?: string;
}

export interface ContractFlowInfoViewDto {
  /** @format uuid */
  id?: string;
  code?: string;
  /** @format uuid */
  contractId?: string;
  /** @format uuid */
  shippingServiceId?: string;
  active?: boolean;
  leg1Currency?: string;
  leg2Currency?: string;
  leg3Currency?: string;
  /** @format date-time */
  createdOn?: string;
  createdBy?: string;
  /** @format date-time */
  updatedOn?: string;
  updatedBy?: string;
  containerCharges?: ContainerChargeDto[];
  percentageCharges?: PercentageChargeDto[];
  shipmentCharges?: ShipmentChargeDto[];
  declarationCharges?: DeclarationChargeDto[];
  weightCharges?: WeightChargeDto[];
}

export interface ResponseDtoContractFlowInfoViewDto {
  success?: boolean;
  message?: string;
  data?: ContractFlowInfoViewDto;
  /** @format int32 */
  statusCode?: number;
}

export interface AddingOrRemovingRateCardToContractFlowReq {
  /** @format uuid */
  id: string;
  contractChargeUnit: 'SHIPMENT' | 'DECLARATION' | 'CONTAINER' | 'WEIGHT' | 'PERCENTAGE';
}

export interface ChartReportRequestDto {
  /** @format uuid */
  id?: string;
  name?: string;
  description?: string;
  hashedFilters?: string;
}

export interface ChartReportDto {
  /** @format uuid */
  id?: string;
  name?: string;
  description?: string;
  hashedFilters?: string;
  /** @format date-time */
  updateDate?: string;
  createdBy?: string;
}

export interface ResponseDtoChartReportDto {
  success?: boolean;
  message?: string;
  data?: ChartReportDto;
  /** @format int32 */
  statusCode?: number;
}

export interface MigrationBatchSizeRequest {
  /** @format int32 */
  size?: number;
}

export interface EntityPermissionAttributes {
  billedEntities?: string[];
  serviceProvider?: string[];
  shippingService?: string[];
}

export interface EntityPermissionDto {
  entityPermission?: EntityPermissionAttributes[];
  allowed?: boolean;
  /** @uniqueItems true */
  permissions?: ('OPERATION' | 'PROCUREMENT' | 'DISPUTE' | 'FINANCE' | 'AUDIT' | 'REPORT' | 'PERFORMANCE')[];
}

export interface UserCreateRequestDto {
  /** @format uuid */
  id?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email?: string;
  cognitoId?: string;
  active?: boolean;
  /** @uniqueItems true */
  roles?: ('OPERATION' | 'PROCUREMENT' | 'FINANCE' | 'ADMIN' | 'SYSTEM_ADMIN')[];
  legalEntityCode?: string;
  userEntityPermission?: UserEntityPermissionDto;
}

export interface UserEntityPermissionDto {
  cognitoId?: string;
  entityPermissions?: EntityPermissionDto[];
}

export interface ResponseDtoUserDto {
  success?: boolean;
  message?: string;
  data?: UserDto;
  /** @format int32 */
  statusCode?: number;
}

export interface UserDto {
  /** @format uuid */
  id?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email?: string;
  cognitoId?: string;
  active?: boolean;
  /** @uniqueItems true */
  roles?: ('OPERATION' | 'PROCUREMENT' | 'FINANCE' | 'ADMIN' | 'SYSTEM_ADMIN')[];
  legalEntity?: LegalEntityDto;
  userPermissionList?: UserPermissionDto[];
}

export interface UserPermissionDto {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  userId?: string;
  /** @format int64 */
  billedEntityId?: number;
  /** @format int64 */
  shipperEntityId?: number;
  /** @format int64 */
  consigneeEntityId?: number;
  /** @format int64 */
  serviceProviderId?: number;
  /** @format uuid */
  shippingServiceId?: string;
  /** @uniqueItems true */
  permissions?: ('OPERATION' | 'PROCUREMENT' | 'DISPUTE' | 'FINANCE' | 'AUDIT' | 'REPORT' | 'PERFORMANCE')[];
  allowed?: boolean;
}

export interface ResponseDtoListUserPermissionDto {
  success?: boolean;
  message?: string;
  data?: UserPermissionDto[];
  /** @format int32 */
  statusCode?: number;
}

export interface UserIssueRequest {
  /** @format uuid */
  invoiceId?: string;
  message?: string;
}

export interface ResponseDtoUserIssueDto {
  success?: boolean;
  message?: string;
  data?: UserIssueDto;
  /** @format int32 */
  statusCode?: number;
}

export interface UserIssueDto {
  status?: 'REVIEW_REQUIRED' | 'UNDER_REVIEW' | 'NOT_AN_INVOICE' | 'MISSING_REQUIRED_FIELD' | 'INCONSISTENT_SHIPMENT_DATA' | 'FIXED';
  issueId?: string;
}

export interface DeviationCreateRequest {
  /** @format uuid */
  chargeId?: string;
  deviationType?: 'UNIT_PRICE' | 'QUANTITY' | 'NEW_CHARGE' | 'DOUBLE_CHARGE' | 'APPROVAL_CHECK';
}

export interface Deviation {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  chargeId?: string;
  active?: boolean;
  type?: 'UNIT_PRICE' | 'QUANTITY' | 'NEW_CHARGE' | 'DOUBLE_CHARGE' | 'APPROVAL_CHECK';
  subType?: 'NO_MAPPING_FOUND' | 'CURRENCY_NOT_MATCHED' | 'CONTAINER_TYPE_NOT_MATCHED' | 'WEIGHT_RANGE_NOT_MATCHED' | 'PERCENTAGE_DATE_NOT_MATCHED';
  status?: 'OPERATION_UNFILLED' | 'OPERATION_REJECTED' | 'OPERATION_APPROVED' | 'PROCUREMENT_REJECTED' | 'PROCUREMENT_APPROVED';
  legType?: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  chargeDescription?: string;
  deviatedCurrency?: string;
  /** @format uuid */
  matchingContractChargeId?: string;
  contractChargeUnit?: 'SHIPMENT' | 'DECLARATION' | 'CONTAINER' | 'WEIGHT' | 'PERCENTAGE';
  invoiceChargeUnit?: string;
  contractUnitPrice?: number;
  invoiceUnitPrice?: number;
  deviationAmountPerUnit?: number;
  taxPercentage?: number;
  exchangeRates?: number;
  deviationAmountBeforeTax?: number;
  deviationAmountAfterTax?: number;
  invoiceQuantity?: number;
  deviationQuantity?: number;
  /** @format date-time */
  createdOn?: string;
  createdBy?: string;
  /** @format date-time */
  updatedOn?: string;
  updatedBy?: string;
}

export interface ResponseDtoDeviation {
  success?: boolean;
  message?: string;
  data?: Deviation;
  /** @format int32 */
  statusCode?: number;
}

export interface GetMatchingShippingServiceRequest {
  /** @format int64 */
  billingPartyId?: number;
  /** @format int64 */
  serviceProviderId?: number;
  modality?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  pol?: string;
  pod?: string;
  /** @format date */
  etd?: string;
  polAlias?: string;
  podAlias?: string;
  /** @format int64 */
  shipperId?: number;
  /** @format int64 */
  consigneeId?: number;
  service?: string;
  type?: string;
  carrier?: string;
  incoterm?: 'EXW' | 'FCA' | 'FAS' | 'FOB' | 'CPT' | 'CIP' | 'CFR' | 'CIF' | 'DAP' | 'DPU' | 'DDP';
}

export interface Co2Emission {
  total?: number;
  wtt?: number;
  ttw?: number;
  intensity?: number;
}

export interface Details {
  vessel?: Vessel;
  fuelType?: string;
}

export interface Leg {
  co2e?: Co2Emission;
  metadata?: Record<string, string>;
}

export interface Order {
  type?: string;
  /** @format int32 */
  quantity?: number;
  sizeTypeCode?: string;
  weight?: number;
}

export interface Parameters {
  orders?: Order[];
  details?: Details;
}

export interface ResponseDtoSeaRoutesResponseDto {
  success?: boolean;
  message?: string;
  data?: SeaRoutesResponseDto;
  /** @format int32 */
  statusCode?: number;
}

export interface SeaRoutesError {
  /** @format int64 */
  timestamp?: number;
  /** @format int32 */
  status?: number;
  error?: string;
  errorCodes?: string[];
  messages?: string[];
}

export interface SeaRoutesResponse {
  parameters?: Parameters;
  co2e?: Co2Emission;
  legs?: Leg[];
}

export interface SeaRoutesResponseDto {
  seaRoutesResponse?: SeaRoutesResponse;
  seaRoutesError?: SeaRoutesError;
}

export interface Vessel {
  name?: string;
}

export interface ContractChargeListsDto {
  containerCharges?: ContainerChargeDto[];
  shipmentDeclarationCharges?: MergedShipmentDeclarationChargeDto[];
  weightCharges?: WeightChargeDto[];
  percentageCharges?: PercentageChargeDto[];
}

export interface ExistingShippingServiceDto {
  /** @format uuid */
  contractFlowId?: string;
  listZone?: ZoneDto[];
  shipperName?: string;
  route?: string;
  consigneeName?: string;
  modality?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  service?: string;
  type?: string;
  carrier?: string;
  incoterm?: 'EXW' | 'FCA' | 'FAS' | 'FOB' | 'CPT' | 'CIP' | 'CFR' | 'CIF' | 'DAP' | 'DPU' | 'DDP';
  /** @format date */
  startDate: string;
  /** @format date */
  endDate: string;
}

export interface MergedShipmentDeclarationChargeDto {
  /** @format uuid */
  id?: string;
  code?: string;
  chargeType?: 'SHIPMENT' | 'DECLARATION' | 'CONTAINER' | 'WEIGHT' | 'PERCENTAGE';
  /** @format int32 */
  number?: number;
  chargeName?: string;
  chargeCategory?:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
  legType?: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  active?: boolean;
  accessorial?: boolean;
  approval?: boolean;
  outlay?: boolean;
  unitPrice?: number;
  currency?: string;
}

export interface NewShippingServiceAndContractRequestDto {
  newShippingService?: ExistingShippingServiceDto;
  contractCharges?: ContractChargeListsDto;
}

export interface ResponseDtoListDeviation {
  success?: boolean;
  message?: string;
  data?: Deviation[];
  /** @format int32 */
  statusCode?: number;
}

export interface MatchingServiceDto {
  listZone?: ZoneDto[];
  shipperName?: string;
  consigneeName?: string;
  modality?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  service?: string;
  type?: string;
  carrier?: string;
  incoterm?: 'EXW' | 'FCA' | 'FAS' | 'FOB' | 'CPT' | 'CIP' | 'CFR' | 'CIF' | 'DAP' | 'DPU' | 'DDP';
  /** @format date */
  estimatedDepartureDate?: string;
}

export interface NoContractDraftData {
  /** @format int32 */
  currentStep?: number;
  unrecognisedShippingService?: MatchingServiceDto;
  partialMatchingServices?: PartialMatchingServiceDto[];
  selectedPartialMatchingService?: PartialMatchingServiceDto;
  selectedResolutionOption?: string;
  contractCharges?: ContractChargeListsDto;
  rejectedReason?: string;
}

export interface NoContractDraftRequestDto {
  data?: NoContractDraftData;
}

export interface PartialMatchingServiceDto {
  existingShippingService?: ExistingShippingServiceDto;
  mismatchedFields?: string[];
}

export interface NoContractDraft {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  userId?: string;
  /** @format uuid */
  shipmentInvoiceId?: string;
  data?: NoContractDraftData;
  status?: 'ACTIVE' | 'DISCARDED';
  /** @format date-time */
  createdOn?: string;
  createdBy?: string;
  /** @format date-time */
  updatedOn?: string;
  updatedBy?: string;
}

export interface ResponseDtoNoContractDraft {
  success?: boolean;
  message?: string;
  data?: NoContractDraft;
  /** @format int32 */
  statusCode?: number;
}

/** Request object for searching the universe */
export interface UniversalSearchRequest {
  /**
   * The search key to filter the results
   * @minLength 3
   * @example "INV123"
   */
  searchKey?: string;
  /**
   * Flag indicating if only approved invoices should be included, be null if user does not want to filter by invoice status
   * @example true
   */
  isApprovedInvoice?: boolean;
  /**
   * The page number for pagination
   * @format int32
   * @default 1
   * @example 1
   */
  pageNumber?: number;
  /**
   * The number of records per page for pagination, minimum is 1
   * @format int32
   * @example 10
   */
  pageSize?: number;
}

export interface PageDto {
  /** @format int32 */
  pageNumber?: number;
  /** @format int32 */
  pageSize?: number;
}

export interface ResponseDtoUniversalSearchResponseDto {
  success?: boolean;
  message?: string;
  data?: UniversalSearchResponseDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ResultRecord {
  /** @format uuid */
  invoiceId?: string;
  invoiceNumber?: string;
  invoiceStatus?: 'FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT';
  serviceProviderName?: string;
  serviceProviderCode?: string;
  modality?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  /** @format uuid */
  shipmentInvoiceId?: string;
  shipmentNumber?: string;
}

export interface UniversalSearchResponseDto {
  /** @format int64 */
  totalRecord?: number;
  page?: PageDto;
  results?: ResultRecord[];
}

export interface BillingFiltersDto {
  /** @uniqueItems true */
  legType?: string[];
  /** @uniqueItems true */
  chargeDomain?: string[];
}

export interface DateFiltersDto {
  /** @uniqueItems true */
  years?: string[];
  /** @uniqueItems true */
  quarters?: string[];
  /** @uniqueItems true */
  months?: string[];
  /** @uniqueItems true */
  weeks?: string[];
  /** @uniqueItems true */
  days?: string[];
}

export interface FiltersDto {
  dateFilters?: DateFiltersDto;
  shipmentFilters?: ShipmentFiltersDto;
  tradeLaneFilters?: TradeLaneFiltersDto;
  billingFilters?: BillingFiltersDto;
  serviceProviders?: ServiceProviderDto[];
}

/** The filters request details for the performance assessment */
export interface FiltersRequestDto {
  currencyReference?:
    | 'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ANG'
    | 'AOA'
    | 'ARS'
    | 'AUD'
    | 'AWG'
    | 'AZN'
    | 'BAM'
    | 'BBD'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BMD'
    | 'BND'
    | 'BOB'
    | 'BOV'
    | 'BRL'
    | 'BSD'
    | 'BTN'
    | 'BWP'
    | 'BYN'
    | 'BYR'
    | 'BZD'
    | 'CAD'
    | 'CDF'
    | 'CHE'
    | 'CHF'
    | 'CHW'
    | 'CLF'
    | 'CLP'
    | 'CNY'
    | 'COP'
    | 'COU'
    | 'CRC'
    | 'CUC'
    | 'CUP'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'EUR'
    | 'FJD'
    | 'FKP'
    | 'GBP'
    | 'GEL'
    | 'GHS'
    | 'GIP'
    | 'GMD'
    | 'GNF'
    | 'GTQ'
    | 'GYD'
    | 'HKD'
    | 'HNL'
    | 'HRK'
    | 'HTG'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KGS'
    | 'KHR'
    | 'KMF'
    | 'KPW'
    | 'KRW'
    | 'KWD'
    | 'KYD'
    | 'KZT'
    | 'LAK'
    | 'LBP'
    | 'LKR'
    | 'LRD'
    | 'LSL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MNT'
    | 'MOP'
    | 'MRO'
    | 'MUR'
    | 'MVR'
    | 'MWK'
    | 'MXN'
    | 'MXV'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NGN'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PGK'
    | 'PHP'
    | 'PKR'
    | 'PLN'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SBD'
    | 'SCR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SHP'
    | 'SLL'
    | 'SOS'
    | 'SRD'
    | 'SSP'
    | 'STD'
    | 'SYP'
    | 'SZL'
    | 'THB'
    | 'TJS'
    | 'TMT'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'USD'
    | 'USN'
    | 'UYI'
    | 'UYU'
    | 'UZS'
    | 'VEF'
    | 'VND'
    | 'VUV'
    | 'WST'
    | 'XAF'
    | 'XAG'
    | 'XAU'
    | 'XBA'
    | 'XBB'
    | 'XBC'
    | 'XBD'
    | 'XCD'
    | 'XDR'
    | 'XFU'
    | 'XOF'
    | 'XPD'
    | 'XPF'
    | 'XPT'
    | 'XSU'
    | 'XTS'
    | 'XUA'
    | 'XXX'
    | 'YER'
    | 'ZAR'
    | 'ZMW';
  invoiceNumber?: string;
  dateReference?: 'PICKUP_DATE' | 'DELIVERY_DATE' | 'INVOICE_DATE' | 'INVOICE_DUE_DATE' | 'SUBMISSION_DATE';
  filters?: FiltersDto;
  /** @uniqueItems true */
  invoiceStatusSet?: ('FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT')[];
}

export interface PerformanceAssessmentKeyMetricRequestDto {
  /** The filters request details for the performance assessment */
  filtersRequest?: FiltersRequestDto;
  /** The service provider for the performance assessment */
  serviceProvider?: string;
  /** The modality for the performance assessment */
  modality?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
}

export interface PerformanceAssessmentVolumetricTableRequestDto {
  performanceAssessmentRequest?: PerformanceAssessmentKeyMetricRequestDto;
  page?: PageDto;
  sort?: VolumetricSortDto;
}

export interface ServiceProviderDto {
  serviceProviderName?: string;
  serviceProviderCode?: string;
  modality?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
}

export interface ShipmentFiltersDto {
  /** @uniqueItems true */
  serviceProviderName?: string[];
  /** @uniqueItems true */
  serviceProviderCode?: string[];
  /** @uniqueItems true */
  modality?: string[];
  /** @uniqueItems true */
  vehicleID?: string[];
}

export interface TradeLaneFiltersDto {
  /** @uniqueItems true */
  tradeLaneCompany?: string[];
  /** @uniqueItems true */
  tradeLaneCityHub?: string[];
  /** @uniqueItems true */
  tradeLaneCountry?: string[];
}

export interface VolumetricSortDto {
  field?:
    | 'VOLUMETRIC_MONTH'
    | 'VOLUMETRIC_TOTAL_AMOUNT'
    | 'GROSS_WEIGHT'
    | 'CHARGEABLE_WEIGHT'
    | 'VOLUME'
    | 'CO2_EMISSIONS'
    | 'VOLUMETRIC_SHIPMENTS_COUNT'
    | 'VOLUMETRIC_AUDITED';
  direction?: 'ASC' | 'DESC';
}

export interface PerformanceVolumetricTableDto {
  month?: string;
  totalAmount?: number;
  grossWeight?: number;
  chargeableWeight?: number;
  volume?: number;
  co2Emissions?: number;
  /** @format int64 */
  shipmentsCount?: number;
  audited?: number;
}

export interface PerformanceVolumetricTableResponseDto {
  page?: PageDto;
  /** @format int64 */
  total?: number;
  volumetricMonthWiseData?: PerformanceVolumetricTableDto[];
}

export interface ResponseDtoPerformanceVolumetricTableResponseDto {
  success?: boolean;
  message?: string;
  data?: PerformanceVolumetricTableResponseDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoVolumetricResponseDto {
  success?: boolean;
  message?: string;
  data?: VolumetricResponseDto;
  /** @format int32 */
  statusCode?: number;
}

export interface VolumetricAmount {
  totalValue?: Amount;
  pricePerUnit?: Amount;
  unitPerShipment?: Amount;
}

export interface VolumetricBigDecimal {
  totalValue?: number;
  pricePerUnit?: number;
  unitPerShipment?: number;
}

export interface VolumetricResponseDto {
  totalCo2Emissions?: VolumetricBigDecimal;
  chargeableWeight?: VolumetricBigDecimal;
  grossWeight?: VolumetricBigDecimal;
  volume?: VolumetricBigDecimal;
  askedAmount?: VolumetricAmount;
}

export interface PerformanceAssessmentSavingsTableRequestDto {
  performanceAssessmentRequest?: PerformanceAssessmentKeyMetricRequestDto;
  page?: PageDto;
  /** The sorting criteria for the savings table request */
  sort?: SavingsSortDto;
}

/** The sorting criteria for the savings table request */
export interface SavingsSortDto {
  /** The field to sort by */
  field?: 'MONTH' | 'TOTAL_AMOUNT' | 'DEVIATIONS' | 'SAVINGS' | 'SHIPMENTS_COUNT' | 'INVOICES_COUNT' | 'CHARGE_LINES_COUNT' | 'AUDITED';
  /** The direction of sorting */
  direction?: 'ASC' | 'DESC';
}

export interface PerformanceSavingsTableDto {
  month?: string;
  totalAmount?: number;
  deviations?: number;
  savings?: number;
  /** @format int64 */
  shipmentsCount?: number;
  /** @format int64 */
  invoicesCount?: number;
  /** @format int64 */
  chargeLinesCount?: number;
  audited?: number;
}

export interface PerformanceSavingsTableResponseDto {
  page?: PageDto;
  /** @format int64 */
  total?: number;
  savingsMonthWiseData?: PerformanceSavingsTableDto[];
}

export interface ResponseDtoPerformanceSavingsTableResponseDto {
  success?: boolean;
  message?: string;
  data?: PerformanceSavingsTableResponseDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoSavingsMetricResponseDto {
  success?: boolean;
  message?: string;
  data?: SavingsMetricResponseDto;
  /** @format int32 */
  statusCode?: number;
}

/** The savings metric for amount */
export interface SavingMetricAmountBigDecimal {
  beforeAudit?: Amount;
  afterAudit?: Amount;
  deviations?: Amount;
  savings?: Amount;
  amountPercentage?: number;
  deviationPercentage?: number;
  savingsPercentage?: number;
}

/** The savings metric for charge lines */
export interface SavingMetricLongBigDecimal {
  /** @format int64 */
  beforeAudit?: number;
  /** @format int64 */
  afterAudit?: number;
  /** @format int64 */
  deviations?: number;
  /** @format int64 */
  savings?: number;
  amountPercentage?: number;
  deviationPercentage?: number;
  savingsPercentage?: number;
}

export interface SavingsMetricResponseDto {
  /** The savings metric for amount */
  amount?: SavingMetricAmountBigDecimal;
  /** The savings metric for charge lines */
  shipments?: SavingMetricLongBigDecimal;
  /** The savings metric for charge lines */
  invoices?: SavingMetricLongBigDecimal;
  /** The savings metric for charge lines */
  chargeLines?: SavingMetricLongBigDecimal;
}

export interface KeyMetricsAggregatedDto {
  totalAmount?: Amount;
  totalDeviationAmount?: Amount;
  totalCo2Emissions?: number;
  chargeableWeight?: number;
  grossWeight?: number;
  volume?: number;
  /** @format int64 */
  noShipments?: number;
  /** @format int64 */
  noCharges?: number;
  /** @format int64 */
  noInvoices?: number;
}

export interface KeyMetricsMetaDto {
  currency?:
    | 'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ANG'
    | 'AOA'
    | 'ARS'
    | 'AUD'
    | 'AWG'
    | 'AZN'
    | 'BAM'
    | 'BBD'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BMD'
    | 'BND'
    | 'BOB'
    | 'BOV'
    | 'BRL'
    | 'BSD'
    | 'BTN'
    | 'BWP'
    | 'BYN'
    | 'BYR'
    | 'BZD'
    | 'CAD'
    | 'CDF'
    | 'CHE'
    | 'CHF'
    | 'CHW'
    | 'CLF'
    | 'CLP'
    | 'CNY'
    | 'COP'
    | 'COU'
    | 'CRC'
    | 'CUC'
    | 'CUP'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'EUR'
    | 'FJD'
    | 'FKP'
    | 'GBP'
    | 'GEL'
    | 'GHS'
    | 'GIP'
    | 'GMD'
    | 'GNF'
    | 'GTQ'
    | 'GYD'
    | 'HKD'
    | 'HNL'
    | 'HRK'
    | 'HTG'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KGS'
    | 'KHR'
    | 'KMF'
    | 'KPW'
    | 'KRW'
    | 'KWD'
    | 'KYD'
    | 'KZT'
    | 'LAK'
    | 'LBP'
    | 'LKR'
    | 'LRD'
    | 'LSL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MNT'
    | 'MOP'
    | 'MRO'
    | 'MUR'
    | 'MVR'
    | 'MWK'
    | 'MXN'
    | 'MXV'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NGN'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PGK'
    | 'PHP'
    | 'PKR'
    | 'PLN'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SBD'
    | 'SCR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SHP'
    | 'SLL'
    | 'SOS'
    | 'SRD'
    | 'SSP'
    | 'STD'
    | 'SYP'
    | 'SZL'
    | 'THB'
    | 'TJS'
    | 'TMT'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'USD'
    | 'USN'
    | 'UYI'
    | 'UYU'
    | 'UZS'
    | 'VEF'
    | 'VND'
    | 'VUV'
    | 'WST'
    | 'XAF'
    | 'XAG'
    | 'XAU'
    | 'XBA'
    | 'XBB'
    | 'XBC'
    | 'XBD'
    | 'XCD'
    | 'XDR'
    | 'XFU'
    | 'XOF'
    | 'XPD'
    | 'XPF'
    | 'XPT'
    | 'XSU'
    | 'XTS'
    | 'XUA'
    | 'XXX'
    | 'YER'
    | 'ZAR'
    | 'ZMW';
  grossWeightUnit?: string;
  chargeableWeightUnit?: string;
  co2EmissionUnit?: string;
  volumeUnit?: string;
}

export interface KeyMetricsResponseDto {
  keymetrics?: KeyMetricsAggregatedDto;
  meta?: KeyMetricsMetaDto;
}

export interface ResponseDtoKeyMetricsResponseDto {
  success?: boolean;
  message?: string;
  data?: KeyMetricsResponseDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoMapStringBoolean {
  success?: boolean;
  message?: string;
  data?: Record<string, boolean>;
  /** @format int32 */
  statusCode?: number;
}

export interface InvoiceSearchRequestDto {
  invoiceNumber?: string;
  invoiceStatusCategory?: 'ALL' | 'ACTION_NEEDED';
  dateReference?: 'PICKUP_DATE' | 'DELIVERY_DATE' | 'INVOICE_DATE' | 'INVOICE_DUE_DATE' | 'SUBMISSION_DATE';
  searchFilters?: FiltersDto;
  /** @uniqueItems true */
  invoiceStatusSet?: ('FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT')[];
  page?: PageDto;
  sorting?: SortDto;
  currencyReference?:
    | 'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ANG'
    | 'AOA'
    | 'ARS'
    | 'AUD'
    | 'AWG'
    | 'AZN'
    | 'BAM'
    | 'BBD'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BMD'
    | 'BND'
    | 'BOB'
    | 'BOV'
    | 'BRL'
    | 'BSD'
    | 'BTN'
    | 'BWP'
    | 'BYN'
    | 'BYR'
    | 'BZD'
    | 'CAD'
    | 'CDF'
    | 'CHE'
    | 'CHF'
    | 'CHW'
    | 'CLF'
    | 'CLP'
    | 'CNY'
    | 'COP'
    | 'COU'
    | 'CRC'
    | 'CUC'
    | 'CUP'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'EUR'
    | 'FJD'
    | 'FKP'
    | 'GBP'
    | 'GEL'
    | 'GHS'
    | 'GIP'
    | 'GMD'
    | 'GNF'
    | 'GTQ'
    | 'GYD'
    | 'HKD'
    | 'HNL'
    | 'HRK'
    | 'HTG'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KGS'
    | 'KHR'
    | 'KMF'
    | 'KPW'
    | 'KRW'
    | 'KWD'
    | 'KYD'
    | 'KZT'
    | 'LAK'
    | 'LBP'
    | 'LKR'
    | 'LRD'
    | 'LSL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MNT'
    | 'MOP'
    | 'MRO'
    | 'MUR'
    | 'MVR'
    | 'MWK'
    | 'MXN'
    | 'MXV'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NGN'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PGK'
    | 'PHP'
    | 'PKR'
    | 'PLN'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SBD'
    | 'SCR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SHP'
    | 'SLL'
    | 'SOS'
    | 'SRD'
    | 'SSP'
    | 'STD'
    | 'SYP'
    | 'SZL'
    | 'THB'
    | 'TJS'
    | 'TMT'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'USD'
    | 'USN'
    | 'UYI'
    | 'UYU'
    | 'UZS'
    | 'VEF'
    | 'VND'
    | 'VUV'
    | 'WST'
    | 'XAF'
    | 'XAG'
    | 'XAU'
    | 'XBA'
    | 'XBB'
    | 'XBC'
    | 'XBD'
    | 'XCD'
    | 'XDR'
    | 'XFU'
    | 'XOF'
    | 'XPD'
    | 'XPF'
    | 'XPT'
    | 'XSU'
    | 'XTS'
    | 'XUA'
    | 'XXX'
    | 'YER'
    | 'ZAR'
    | 'ZMW';
}

export interface SortDto {
  field?:
    | 'INVOICE_STATUS'
    | 'INVOICE_NUMBER'
    | 'INVOICE_DATE'
    | 'INVOICE_DUE_DATE'
    | 'PICKUP_DATE'
    | 'DELIVERY_DATE'
    | 'SUBMISSION_DATE'
    | 'SHIPMENTS'
    | 'TRADE_LANES'
    | 'SERVICES'
    | 'TOTAL_AMOUNT'
    | 'MBL_HBL';
  sortingDirection?: 'ASC' | 'DESC';
}

export interface InvoiceSearchResponseDto {
  stats?: InvoiceStatusCategoryStatsDto;
  invoiceStatusCategory?: 'ALL' | 'ACTION_NEEDED';
  /** @format int64 */
  totalRecord?: number;
  page?: PageDto;
  invoices?: InvoiceDto[];
}

export interface InvoiceStatusCategoryStatsDto {
  /** @format int64 */
  actionNeeded?: number;
  /** @format int64 */
  all?: number;
}

export interface ResponseDtoInvoiceSearchResponseDto {
  success?: boolean;
  message?: string;
  data?: InvoiceSearchResponseDto;
  /** @format int32 */
  statusCode?: number;
}

export interface InvoiceUploadSignedUrlRequestDto {
  /** @uniqueItems true */
  fileNames: string[];
  modality?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  serviceProviderName?: string;
  serviceProviderCode?: string;
  /** @format uuid */
  batchId?: string;
}

export interface InvoiceUploadSignedUrlDto {
  fileName?: string;
  preSignedURLs?: string;
  headers?: Record<string, string>;
  /** @format uuid */
  dataPipelineProcessingId?: string;
}

export interface InvoiceUploadSignedUrlResponseDto {
  uploadUrls?: InvoiceUploadSignedUrlDto[];
  /** @format uuid */
  batchId?: string;
}

export interface ResponseDtoInvoiceUploadSignedUrlResponseDto {
  success?: boolean;
  message?: string;
  data?: InvoiceUploadSignedUrlResponseDto;
  /** @format int32 */
  statusCode?: number;
}

export interface InvoiceAttachmentUploadSignedUrlRequestDto {
  /** @format uuid */
  invoiceId: string;
  fileName: string;
  attachmentType: 'APPROVAL' | 'RATE_CARD' | 'ADDITIONAL_ATTACHMENT';
}

export interface SetFapRefRequest {
  invoiceIds?: string[];
  fapRef?: string;
}

export interface SearchingInvoiceFileProcessingRequest {
  ids?: string[];
  /** @format date-time */
  from?: string;
  /** @format date-time */
  to?: string;
  statuses?: (
    | 'BEING_UPLOADED'
    | 'COMPLETE'
    | 'OCR_PROCESSING'
    | 'OCR_COMPLETE'
    | 'OCR_ERROR'
    | 'TRANSFORM_ERROR'
    | 'TRANSFORM_COMPLETE'
    | 'MESSAGE_PROCESSING_FAILED'
    | 'UPLOAD_FROM_EMAIL_FAILED'
    | 'RESOLVED_AS_NOT_AN_INVOICE'
    | 'RESOLVED_AS_MISSING_REQUIRED_FIELD'
    | 'RESOLVED_AS_INCONSISTENT_SHIPMENT_DATA'
    | 'MARKED_MISSING_REQUIRED_FIELD_FOR_REVIEW'
    | 'MARKED_INCONSISTENT_SHIPMENT_DATA_FOR_REVIEW'
    | 'USER_MARK_AS_ERROR'
  )[];
  fileResolutionStatuses?: ('REVIEW_REQUIRED' | 'UNDER_REVIEW' | 'NOT_AN_INVOICE' | 'MISSING_REQUIRED_FIELD' | 'INCONSISTENT_SHIPMENT_DATA' | 'FIXED')[];
  /** @format int32 */
  page?: number;
  /** @format int32 */
  size?: number;
  sortingColum?: string;
  sortingDirection?: 'ASC' | 'DESC';
}

export interface InternalDashboardTableResponseDtoInvoiceFileProcessingDto {
  tableStats?: InternalDashboardTableStatsDto;
  tableData?: PageInvoiceFileProcessingDto;
}

export interface InternalDashboardTableStatsDto {
  /** @format int64 */
  actionNeeded?: number;
  /** @format int64 */
  allIssues?: number;
  /** @format int64 */
  allFiles?: number;
}

export interface PageInvoiceFileProcessingDto {
  /** @format int32 */
  totalPages?: number;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  size?: number;
  content?: InvoiceFileProcessingDto[];
  /** @format int32 */
  number?: number;
  sort?: SortObject[];
  pageable?: PageableObject;
  /** @format int32 */
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface PageableObject {
  /** @format int64 */
  offset?: number;
  sort?: SortObject[];
  /** @format int32 */
  pageNumber?: number;
  /** @format int32 */
  pageSize?: number;
  paged?: boolean;
  unpaged?: boolean;
}

export interface ResponseDtoInternalDashboardTableResponseDtoInvoiceFileProcessingDto {
  success?: boolean;
  message?: string;
  data?: InternalDashboardTableResponseDtoInvoiceFileProcessingDto;
  /** @format int32 */
  statusCode?: number;
}

export interface SortObject {
  direction?: string;
  nullHandling?: string;
  ascending?: boolean;
  property?: string;
  ignoreCase?: boolean;
}

export interface InvoiceDataPipelineConfigDto {
  /** @format uuid */
  id?: string;
  serviceProviderCode?: string;
  serviceProviderName?: string;
  dataPipelineConfigId?: string;
  modality?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  /** @format date-time */
  updatedOn?: string;
  modelId?: string;
}

export interface ResponseDtoInvoiceDataPipelineConfigDto {
  success?: boolean;
  message?: string;
  data?: InvoiceDataPipelineConfigDto;
  /** @format int32 */
  statusCode?: number;
}

export interface InvoiceDataPipelineConfigRequestDto {
  modalities?: ('ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT')[];
  serviceProviderCodes?: string[];
  modelIds?: string[];
}

export interface ResponseDtoListInvoiceDataPipelineConfigDto {
  success?: boolean;
  message?: string;
  data?: InvoiceDataPipelineConfigDto[];
  /** @format int32 */
  statusCode?: number;
}

export interface FilterInvoiceChargeDomainMappingReq {
  serviceProviderCode: string;
  invoiceChargeName?: string;
  chargeDomain?:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
  chargeSubDomain?: string;
  legType?: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  contractChargeUnit?: 'SHIPMENT' | 'DECLARATION' | 'CONTAINER' | 'WEIGHT' | 'PERCENTAGE';
  paginationRequest: PaginationRequest;
}

export interface PaginationRequest {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  size?: number;
  sortingColum?: string;
  sortingDirection?: 'ASC' | 'DESC';
}

export interface PageInvoiceChargeDomainMappingDto {
  /** @format int32 */
  totalPages?: number;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  size?: number;
  content?: InvoiceChargeDomainMappingDto[];
  /** @format int32 */
  number?: number;
  sort?: SortObject[];
  pageable?: PageableObject;
  /** @format int32 */
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface ResponseDtoPageInvoiceChargeDomainMappingDto {
  success?: boolean;
  message?: string;
  data?: PageInvoiceChargeDomainMappingDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoFiltersDto {
  success?: boolean;
  message?: string;
  data?: FiltersDto;
  /** @format int32 */
  statusCode?: number;
}

export interface FilterFileResolutionRequest {
  invoiceFileProcessingIds?: string[];
  /** @format date-time */
  from?: string;
  /** @format date-time */
  to?: string;
  fileResolutionStatuses?: ('REVIEW_REQUIRED' | 'UNDER_REVIEW' | 'NOT_AN_INVOICE' | 'MISSING_REQUIRED_FIELD' | 'INCONSISTENT_SHIPMENT_DATA' | 'FIXED')[];
  isAssign?: boolean;
  assigneeIds?: string[];
  /** @format int32 */
  page?: number;
  /** @format int32 */
  size?: number;
  isUnresolvedStatus?: boolean;
  sortingColum?: string;
  sortingDirection?: 'ASC' | 'DESC';
}

export interface GettingFileResolutionDto {
  modality?: string;
  extractedPage?: string;
  modelUsed?: string;
  transformerUsed?: string;
  invoiceNumber?: string;
  serviceProviderName?: string;
  billedEntity?: string;
  /** @format int32 */
  id?: number;
  resolutionType?: 'ISUSR' | 'ISSYS';
  /** @format uuid */
  invoiceId?: string;
  resolutionStatus?: 'REVIEW_REQUIRED' | 'UNDER_REVIEW' | 'NOT_AN_INVOICE' | 'MISSING_REQUIRED_FIELD' | 'INCONSISTENT_SHIPMENT_DATA' | 'FIXED';
  resolutionMessage?: string;
  userMessage?: string;
  /** @format uuid */
  assigneeId?: string;
  assigneeFirstName?: string;
  assigneeLastName?: string;
  /** @format date-time */
  resolutionCreatedOn?: string;
  resolutionCreatedBy?: string;
  /** @format date-time */
  resolutionUpdatedOn?: string;
  /** @format uuid */
  invoiceFileProcessingId?: string;
  fileName?: string;
  invoiceFileProcessingStatus?:
    | 'BEING_UPLOADED'
    | 'COMPLETE'
    | 'OCR_PROCESSING'
    | 'OCR_COMPLETE'
    | 'OCR_ERROR'
    | 'TRANSFORM_ERROR'
    | 'TRANSFORM_COMPLETE'
    | 'MESSAGE_PROCESSING_FAILED'
    | 'UPLOAD_FROM_EMAIL_FAILED'
    | 'RESOLVED_AS_NOT_AN_INVOICE'
    | 'RESOLVED_AS_MISSING_REQUIRED_FIELD'
    | 'RESOLVED_AS_INCONSISTENT_SHIPMENT_DATA'
    | 'MARKED_MISSING_REQUIRED_FIELD_FOR_REVIEW'
    | 'MARKED_INCONSISTENT_SHIPMENT_DATA_FOR_REVIEW'
    | 'USER_MARK_AS_ERROR';
  invoiceFileProcessingMessage?: string;
  /** @format uuid */
  batchId?: string;
  reporterFirstName?: string;
  reporterLastName?: string;
  invoiceStatus?: 'FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT';
  resolutionCode?: string;
  currentDeviationWorkflow?:
    | 'OPERATION_REVIEW'
    | 'PROCUREMENT_REVIEW'
    | 'PENDING_REVIEW'
    | 'DISPUTE_REVIEW'
    | 'PROCUREMENT_NO_CONTRACT_REVIEW'
    | 'FINANCE_REVIEW';
}

export interface InternalDashboardTableResponseDtoGettingFileResolutionDto {
  tableStats?: InternalDashboardTableStatsDto;
  tableData?: PageGettingFileResolutionDto;
}

export interface PageGettingFileResolutionDto {
  /** @format int32 */
  totalPages?: number;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  size?: number;
  content?: GettingFileResolutionDto[];
  /** @format int32 */
  number?: number;
  sort?: SortObject[];
  pageable?: PageableObject;
  /** @format int32 */
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface ResponseDtoInternalDashboardTableResponseDtoGettingFileResolutionDto {
  success?: boolean;
  message?: string;
  data?: InternalDashboardTableResponseDtoGettingFileResolutionDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ExchangeRateDto {
  /** @format uuid */
  id?: string;
  baseCurrency?: string;
  toCurrency?: string;
  exchangeRate?: number;
  day?: string;
  month?: string;
  quarter?: string;
  createdBy?: string;
}

export interface ResponseDtoExchangeRateDto {
  success?: boolean;
  message?: string;
  data?: ExchangeRateDto;
  /** @format int32 */
  statusCode?: number;
}

export interface CreatingContractReq {
  /** @format uuid */
  shippingServiceId: string;
  code?: string;
  /** @format date */
  startDate: string;
  /** @format date */
  endDate: string;
  filePath?: string;
}

export interface SystemConfigurationDto {
  type?: string;
  value?: string;
}

export interface ResponseDtoListString {
  success?: boolean;
  message?: string;
  data?: string[];
  /** @format int32 */
  statusCode?: number;
}

/** The search request details for the shipment search */
export interface AnalyticSearchRequestDto {
  /** The currency reference for the analytic search */
  currencyReference?:
    | 'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ANG'
    | 'AOA'
    | 'ARS'
    | 'AUD'
    | 'AWG'
    | 'AZN'
    | 'BAM'
    | 'BBD'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BMD'
    | 'BND'
    | 'BOB'
    | 'BOV'
    | 'BRL'
    | 'BSD'
    | 'BTN'
    | 'BWP'
    | 'BYN'
    | 'BYR'
    | 'BZD'
    | 'CAD'
    | 'CDF'
    | 'CHE'
    | 'CHF'
    | 'CHW'
    | 'CLF'
    | 'CLP'
    | 'CNY'
    | 'COP'
    | 'COU'
    | 'CRC'
    | 'CUC'
    | 'CUP'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'EUR'
    | 'FJD'
    | 'FKP'
    | 'GBP'
    | 'GEL'
    | 'GHS'
    | 'GIP'
    | 'GMD'
    | 'GNF'
    | 'GTQ'
    | 'GYD'
    | 'HKD'
    | 'HNL'
    | 'HRK'
    | 'HTG'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KGS'
    | 'KHR'
    | 'KMF'
    | 'KPW'
    | 'KRW'
    | 'KWD'
    | 'KYD'
    | 'KZT'
    | 'LAK'
    | 'LBP'
    | 'LKR'
    | 'LRD'
    | 'LSL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MNT'
    | 'MOP'
    | 'MRO'
    | 'MUR'
    | 'MVR'
    | 'MWK'
    | 'MXN'
    | 'MXV'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NGN'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PGK'
    | 'PHP'
    | 'PKR'
    | 'PLN'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SBD'
    | 'SCR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SHP'
    | 'SLL'
    | 'SOS'
    | 'SRD'
    | 'SSP'
    | 'STD'
    | 'SYP'
    | 'SZL'
    | 'THB'
    | 'TJS'
    | 'TMT'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'USD'
    | 'USN'
    | 'UYI'
    | 'UYU'
    | 'UZS'
    | 'VEF'
    | 'VND'
    | 'VUV'
    | 'WST'
    | 'XAF'
    | 'XAG'
    | 'XAU'
    | 'XBA'
    | 'XBB'
    | 'XBC'
    | 'XBD'
    | 'XCD'
    | 'XDR'
    | 'XFU'
    | 'XOF'
    | 'XPD'
    | 'XPF'
    | 'XPT'
    | 'XSU'
    | 'XTS'
    | 'XUA'
    | 'XXX'
    | 'YER'
    | 'ZAR'
    | 'ZMW';
  /** The granularity level for the analytic search */
  granularity:
    | 'QUARTERLY'
    | 'MONTHLY'
    | 'WEEKLY'
    | 'DAILY'
    | 'YEARLY'
    | 'SERVICE_PROVIDER_NAME'
    | 'SERVICE_PROVIDER_CODE'
    | 'MODALITY'
    | 'VEHICLE_ID'
    | 'COUNTRY'
    | 'CITY_HUB'
    | 'COMPANY'
    | 'LEG_TYPE'
    | 'CHARGE_DOMAIN';
  /** The sorting order for the analytic search results */
  sortingOrder?: 'ASC' | 'DESC';
  /** The date reference for filtering the analytic search */
  dateReference?: 'PICKUP_DATE' | 'DELIVERY_DATE' | 'INVOICE_DATE' | 'INVOICE_DUE_DATE' | 'SUBMISSION_DATE';
  filters?: FiltersDto;
  /**
   * The set of invoice statuses for the analytic search
   * @uniqueItems true
   */
  invoiceStatusSet?: ('FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT')[];
}

export interface ShipmentSearchRequestDto {
  /** The sorting criteria for the shipment search */
  sorting?: ShipmentSortDto;
  page?: PageDto;
  /** The search request details for the shipment search */
  searchRequest?: AnalyticSearchRequestDto;
}

/** The sorting criteria for the shipment search */
export interface ShipmentSortDto {
  /**
   * * SHIPMENT_NUMBER - The unique identifier for the shipment.
   * * INVOICE_DATE - The date the invoice was issued.
   * * INVOICE_DUE_DATE - The due date for the invoice payment.
   * * PICKUP_DATE - The date the shipment was picked up.
   * * DELIVERY_DATE - The date the shipment was delivered.
   * * SUBMISSION_DATE - The date the shipment was submitted.
   * * INVOICE_COUNT - The total number of invoices associated with the shipment.
   * * INVOICE_CHARGES_COUNT - The total number of charges in the invoice.
   * * TOTAL_AMOUNT_INVOICE_CURRENCY - The total amount in the invoice currency.
   * * TOTAL_AMOUNT - The total amount for the shipment.
   * * GROSS_WEIGHT - The gross weight of the shipment.
   * * CHARGEABLE_WEIGHT - The chargeable weight of the shipment.
   * * VOLUME - The volume of the shipment.
   * * CO2_EMISSION - The CO2 emissions associated with the shipment.
   * * SERVICE_PROVIDER - The service provider handling the shipment.
   * * MODALITY - The mode of transportation for the shipment.
   * * VEHICLE_ID - The identifier for the vehicle used in the shipment.
   * * SHIPPER_NAME - The name of the shipper.
   * * POL - The port of loading for the shipment.
   * * SHIPPER_COUNTRY - The country of the shipper.
   * * CONSIGNEE_NAME - The name of the consignee.
   * * POD - The port of discharge for the shipment.
   * * CONSIGNEE_COUNTRY - The country of the consignee.
   * * GOOD_NAME - The name of the goods being shipped.
   * * PACKAGE_COUNT - The number of packages in the shipment.
   * * PACKAGE_UNIT - The unit of measurement for the packages.
   * * SERVICE_TYPE - The type of service provided for the shipment.
   * * INVOICE_NUMBER - The number of the invoice.
   * * INVOICE_STATUS - The status of the invoice.
   * * SHIPMENT_CURRENT_DEVIATION_WORKFLOW - The current deviation workflow for the shipment.
   * * BILLED_ENTITY - The entity being billed for the shipment.
   * * TOTAL_CHARGE_AMOUNT_LEG_1 - The total charge amount for leg 1 of the shipment.
   * * TOTAL_CHARGE_AMOUNT_LEG_2 - The total charge amount for leg 2 of the shipment.
   * * TOTAL_CHARGE_AMOUNT_LEG_3 - The total charge amount for leg 3 of the shipment.
   * * LEAD_TIME - The lead time for the shipment.
   */
  field?:
    | 'SHIPMENT_NUMBER'
    | 'INVOICE_DATE'
    | 'INVOICE_DUE_DATE'
    | 'PICKUP_DATE'
    | 'DELIVERY_DATE'
    | 'SUBMISSION_DATE'
    | 'INVOICE_COUNT'
    | 'INVOICE_CHARGES_COUNT'
    | 'TOTAL_AMOUNT_INVOICE_CURRENCY'
    | 'TOTAL_AMOUNT'
    | 'GROSS_WEIGHT'
    | 'CHARGEABLE_WEIGHT'
    | 'VOLUME'
    | 'CO2_EMISSION'
    | 'SERVICE_PROVIDER'
    | 'MODALITY'
    | 'VEHICLE_ID'
    | 'SHIPPER_NAME'
    | 'POL'
    | 'SHIPPER_COUNTRY'
    | 'CONSIGNEE_NAME'
    | 'POD'
    | 'CONSIGNEE_COUNTRY'
    | 'GOOD_NAME'
    | 'PACKAGE_COUNT'
    | 'PACKAGE_UNIT'
    | 'SERVICE_TYPE'
    | 'INVOICE_NUMBER'
    | 'INVOICE_STATUS'
    | 'SHIPMENT_CURRENT_DEVIATION_WORKFLOW'
    | 'BILLED_ENTITY'
    | 'TOTAL_CHARGE_AMOUNT_LEG_1'
    | 'TOTAL_CHARGE_AMOUNT_LEG_2'
    | 'TOTAL_CHARGE_AMOUNT_LEG_3'
    | 'LEAD_TIME';
  /** The direction of sorting */
  direction?: 'ASC' | 'DESC';
}

export interface ResponseDtoShipmentSearchResponseDto {
  success?: boolean;
  message?: string;
  data?: ShipmentSearchResponseDto;
  /** @format int32 */
  statusCode?: number;
}

/** The list of shipments */
export interface ShipmentDto {
  /**
   * The unique identifier of the invoice
   * @format uuid
   */
  invoiceId?: string;
  /** The shipment number */
  shipmentNumber?: string;
  /** The submission date of the shipment */
  submissionDate?: string;
  /** The pickup date of the shipment */
  pickupDate?: string;
  /** The delivery date of the shipment */
  deliveryDate?: string;
  /** The due date of the invoice */
  invoiceDueDate?: string;
  /** The date of the invoice */
  invoiceDate?: string;
  /**
   * The count of invoices
   * @format int32
   */
  invoiceCount?: number;
  /**
   * The count of invoice charges
   * @format int32
   */
  invoiceChargesCount?: number;
  /** The total amount of the shipment */
  totalAmount?: number;
  /** The currency of the total amount */
  totalAmountCurrency?: string;
  /** The invoice amount after tax */
  invoiceAmountAfterTax?: number;
  /** The currency of the invoice */
  invoiceCurrency?: string;
  /** The gross weight of the shipment */
  grossWeight?: number;
  /** The chargeable weight of the shipment */
  chargeableWeight?: number;
  /** The volume of the shipment */
  volume?: number;
  /** The CO2 emission of the shipment */
  co2Emission?: number;
  /** The service provider of the shipment */
  serviceProvider?: string;
  /** The modality of the shipment */
  modality?: string;
  /** The vehicle ID of the shipment */
  vehicleId?: string;
  /** The shipper of the shipment */
  shipper?: string;
  /** The port of loading */
  pol?: string;
  /** The country of the shipper */
  shipperCountry?: string;
  /** The consignee of the shipment */
  consignee?: string;
  /** The port of discharge */
  pod?: string;
  /** The country of the consignee */
  consigneeCountry?: string;
  /** The name of the goods */
  goodsName?: string;
  /** The count of packages */
  packageCount?: string;
  /** The unit of the packages */
  packageUnit?: string;
  /** The billed entity */
  billedEntity?: string;
  /** The type of the shipment */
  shipmentType?: 'IMPORT' | 'EXPORT' | 'MULTI';
  /** The service type of the shipment */
  serviceType?: string;
  /** The special type of the shipment */
  specialType?: string;
  /** The invoice number */
  invoiceNumber?: string;
  /** The status of the invoice */
  invoiceStatus?: 'FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT';
  /** The current deviation workflow of the shipment */
  shipmentCurrentDeviationWorkflow?:
    | 'OPERATION_REVIEW'
    | 'PROCUREMENT_REVIEW'
    | 'PENDING_REVIEW'
    | 'DISPUTE_REVIEW'
    | 'PROCUREMENT_NO_CONTRACT_REVIEW'
    | 'FINANCE_REVIEW';
  /** The total charge amount for leg 1 */
  totalChargeAmountLeg1?: number;
  /** The total charge amount for leg 2 */
  totalChargeAmountLeg2?: number;
  /** The total charge amount for leg 3 */
  totalChargeAmountLeg3?: number;
  /**
   * The lead time of the shipment
   * @format int64
   */
  leadTime?: number;
}

export interface ShipmentSearchResponseDto {
  page?: PageDto;
  /**
   * The total number of shipments
   * @format int64
   */
  total?: number;
  /** The list of shipments */
  shipments?: ShipmentDto[];
}

export interface InvoiceAnalyticSearchRequestDto {
  /** The sorting criteria for the invoice analytics search */
  sorting?: InvoiceSortDto;
  page?: PageDto;
  /** The search request details for the shipment search */
  searchRequest?: AnalyticSearchRequestDto;
}

/** The sorting criteria for the invoice analytics search */
export interface InvoiceSortDto {
  /** The field to sort by */
  field?:
    | 'INVOICE_NUMBER'
    | 'INVOICE_DATE'
    | 'INVOICE_DUE_DATE'
    | 'PICKUP_DATE'
    | 'DELIVERY_DATE'
    | 'SUBMISSION_DATE'
    | 'RESOLUTION_DATE'
    | 'FAP_REF'
    | 'SHIPMENT_COUNT'
    | 'INVOICE_CHARGES_COUNT'
    | 'BILLED_ENTITY_NAME'
    | 'INVOICE_STATUS'
    | 'TOTAL_AMOUNT'
    | 'INVOICE_TOTAL_AMOUNT'
    | 'SERVICE_PROVIDER_NAME'
    | 'SHIPPER_NAME'
    | 'MODALITY'
    | 'POL'
    | 'SHIPPER_COUNTRY'
    | 'CONSIGNEE_NAME'
    | 'CONSIGNEE_COUNTRY'
    | 'POD'
    | 'ACCOUNT_NUMBER';
  /** The direction of sorting */
  direction?: 'ASC' | 'DESC';
}

/** The list of invoice analytics data */
export interface InvoiceAnalyticsDto {
  /** The invoice number */
  invoiceNumber?: string;
  /** The shipment number */
  shipmentNumber?: string;
  /** The pickup date */
  pickupDate?: string;
  /** The delivery date */
  deliveryDate?: string;
  /** The invoice date */
  invoiceDate?: string;
  /** The invoice due date */
  invoiceDueDate?: string;
  /**
   * The shipment count
   * @format int32
   */
  shipmentCount?: number;
  /**
   * The invoice charges count
   * @format int32
   */
  invoiceChargesCount?: number;
  /** The billed entity */
  billedEntity?: string;
  /** The invoice status */
  invoiceStatus?: 'FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT';
  /** The total amount */
  totalAmount?: number;
  /** The invoice currency */
  invoiceCurrency?: string;
  /** The total amount in reference currency */
  totalAmountInReferenceCurrency?: number;
  /** The invoice reference currency */
  invoiceReferenceCurrency?: string;
  /** The service provider */
  serviceProvider?: string;
  /** The modality */
  modality?: string;
  /** The shipper */
  shipper?: string;
  /** The port of loading (POL) */
  pol?: string;
  /** The shipper country */
  shipperCountry?: string;
  /** The consignee */
  consignee?: string;
  /** The port of discharge (POD) */
  pod?: string;
  /** The consignee country */
  consigneeCountry?: string;
  /** The goods purchase order reference */
  goodsPoRef?: string;
  /** The FAP reference */
  fapRef?: string;
  /** The submission date */
  submissionDate?: string;
  /** The resolution date */
  resolutionDate?: string;
  /**
   * The invoice ID
   * @format uuid
   */
  invoiceId?: string;
  /** The account number */
  accountNumber?: string;
  /** The shipment type */
  shipmentType?: 'IMPORT' | 'EXPORT' | 'MULTI';
}

export interface InvoiceAnalyticsSearchResponseDto {
  page?: PageDto;
  /**
   * The total number of invoices
   * @format int64
   */
  total?: number;
  /** The list of invoice analytics data */
  invoices?: InvoiceAnalyticsDto[];
}

export interface ResponseDtoInvoiceAnalyticsSearchResponseDto {
  success?: boolean;
  message?: string;
  data?: InvoiceAnalyticsSearchResponseDto;
  /** @format int32 */
  statusCode?: number;
}

export interface InvoiceChargeSearchRequestDto {
  /** The sorting criteria for the invoice charge search */
  sorting?: InvoiceChargeSortDto;
  page?: PageDto;
  /** The search request details for the shipment search */
  searchRequest?: AnalyticSearchRequestDto;
}

/** The sorting criteria for the invoice charge search */
export interface InvoiceChargeSortDto {
  /** The field to sort by */
  field?:
    | 'INVOICE_NUMBER'
    | 'SHIPMENT_NUMBER'
    | 'INVOICE_DATE'
    | 'PICKUP_DATE'
    | 'INVOICE_DUE_DATE'
    | 'DELIVERY_DATE'
    | 'SUBMISSION_DATE'
    | 'CHARGE_DOMAIN'
    | 'CHARGE_NAME'
    | 'CHARGE_AMOUNT'
    | 'INVOICE_CURRENCY'
    | 'CHARGE_QUANTITY'
    | 'CHARGE_UNIT_PRICE'
    | 'CHARGE_CURRENCY'
    | 'EXCHANGE_RATE'
    | 'SERVICE_PROVIDER_NAME'
    | 'SHIPPER_NAME'
    | 'MODALITY'
    | 'POL'
    | 'POD'
    | 'SHIPPER_COUNTRY'
    | 'CONSIGNEE_NAME'
    | 'CONSIGNEE_COUNTRY'
    | 'QUANTITY_UNIT'
    | 'LEG_TYPE'
    | 'CHARGE_AMOUNT_INVOICE_CURRENCY'
    | 'CHARGE_AMOUNT_REFERENCE_CURRENCY'
    | 'DEVIATION_AMOUNT_INVOICE_CURRENCY'
    | 'BILLED_ENTITY';
  /** The direction of sorting */
  direction?: 'ASC' | 'DESC';
}

/** The list of invoice charge data */
export interface InvoiceChargeDto {
  /**
   * The invoice ID
   * @format uuid
   */
  invoiceId?: string;
  /**
   * The invoice charge ID
   * @format uuid
   */
  invoiceChargeId?: string;
  /** The shipment number */
  shipmentNumber?: string;
  /** The invoice number */
  invoiceNumber?: string;
  /** The invoice date */
  invoiceDate?: string;
  /** The pickup date */
  pickupDate?: string;
  /** The submission date */
  submissionDate?: string;
  /** The delivery date */
  deliveryDate?: string;
  /** The invoice due date */
  invoiceDueDate?: string;
  /** The charge domain */
  chargeDomain?:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
  /** The charge name */
  chargeName?: string;
  /** The charge unit price */
  chargeUnitPrice?: number;
  /**
   * The charge quantity
   * @format int32
   */
  chargeQuantity?: number;
  /** The quantity unit */
  quantityUnit?: string;
  /** The charge amount */
  chargeAmount?: number;
  /** The charge currency */
  chargeCurrency?:
    | 'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ANG'
    | 'AOA'
    | 'ARS'
    | 'AUD'
    | 'AWG'
    | 'AZN'
    | 'BAM'
    | 'BBD'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BMD'
    | 'BND'
    | 'BOB'
    | 'BOV'
    | 'BRL'
    | 'BSD'
    | 'BTN'
    | 'BWP'
    | 'BYN'
    | 'BYR'
    | 'BZD'
    | 'CAD'
    | 'CDF'
    | 'CHE'
    | 'CHF'
    | 'CHW'
    | 'CLF'
    | 'CLP'
    | 'CNY'
    | 'COP'
    | 'COU'
    | 'CRC'
    | 'CUC'
    | 'CUP'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'EUR'
    | 'FJD'
    | 'FKP'
    | 'GBP'
    | 'GEL'
    | 'GHS'
    | 'GIP'
    | 'GMD'
    | 'GNF'
    | 'GTQ'
    | 'GYD'
    | 'HKD'
    | 'HNL'
    | 'HRK'
    | 'HTG'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KGS'
    | 'KHR'
    | 'KMF'
    | 'KPW'
    | 'KRW'
    | 'KWD'
    | 'KYD'
    | 'KZT'
    | 'LAK'
    | 'LBP'
    | 'LKR'
    | 'LRD'
    | 'LSL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MNT'
    | 'MOP'
    | 'MRO'
    | 'MUR'
    | 'MVR'
    | 'MWK'
    | 'MXN'
    | 'MXV'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NGN'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PGK'
    | 'PHP'
    | 'PKR'
    | 'PLN'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SBD'
    | 'SCR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SHP'
    | 'SLL'
    | 'SOS'
    | 'SRD'
    | 'SSP'
    | 'STD'
    | 'SYP'
    | 'SZL'
    | 'THB'
    | 'TJS'
    | 'TMT'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'USD'
    | 'USN'
    | 'UYI'
    | 'UYU'
    | 'UZS'
    | 'VEF'
    | 'VND'
    | 'VUV'
    | 'WST'
    | 'XAF'
    | 'XAG'
    | 'XAU'
    | 'XBA'
    | 'XBB'
    | 'XBC'
    | 'XBD'
    | 'XCD'
    | 'XDR'
    | 'XFU'
    | 'XOF'
    | 'XPD'
    | 'XPF'
    | 'XPT'
    | 'XSU'
    | 'XTS'
    | 'XUA'
    | 'XXX'
    | 'YER'
    | 'ZAR'
    | 'ZMW';
  /** The charge amount in invoice currency */
  chargeAmountInInvoiceCurrency?: number;
  /** The deviation amount in invoice currency */
  deviationAmountInInvoiceCurrency?: number;
  /** The invoice currency */
  invoiceCurrency?:
    | 'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ANG'
    | 'AOA'
    | 'ARS'
    | 'AUD'
    | 'AWG'
    | 'AZN'
    | 'BAM'
    | 'BBD'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BMD'
    | 'BND'
    | 'BOB'
    | 'BOV'
    | 'BRL'
    | 'BSD'
    | 'BTN'
    | 'BWP'
    | 'BYN'
    | 'BYR'
    | 'BZD'
    | 'CAD'
    | 'CDF'
    | 'CHE'
    | 'CHF'
    | 'CHW'
    | 'CLF'
    | 'CLP'
    | 'CNY'
    | 'COP'
    | 'COU'
    | 'CRC'
    | 'CUC'
    | 'CUP'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'EUR'
    | 'FJD'
    | 'FKP'
    | 'GBP'
    | 'GEL'
    | 'GHS'
    | 'GIP'
    | 'GMD'
    | 'GNF'
    | 'GTQ'
    | 'GYD'
    | 'HKD'
    | 'HNL'
    | 'HRK'
    | 'HTG'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KGS'
    | 'KHR'
    | 'KMF'
    | 'KPW'
    | 'KRW'
    | 'KWD'
    | 'KYD'
    | 'KZT'
    | 'LAK'
    | 'LBP'
    | 'LKR'
    | 'LRD'
    | 'LSL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MNT'
    | 'MOP'
    | 'MRO'
    | 'MUR'
    | 'MVR'
    | 'MWK'
    | 'MXN'
    | 'MXV'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NGN'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PGK'
    | 'PHP'
    | 'PKR'
    | 'PLN'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SBD'
    | 'SCR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SHP'
    | 'SLL'
    | 'SOS'
    | 'SRD'
    | 'SSP'
    | 'STD'
    | 'SYP'
    | 'SZL'
    | 'THB'
    | 'TJS'
    | 'TMT'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'USD'
    | 'USN'
    | 'UYI'
    | 'UYU'
    | 'UZS'
    | 'VEF'
    | 'VND'
    | 'VUV'
    | 'WST'
    | 'XAF'
    | 'XAG'
    | 'XAU'
    | 'XBA'
    | 'XBB'
    | 'XBC'
    | 'XBD'
    | 'XCD'
    | 'XDR'
    | 'XFU'
    | 'XOF'
    | 'XPD'
    | 'XPF'
    | 'XPT'
    | 'XSU'
    | 'XTS'
    | 'XUA'
    | 'XXX'
    | 'YER'
    | 'ZAR'
    | 'ZMW';
  /** The charge amount in reference currency */
  chargeAmountInReferenceCurrency?: number;
  /** The reference currency */
  referenceCurrency?:
    | 'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ANG'
    | 'AOA'
    | 'ARS'
    | 'AUD'
    | 'AWG'
    | 'AZN'
    | 'BAM'
    | 'BBD'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BMD'
    | 'BND'
    | 'BOB'
    | 'BOV'
    | 'BRL'
    | 'BSD'
    | 'BTN'
    | 'BWP'
    | 'BYN'
    | 'BYR'
    | 'BZD'
    | 'CAD'
    | 'CDF'
    | 'CHE'
    | 'CHF'
    | 'CHW'
    | 'CLF'
    | 'CLP'
    | 'CNY'
    | 'COP'
    | 'COU'
    | 'CRC'
    | 'CUC'
    | 'CUP'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'EUR'
    | 'FJD'
    | 'FKP'
    | 'GBP'
    | 'GEL'
    | 'GHS'
    | 'GIP'
    | 'GMD'
    | 'GNF'
    | 'GTQ'
    | 'GYD'
    | 'HKD'
    | 'HNL'
    | 'HRK'
    | 'HTG'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KGS'
    | 'KHR'
    | 'KMF'
    | 'KPW'
    | 'KRW'
    | 'KWD'
    | 'KYD'
    | 'KZT'
    | 'LAK'
    | 'LBP'
    | 'LKR'
    | 'LRD'
    | 'LSL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MNT'
    | 'MOP'
    | 'MRO'
    | 'MUR'
    | 'MVR'
    | 'MWK'
    | 'MXN'
    | 'MXV'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NGN'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PGK'
    | 'PHP'
    | 'PKR'
    | 'PLN'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SBD'
    | 'SCR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SHP'
    | 'SLL'
    | 'SOS'
    | 'SRD'
    | 'SSP'
    | 'STD'
    | 'SYP'
    | 'SZL'
    | 'THB'
    | 'TJS'
    | 'TMT'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'USD'
    | 'USN'
    | 'UYI'
    | 'UYU'
    | 'UZS'
    | 'VEF'
    | 'VND'
    | 'VUV'
    | 'WST'
    | 'XAF'
    | 'XAG'
    | 'XAU'
    | 'XBA'
    | 'XBB'
    | 'XBC'
    | 'XBD'
    | 'XCD'
    | 'XDR'
    | 'XFU'
    | 'XOF'
    | 'XPD'
    | 'XPF'
    | 'XPT'
    | 'XSU'
    | 'XTS'
    | 'XUA'
    | 'XXX'
    | 'YER'
    | 'ZAR'
    | 'ZMW';
  /** The exchange rate from 1 charge currency to 1 invoice currency */
  exchangeRate?: number;
  /** The service provider */
  serviceProvider?: string;
  /** The shipper */
  shipper?: string;
  /** The shipper country */
  shipperCountry?: string;
  /** The consignee */
  consignee?: string;
  /** The consignee country */
  consigneeCountry?: string;
  /** The modality */
  modality?: string;
  /** The port of loading (POL) */
  pol?: string;
  /** The port of discharge (POD) */
  pod?: string;
  /** The billed entity */
  billedEntity?: string;
  /** The leg type */
  legType?: string;
}

export interface InvoiceChargeSearchResponseDto {
  page?: PageDto;
  /**
   * The total number of invoice charges
   * @format int64
   */
  total?: number;
  /** The list of invoice charge data */
  invoiceCharges?: InvoiceChargeDto[];
}

export interface ResponseDtoInvoiceChargeSearchResponseDto {
  success?: boolean;
  message?: string;
  data?: InvoiceChargeSearchResponseDto;
  /** @format int32 */
  statusCode?: number;
}

/** The list of aggregated data */
export interface AggregatedDto {
  /** The unit of aggregation */
  aggregatedUnit?: string;
  /** The total amount */
  totalAmount?: number;
  /** The total CO2 emissions */
  totalCo2Emissions?: number;
  /** The chargeable weight */
  chargeableWeight?: number;
  /** The gross weight */
  grossWeight?: number;
  /** The volume */
  volume?: number;
  /**
   * The number of shipments
   * @format int64
   */
  noShipments?: number;
  /**
   * The number of charges
   * @format int64
   */
  noCharges?: number;
  /**
   * The number of invoices
   * @format int64
   */
  noInvoices?: number;
}

export interface ChartResponseDto {
  /** The list of aggregated data */
  aggregated?: AggregatedDto[];
  /** The date range for the chart data */
  dateRange?: RangeDto;
  /** The metadata for the chart data */
  meta?: MetaDto;
}

/** The metadata for the chart data */
export interface MetaDto {
  /** The currency */
  currency?:
    | 'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ANG'
    | 'AOA'
    | 'ARS'
    | 'AUD'
    | 'AWG'
    | 'AZN'
    | 'BAM'
    | 'BBD'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BMD'
    | 'BND'
    | 'BOB'
    | 'BOV'
    | 'BRL'
    | 'BSD'
    | 'BTN'
    | 'BWP'
    | 'BYN'
    | 'BYR'
    | 'BZD'
    | 'CAD'
    | 'CDF'
    | 'CHE'
    | 'CHF'
    | 'CHW'
    | 'CLF'
    | 'CLP'
    | 'CNY'
    | 'COP'
    | 'COU'
    | 'CRC'
    | 'CUC'
    | 'CUP'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'EUR'
    | 'FJD'
    | 'FKP'
    | 'GBP'
    | 'GEL'
    | 'GHS'
    | 'GIP'
    | 'GMD'
    | 'GNF'
    | 'GTQ'
    | 'GYD'
    | 'HKD'
    | 'HNL'
    | 'HRK'
    | 'HTG'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KGS'
    | 'KHR'
    | 'KMF'
    | 'KPW'
    | 'KRW'
    | 'KWD'
    | 'KYD'
    | 'KZT'
    | 'LAK'
    | 'LBP'
    | 'LKR'
    | 'LRD'
    | 'LSL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MNT'
    | 'MOP'
    | 'MRO'
    | 'MUR'
    | 'MVR'
    | 'MWK'
    | 'MXN'
    | 'MXV'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NGN'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PGK'
    | 'PHP'
    | 'PKR'
    | 'PLN'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SBD'
    | 'SCR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SHP'
    | 'SLL'
    | 'SOS'
    | 'SRD'
    | 'SSP'
    | 'STD'
    | 'SYP'
    | 'SZL'
    | 'THB'
    | 'TJS'
    | 'TMT'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'USD'
    | 'USN'
    | 'UYI'
    | 'UYU'
    | 'UZS'
    | 'VEF'
    | 'VND'
    | 'VUV'
    | 'WST'
    | 'XAF'
    | 'XAG'
    | 'XAU'
    | 'XBA'
    | 'XBB'
    | 'XBC'
    | 'XBD'
    | 'XCD'
    | 'XDR'
    | 'XFU'
    | 'XOF'
    | 'XPD'
    | 'XPF'
    | 'XPT'
    | 'XSU'
    | 'XTS'
    | 'XUA'
    | 'XXX'
    | 'YER'
    | 'ZAR'
    | 'ZMW';
  /** The unit of gross weight */
  grossWeightUnit?: string;
  /** The unit of chargeable weight */
  chargeableWeightUnit?: string;
  /** The unit of CO2 emission */
  co2EmissionUnit?: string;
  /** The unit of volume */
  volumeUnit?: string;
}

/** The date range for the chart data */
export interface RangeDto {
  from?: string;
  to?: string;
}

export interface ResponseDtoChartResponseDto {
  success?: boolean;
  message?: string;
  data?: ChartResponseDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ExchangeRateAmountReCalculationRequest {
  /** @format date */
  startDate?: string;
  /** @format date */
  endDate?: string;
  invoiceIds?: string[];
}

export interface ResponseDtoBackgroundShipmentPerformResultDto {
  success?: boolean;
  message?: string;
  data?: BackgroundShipmentPerformResultDto;
  /** @format int32 */
  statusCode?: number;
}

export interface UpdatingS3FileMetadataReq {
  /** @format uuid */
  pipelineId: string;
  dataPipelineConfigId?: string;
  /** @format int32 */
  extractedPage?: number;
}

export interface UpdatingResolutionStatusRequest {
  status: 'REVIEW_REQUIRED' | 'UNDER_REVIEW' | 'NOT_AN_INVOICE' | 'MISSING_REQUIRED_FIELD' | 'INCONSISTENT_SHIPMENT_DATA' | 'FIXED';
  message?: string;
  resolutionType: 'ISUSR' | 'ISSYS';
}

export interface ResponseDtoGettingFileResolutionDto {
  success?: boolean;
  message?: string;
  data?: GettingFileResolutionDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoListUserDto {
  success?: boolean;
  message?: string;
  data?: UserDto[];
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoListShippingServiceDto {
  success?: boolean;
  message?: string;
  data?: ShippingServiceDto[];
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoListRawShipmentDto {
  success?: boolean;
  message?: string;
  data?: RawShipmentDto[];
  /** @format int32 */
  statusCode?: number;
}

export interface NoContractResponseDto {
  matchedService?: MatchingServiceDto;
  unmatchedService?: MatchingServiceDto;
  partialMatchingServices?: PartialMatchingServiceDto[];
}

export interface ResponseDtoNoContractResponseDto {
  success?: boolean;
  message?: string;
  data?: NoContractResponseDto;
  /** @format int32 */
  statusCode?: number;
}

export interface DeviationQuestionAnswerOptionDto {
  /** @format uuid */
  id?: string;
  name?: string;
  /** @format int32 */
  displayOrder?: number;
  type?: 'YES' | 'NO' | 'RECURRING' | 'ONE_OFF' | 'YES_BUT_WITH_CHANGE_RECURRENCE' | 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE' | 'NOT_REQUIRED' | 'OTHER';
  nextQuestions?: DeviationQuestionDto[];
}

export interface DeviationQuestionDto {
  /** @format uuid */
  id?: string;
  name?: string;
  deviationType?: 'UNIT_PRICE' | 'QUANTITY' | 'NEW_CHARGE' | 'DOUBLE_CHARGE' | 'APPROVAL_CHECK';
  questionType?: 'TEXT' | 'SELECT' | 'RADIO' | 'DATE' | 'DATE_RANGE' | 'DATE_TIME' | 'DATE_TIME_RANGE' | 'MULTIPLE_SELECT';
  questionDomainType?:
    | 'WAS_SHIPMENT_SPECIAL_INSTRUCTION_GIVEN'
    | 'WHAT_WAS_THE_SHIPMENT_SPECIAL_INSTRUCTION'
    | 'IS_THIS_RECURRING_CHARGE'
    | 'IS_THIS_DUE_TO_MARKET_CONDITION'
    | 'WHAT_IS_THE_NEW_MARKET_CONDITION'
    | 'PERIOD_OF_EXPECTED_RECURRENCE'
    | 'DO_YOU_ACCEPT_THIS_CHARGE_AS_IS'
    | 'CHARGE_REJECTED_REASON_TEXT'
    | 'DO_YOU_ACCEPT_THIS_QUANTITY'
    | 'WHY_DO_YOU_ACCEPT_THIS_QUANTITY'
    | 'WHY_DO_YOU_REJECT_THIS_QUANTITY'
    | 'IS_THERE_REASON_FOR_DOUBLE_CHARGE'
    | 'WHAT_IS_THE_REASON_FOR_DOUBLE_CHARGE'
    | 'DO_YOU_ACCEPT_THIS_DOUBLE_CHARGE'
    | 'WHY_DO_YOU_REJECT_THIS_DOUBLE_CHARGE'
    | 'DO_YOU_ACCEPT_THIS_DEVIATION_FROM_OPERATION'
    | 'WHY_DO_YOU_ACCEPT_THIS_DEVIATION'
    | 'WHY_DO_YOU_ACCEPT_THIS_DEVIATION_WITH_RECURRENCE_CHANGE'
    | 'WHY_DO_YOU_REJECT_THIS_CHARGE'
    | 'MODIFY_RECURRENCE'
    | 'WHY_DO_YOU_REJECT_THIS_DEVIATION'
    | 'CHANGE_LEG_OF_INVOICE_CHARGE'
    | 'DO_YOU_HAVE_ALL_REQUIRED_APPROVALS_FOR_THE_ACCESSORIAL_CHARGES'
    | 'DO_YOU_HAVE_ALL_THE_REQUIRED_APPROVALS_FOR_THIS_SERVICE'
    | 'DO_YOU_ACCEPT_THIS_NEW_CHARGE_AS_IT'
    | 'WHY_DO_YOU_ACCEPT_THIS_NEW_CHARGE_AS_ONE_OFF'
    | 'WHY_DO_YOU_REJECT_THIS_CHARGE_AS_IT'
    | 'WHY_DO_YOU_ACCEPT_THIS_NEW_CHARGE_AS_RECURRING'
    | 'WHAT_ARE_YOU_MISSING_FOR_THE_APPROVAL_OF_THE_ACCESSORIAL_CHARGES'
    | 'WHAT_ARE_YOU_MISSING_FOR_THE_APPROVAL_OF_THIS_SERVICE'
    | 'WHY_IS_NOT_REQUIRED'
    | 'SELECT_A_CHARGE_NAME';
  approvalType?: 'ACCESSORIAL_CHARGE' | 'SERVICE_TYPE';
  answerOptions?: DeviationQuestionAnswerOptionDto[];
}

export interface ResponseDtoMapDeviationWorkflowListDeviationQuestionDto {
  success?: boolean;
  message?: string;
  data?: Record<string, DeviationQuestionDto[]>;
  /** @format int32 */
  statusCode?: number;
}

/** List of invoice statuses associated with the service provider */
export interface InvoiceStatusCountDto {
  /** Status of the invoice */
  status?: 'FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT';
  /**
   * Count of invoices with the given status
   * @format int64
   */
  count?: number;
}

export interface ResponseDtoListServiceProviderDetailsDto {
  success?: boolean;
  message?: string;
  data?: ServiceProviderDetailsDto[];
  /** @format int32 */
  statusCode?: number;
}

export interface ServiceProviderDetailsDto {
  serviceProviderName?: string;
  serviceProviderCode?: string;
  modality?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  /**
   * Unique identifier of the service provider
   * @format int64
   */
  id: number;
  /** List of invoice statuses associated with the service provider */
  invoiceStatuses: InvoiceStatusCountDto[];
  /**
   * Date when the latest invoice was created
   * @format date-time
   */
  latestInvoiceCreatedDate?: string;
}

export interface ResponseDtoListServiceProviderOverViewDto {
  success?: boolean;
  message?: string;
  data?: ServiceProviderOverViewDto[];
  /** @format int32 */
  statusCode?: number;
}

export interface ServiceProviderOverViewDto {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  parentServiceProviderId?: number;
  name?: string;
  code?: string;
  active?: boolean;
  email?: string;
}

export interface ResponseDtoListRouteDto {
  success?: boolean;
  message?: string;
  data?: RouteDto[];
  /** @format int32 */
  statusCode?: number;
}

export interface RouteDto {
  route?: string;
  zones?: ZoneDto[];
}

export interface ResponseDtoListContractChargeDto {
  success?: boolean;
  message?: string;
  data?: (ContainerChargeDto | DeclarationChargeDto | PercentageChargeDto | ShipmentChargeDto | WeightChargeDto)[];
  /** @format int32 */
  statusCode?: number;
}

export interface NotificationDto {
  /** @uniqueItems true */
  serviceProviderCodes?: string[];
  /** @uniqueItems true */
  modalities?: ('ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT')[];
  /** @format int64 */
  flaggedInvoices?: number;
  /** @format int64 */
  approvedInvoices?: number;
  /** @format int64 */
  rejectedInvoices?: number;
  /** @format int64 */
  errorInvoices?: number;
  /** @format int64 */
  processingInvoices?: number;
  /** @format int64 */
  totalFiles?: number;
  isRead?: boolean;
  /** @format uuid */
  batchId?: string;
  /** @format uuid */
  notificationId?: string;
  notificationStatus?: 'EMAIL_RECEIVED' | 'BATCH_PROCESSED_WITH_WARNINGS' | 'BATCH_PROCESSED';
  notificationSourceType?: 'EMAIL' | 'GENERAL_CONFIG' | 'SPECIFIC_CONFIG';
  uploadedBy?: string;
  /** @format date-time */
  uploadedOn?: string;
}

export interface NotificationResponse {
  isRead?: boolean;
  notifications?: NotificationDto[];
}

export interface ResponseDtoNotificationResponse {
  success?: boolean;
  message?: string;
  data?: NotificationResponse;
  /** @format int32 */
  statusCode?: number;
}

export interface AdminDashboardMetricDto {
  /** @format int64 */
  newIssues?: number;
  /** @format int64 */
  issuesUnderReview?: number;
  /** @format int64 */
  fileIngestionFailures?: number;
  /** @format int64 */
  totalFilesUploaded?: number;
  /** @format int64 */
  extractedPages?: number;
  /** @format int64 */
  creditAmount?: number;
  /** @format date */
  creditExpirationDate?: string;
  /** @format int64 */
  shipmentCreditAmount?: number;
  /** @format date */
  shipmentCreditExpirationDate?: string;
}

export interface ResponseDtoAdminDashboardMetricDto {
  success?: boolean;
  message?: string;
  data?: AdminDashboardMetricDto;
  /** @format int32 */
  statusCode?: number;
}

export interface LoggedInUserDto {
  /** @format uuid */
  id?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email?: string;
  displayRole?: string;
  admin?: boolean;
  systemAdmin?: boolean;
}

export interface ResponseDtoLoggedInUserDto {
  success?: boolean;
  message?: string;
  data?: LoggedInUserDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoSetPermission {
  success?: boolean;
  message?: string;
  /** @uniqueItems true */
  data?: (
    | 'CAN_GIVE_PROCUREMENT_REVIEW'
    | 'CAN_GIVE_OPERATION_REVIEW'
    | 'CAN_GIVE_FINANCE_REVIEW'
    | 'CAN_GIVE_DISPUTE_REVIEW'
    | 'CAN_GIVE_NO_CONTRACT_REVIEW'
    | 'CAN_UPLOAD_INVOICE_FILE'
  )[];
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoSetPermissionType {
  success?: boolean;
  message?: string;
  /** @uniqueItems true */
  data?: ('OPERATION' | 'PROCUREMENT' | 'DISPUTE' | 'FINANCE' | 'AUDIT' | 'REPORT' | 'PERFORMANCE')[];
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoLegalEntityDto {
  success?: boolean;
  message?: string;
  data?: LegalEntityDto;
  /** @format int32 */
  statusCode?: number;
}

export interface PageLegalEntityDto {
  /** @format int32 */
  totalPages?: number;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  size?: number;
  content?: LegalEntityDto[];
  /** @format int32 */
  number?: number;
  sort?: SortObject[];
  pageable?: PageableObject;
  /** @format int32 */
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface ResponseDtoPageLegalEntityDto {
  success?: boolean;
  message?: string;
  data?: PageLegalEntityDto;
  /** @format int32 */
  statusCode?: number;
}

export interface LegalEntity {
  /** @format int64 */
  id?: number;
  name?: string;
  code?: string;
  active?: boolean;
  nationalCurrency?: string;
  /** @format int64 */
  parentId?: number;
  nameAliases?: LegalEntityNameAlias[];
  isBilledEntity?: boolean;
  subEntities?: LegalEntity[];
  parentEntity?: LegalEntity;
}

export interface LegalEntityNameAlias {
  /** @format int64 */
  id?: number;
  name?: string;
  legalEntity?: LegalEntity;
}

export interface ResponseDtoShipment {
  success?: boolean;
  message?: string;
  data?: Shipment;
  /** @format int32 */
  statusCode?: number;
}

export interface BaseInfo {
  /** @format int32 */
  quantity?: number;
  /** @uniqueItems true */
  shipmentImpacted?: string[];
  totalAmount?: Amount;
}

export interface InvoiceIssueSummaryDto {
  accessorialMissingApprovals?: BaseInfo;
  serviceTypeMissingApprovals?: BaseInfo;
  deviations?: BaseInfo;
  rejectedNRCFShipments?: BaseInfo;
}

export interface ResponseDtoInvoiceIssueSummaryDto {
  success?: boolean;
  message?: string;
  data?: InvoiceIssueSummaryDto;
  /** @format int32 */
  statusCode?: number;
}

export interface DeviationActivity {
  deviationType?: 'UNIT_PRICE' | 'QUANTITY' | 'NEW_CHARGE' | 'DOUBLE_CHARGE' | 'APPROVAL_CHECK';
  chargeName?: string;
  unitPrice?: number;
  chargesCurrency?: string;
  mappedChargeDomain?: string;
  mappedChargeSubDomain?: string;
  mappedLegType?: string;
  /** @format date */
  startDate?: string;
  /** @format date */
  endDate?: string;
}

export interface EmailDeliveryHistoryDto {
  /** @format uuid */
  id?: string;
  type?: 'INVOICE_FORWARD' | 'INVOICE_REJECTION';
  subject?: string;
  /** @format date-time */
  deliveryTime?: string;
  attachmentFile?: string;
  receiver?: string;
  /** @format date-time */
  createdOn?: string;
  createdBy?: string;
  /** @format date-time */
  updatedOn?: string;
}

export interface EntityActivityLogDto {
  activityType?:
    | 'INVOICE_UPLOAD'
    | 'STATUS_UPDATE'
    | 'DISPUTE_UPDATE'
    | 'CREDIT_NOTE_UPDATE'
    | 'DOCUMENT_UPDATE'
    | 'DEVIATION_UPDATE'
    | 'NO_CONTRACT_FOUND_UPDATE'
    | 'RATE_CARD_MAP_UPDATE';
  objectStatus?:
    | 'OBJECT_UPLOADED'
    | 'OBJECT_FLAGGED'
    | 'OBJECT_APPROVED'
    | 'OBJECT_TO_APPROVE'
    | 'OBJECT_TO_REJECT'
    | 'OBJECT_PENDING_DOCUMENT'
    | 'OBJECT_REJECTED'
    | 'OBJECT_NO_CONTRACT_FOUND'
    | 'OBJECT_DOCUMENT_UPDATED'
    | 'OBJECT_DEVIATION_UPDATED'
    | 'OBJECT_RATE_CARD_MAPPED';
  actionOnObject?:
    | 'UPLOADED_ON_OPERATION'
    | 'SENT_TO_OPERATION'
    | 'SENT_TO_PROCUREMENT'
    | 'SENT_TO_FINANCE'
    | 'DISPUTE_STARTED'
    | 'DISPUTE_RESOLVED'
    | 'CREDIT_NOTE_UPLOADED'
    | 'CREDIT_NOTE_DELETED'
    | 'DOCUMENT_UPLOADED'
    | 'DOCUMENT_DELETED'
    | 'MARKED_PENDING'
    | 'MARKED_TO_REJECT'
    | 'MARKED_TO_APPROVE'
    | 'MARKED_READY_FOR_PAYMENT'
    | 'REJECTED'
    | 'APPROVED'
    | 'PENDING_REVIEW'
    | 'NEW_CHARGE_ACCEPTED_ONE_OFF'
    | 'NEW_CHARGE_ACCEPTED_RECURRING'
    | 'NEW_CHARGE_REJECTED'
    | 'NEW_CHARGE_MAPPED_OTHER'
    | 'MAPPED_TO_RATE_CARD'
    | 'PERCENTAGE_CHARGE_RECALCULATED'
    | 'RECURRING_CHARGE_RECALCULATED';
  disputeAgreementType?:
    | 'ACCEPT_INVOICE_AS_IS'
    | 'AMEND_INVOICE_CREDIT_NOTE'
    | 'REJECT_INVOICE'
    | 'COMMERCIAL_SETTLEMENT_CREDIT_NOTE'
    | 'ACCEPT_INVOICE_WITH_CREDIT_NOTE';
  deviationActivity?: DeviationActivity;
  actionBy?: string;
  /** @format date-time */
  createdOn?: string;
  finalResolution?:
    | 'NO_CONTRACT_REJECT'
    | 'REJECT_AFTER_DISPUTE'
    | 'UNKNOWN_BILLING_PARTY'
    | 'WRONG_BILLING_PARTY'
    | 'DUPLICATE_INVOICE'
    | 'NO_RATE_CARD_FOUND';
  emailDeliveryHistory?: EmailDeliveryHistoryDto;
  documentName?: string;
  invoiceStatusOverview?: InvoiceStatusOverview;
  shipmentOverview?: ShipmentOverview;
  shipmentLogs?: ShipmentLog[];
}

export interface InvoiceStatusOverview {
  /** @format int32 */
  procurementReview?: number;
  /** @format int32 */
  operationReview?: number;
  /** @format int32 */
  rejected?: number;
  /** @format int32 */
  approved?: number;
}

export interface ResponseDtoListEntityActivityLogDto {
  success?: boolean;
  message?: string;
  data?: EntityActivityLogDto[];
  /** @format int32 */
  statusCode?: number;
}

export interface ShipmentLog {
  /** @format uuid */
  shipmentInvoiceId?: string;
  shipmentNumber?: string;
  status?: 'FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT';
  currentDeviationWorkflow?:
    | 'OPERATION_REVIEW'
    | 'PROCUREMENT_REVIEW'
    | 'PENDING_REVIEW'
    | 'DISPUTE_REVIEW'
    | 'PROCUREMENT_NO_CONTRACT_REVIEW'
    | 'FINANCE_REVIEW';
  finalResolution?:
    | 'NO_CONTRACT_REJECT'
    | 'REJECT_AFTER_DISPUTE'
    | 'UNKNOWN_BILLING_PARTY'
    | 'WRONG_BILLING_PARTY'
    | 'DUPLICATE_INVOICE'
    | 'NO_RATE_CARD_FOUND';
  /** @format int32 */
  numberOfApprovals?: number;
  /** List of nested activity logs */
  activityLogs?: EntityActivityLogDto[];
}

export interface ShipmentOverview {
  /** @format int64 */
  unitPrice?: number;
  /** @format int64 */
  quantity?: number;
  /** @format int64 */
  newCharge?: number;
  /** @format int64 */
  doubleCharge?: number;
  /** @format int64 */
  approvalCheck?: number;
  /** @format int64 */
  approval?: number;
}

export interface ResponseDtoMapDeviationWorkflowListDeviationQuestionUserResponseDto {
  success?: boolean;
  message?: string;
  data?: Record<string, DeviationQuestionUserResponseDto[]>;
  /** @format int32 */
  statusCode?: number;
}

export interface EmailRecord {
  /** @format uuid */
  id?: string;
  messageId?: string;
  sender?: string;
  cc?: string;
  bcc?: string;
  recipientTo?: string;
  subject?: string;
  /** @format date-time */
  receiptTime?: string;
  attachmentFile?: string;
  content?: string;
  receiverLegalEntity?: LegalEntity;
  /** @format date-time */
  createdOn?: string;
  createdBy?: string;
  /** @format date-time */
  updatedOn?: string;
}

export interface Invoice {
  /** @format uuid */
  id?: string;
  /** @format int64 */
  billedPartyId?: number;
  invoiceNumber?: string;
  /** @format date */
  invoiceDate?: string;
  /** @format date */
  dueDate?: string;
  /** @format date */
  paymentDate?: string;
  terms?: string;
  status?: 'FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT';
  currentDeviationWorkflow?:
    | 'OPERATION_REVIEW'
    | 'PROCUREMENT_REVIEW'
    | 'PENDING_REVIEW'
    | 'DISPUTE_REVIEW'
    | 'PROCUREMENT_NO_CONTRACT_REVIEW'
    | 'FINANCE_REVIEW';
  meta?: InvoiceMeta;
  finalResolution?:
    | 'NO_CONTRACT_REJECT'
    | 'REJECT_AFTER_DISPUTE'
    | 'UNKNOWN_BILLING_PARTY'
    | 'WRONG_BILLING_PARTY'
    | 'DUPLICATE_INVOICE'
    | 'NO_RATE_CARD_FOUND';
  jobNumber?: string;
  preparedBy?: string;
  invoiceFileLink?: string;
  customerCode?: string;
  customerReference?: string;
  poNumber?: string;
  businessUnit?: string;
  salesPerson?: string;
  companyRegistrationNumber?: string;
  gstRegistrationNumber?: string;
  lotNumber?: string;
  incoterm?: 'EXW' | 'FCA' | 'FAS' | 'FOB' | 'CPT' | 'CIP' | 'CFR' | 'CIF' | 'DAP' | 'DPU' | 'DDP';
  commodity?: string;
  invoiceCurrency?: string;
  totalAmountBeforeTax?: number;
  totalAmountAfterTax?: number;
  totalAmountAfterTaxInWords?: string;
  totalTaxAmount?: number;
  modality?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  notes?: string;
  remarks?: string;
  bankDetails?: InvoiceBankDetail[];
  /** @format uuid */
  invoiceFileProcessingId?: string;
  /** @format int64 */
  serviceProviderId?: number;
  fapRef?: string;
  accountNumber?: string;
  /** @format date-time */
  createdOn?: string;
  createdBy?: string;
  /** @format date-time */
  updatedOn?: string;
  updatedBy?: string;
  transientMailTrackerContext?: MailDeliveryTrackerContext;
}

export interface InvoiceBankDetail {
  bankName?: string;
  branch?: string;
  swiftCode?: string;
  accountName?: string;
  accountNumber?: string;
}

export interface MailDeliveryTrackerContext {
  detailedReason?: string;
  emailRecord?: EmailRecord;
}

export interface ResponseDtoListInvoice {
  success?: boolean;
  message?: string;
  data?: Invoice[];
  /** @format int32 */
  statusCode?: number;
}

export interface InvoiceOcrMessageDto {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  invoiceFileProcessingId?: string;
  message?: InvoiceMessageDto;
}

export interface ResponseDtoInvoiceOcrMessageDto {
  success?: boolean;
  message?: string;
  data?: InvoiceOcrMessageDto;
  /** @format int32 */
  statusCode?: number;
}

export interface InvoiceFileProcessingStatusDto {
  /** @format uuid */
  invoiceId?: string;
  status?:
    | 'BEING_UPLOADED'
    | 'COMPLETE'
    | 'OCR_PROCESSING'
    | 'OCR_COMPLETE'
    | 'OCR_ERROR'
    | 'TRANSFORM_ERROR'
    | 'TRANSFORM_COMPLETE'
    | 'MESSAGE_PROCESSING_FAILED'
    | 'UPLOAD_FROM_EMAIL_FAILED'
    | 'RESOLVED_AS_NOT_AN_INVOICE'
    | 'RESOLVED_AS_MISSING_REQUIRED_FIELD'
    | 'RESOLVED_AS_INCONSISTENT_SHIPMENT_DATA'
    | 'MARKED_MISSING_REQUIRED_FIELD_FOR_REVIEW'
    | 'MARKED_INCONSISTENT_SHIPMENT_DATA_FOR_REVIEW'
    | 'USER_MARK_AS_ERROR';
  invoiceStatus?: 'FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT';
  /** @format uuid */
  invoiceFileProcessingId?: string;
  /** @format date-time */
  updatedOn?: string;
  isNoRateCardFound?: boolean;
  fileName?: string;
  invoiceNumber?: string;
  finalResolution?:
    | 'NO_CONTRACT_REJECT'
    | 'REJECT_AFTER_DISPUTE'
    | 'UNKNOWN_BILLING_PARTY'
    | 'WRONG_BILLING_PARTY'
    | 'DUPLICATE_INVOICE'
    | 'NO_RATE_CARD_FOUND';
  modality?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  serviceProviderCode?: string;
  fileResolutionStatus?: 'REVIEW_REQUIRED' | 'UNDER_REVIEW' | 'NOT_AN_INVOICE' | 'MISSING_REQUIRED_FIELD' | 'INCONSISTENT_SHIPMENT_DATA' | 'FIXED';
  /** @format date-time */
  createdOn?: string;
  uploadedBy?: string;
}

export interface ResponseDtoListInvoiceFileProcessingStatusDto {
  success?: boolean;
  message?: string;
  data?: InvoiceFileProcessingStatusDto[];
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoMapStringString {
  success?: boolean;
  message?: string;
  data?: Record<string, string>;
  /** @format int32 */
  statusCode?: number;
}

export interface InvoiceDataPipelineConfigMetadataDto {
  modalities?: ('ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT')[];
  serviceProviders?: ServiceProviderOverViewDto[];
  modelIds?: string[];
}

export interface ResponseDtoInvoiceDataPipelineConfigMetadataDto {
  success?: boolean;
  message?: string;
  data?: InvoiceDataPipelineConfigMetadataDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoListExchangeRateDto {
  success?: boolean;
  message?: string;
  data?: ExchangeRateDto[];
  /** @format int32 */
  statusCode?: number;
}

export interface DomainMetaDataDto {
  defaultCurrency?:
    | 'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ANG'
    | 'AOA'
    | 'ARS'
    | 'AUD'
    | 'AWG'
    | 'AZN'
    | 'BAM'
    | 'BBD'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BMD'
    | 'BND'
    | 'BOB'
    | 'BOV'
    | 'BRL'
    | 'BSD'
    | 'BTN'
    | 'BWP'
    | 'BYN'
    | 'BYR'
    | 'BZD'
    | 'CAD'
    | 'CDF'
    | 'CHE'
    | 'CHF'
    | 'CHW'
    | 'CLF'
    | 'CLP'
    | 'CNY'
    | 'COP'
    | 'COU'
    | 'CRC'
    | 'CUC'
    | 'CUP'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'EUR'
    | 'FJD'
    | 'FKP'
    | 'GBP'
    | 'GEL'
    | 'GHS'
    | 'GIP'
    | 'GMD'
    | 'GNF'
    | 'GTQ'
    | 'GYD'
    | 'HKD'
    | 'HNL'
    | 'HRK'
    | 'HTG'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KGS'
    | 'KHR'
    | 'KMF'
    | 'KPW'
    | 'KRW'
    | 'KWD'
    | 'KYD'
    | 'KZT'
    | 'LAK'
    | 'LBP'
    | 'LKR'
    | 'LRD'
    | 'LSL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MNT'
    | 'MOP'
    | 'MRO'
    | 'MUR'
    | 'MVR'
    | 'MWK'
    | 'MXN'
    | 'MXV'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NGN'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PGK'
    | 'PHP'
    | 'PKR'
    | 'PLN'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SBD'
    | 'SCR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SHP'
    | 'SLL'
    | 'SOS'
    | 'SRD'
    | 'SSP'
    | 'STD'
    | 'SYP'
    | 'SZL'
    | 'THB'
    | 'TJS'
    | 'TMT'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'USD'
    | 'USN'
    | 'UYI'
    | 'UYU'
    | 'UZS'
    | 'VEF'
    | 'VND'
    | 'VUV'
    | 'WST'
    | 'XAF'
    | 'XAG'
    | 'XAU'
    | 'XBA'
    | 'XBB'
    | 'XBC'
    | 'XBD'
    | 'XCD'
    | 'XDR'
    | 'XFU'
    | 'XOF'
    | 'XPD'
    | 'XPF'
    | 'XPT'
    | 'XSU'
    | 'XTS'
    | 'XUA'
    | 'XXX'
    | 'YER'
    | 'ZAR'
    | 'ZMW';
  currencies?: (
    | 'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ANG'
    | 'AOA'
    | 'ARS'
    | 'AUD'
    | 'AWG'
    | 'AZN'
    | 'BAM'
    | 'BBD'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BMD'
    | 'BND'
    | 'BOB'
    | 'BOV'
    | 'BRL'
    | 'BSD'
    | 'BTN'
    | 'BWP'
    | 'BYN'
    | 'BYR'
    | 'BZD'
    | 'CAD'
    | 'CDF'
    | 'CHE'
    | 'CHF'
    | 'CHW'
    | 'CLF'
    | 'CLP'
    | 'CNY'
    | 'COP'
    | 'COU'
    | 'CRC'
    | 'CUC'
    | 'CUP'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'EUR'
    | 'FJD'
    | 'FKP'
    | 'GBP'
    | 'GEL'
    | 'GHS'
    | 'GIP'
    | 'GMD'
    | 'GNF'
    | 'GTQ'
    | 'GYD'
    | 'HKD'
    | 'HNL'
    | 'HRK'
    | 'HTG'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KGS'
    | 'KHR'
    | 'KMF'
    | 'KPW'
    | 'KRW'
    | 'KWD'
    | 'KYD'
    | 'KZT'
    | 'LAK'
    | 'LBP'
    | 'LKR'
    | 'LRD'
    | 'LSL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MNT'
    | 'MOP'
    | 'MRO'
    | 'MUR'
    | 'MVR'
    | 'MWK'
    | 'MXN'
    | 'MXV'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NGN'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PGK'
    | 'PHP'
    | 'PKR'
    | 'PLN'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SBD'
    | 'SCR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SHP'
    | 'SLL'
    | 'SOS'
    | 'SRD'
    | 'SSP'
    | 'STD'
    | 'SYP'
    | 'SZL'
    | 'THB'
    | 'TJS'
    | 'TMT'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'USD'
    | 'USN'
    | 'UYI'
    | 'UYU'
    | 'UZS'
    | 'VEF'
    | 'VND'
    | 'VUV'
    | 'WST'
    | 'XAF'
    | 'XAG'
    | 'XAU'
    | 'XBA'
    | 'XBB'
    | 'XBC'
    | 'XBD'
    | 'XCD'
    | 'XDR'
    | 'XFU'
    | 'XOF'
    | 'XPD'
    | 'XPF'
    | 'XPT'
    | 'XSU'
    | 'XTS'
    | 'XUA'
    | 'XXX'
    | 'YER'
    | 'ZAR'
    | 'ZMW'
  )[];
  /** @uniqueItems true */
  resolvedInvoiceStatuses?: ('FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT')[];
  /** @uniqueItems true */
  unResolvedInvoiceStatuses?: ('FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT')[];
  /** @uniqueItems true */
  resolvedResolutionStatuses?: ('REVIEW_REQUIRED' | 'UNDER_REVIEW' | 'NOT_AN_INVOICE' | 'MISSING_REQUIRED_FIELD' | 'INCONSISTENT_SHIPMENT_DATA' | 'FIXED')[];
  /** @uniqueItems true */
  unResolvedResolutionStatuses?: ('REVIEW_REQUIRED' | 'UNDER_REVIEW' | 'NOT_AN_INVOICE' | 'MISSING_REQUIRED_FIELD' | 'INCONSISTENT_SHIPMENT_DATA' | 'FIXED')[];
  /** @uniqueItems true */
  errorProcessingStatuses?: (
    | 'BEING_UPLOADED'
    | 'COMPLETE'
    | 'OCR_PROCESSING'
    | 'OCR_COMPLETE'
    | 'OCR_ERROR'
    | 'TRANSFORM_ERROR'
    | 'TRANSFORM_COMPLETE'
    | 'MESSAGE_PROCESSING_FAILED'
    | 'UPLOAD_FROM_EMAIL_FAILED'
    | 'RESOLVED_AS_NOT_AN_INVOICE'
    | 'RESOLVED_AS_MISSING_REQUIRED_FIELD'
    | 'RESOLVED_AS_INCONSISTENT_SHIPMENT_DATA'
    | 'MARKED_MISSING_REQUIRED_FIELD_FOR_REVIEW'
    | 'MARKED_INCONSISTENT_SHIPMENT_DATA_FOR_REVIEW'
    | 'USER_MARK_AS_ERROR'
  )[];
}

export interface ResponseDtoDomainMetaDataDto {
  success?: boolean;
  message?: string;
  data?: DomainMetaDataDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoListChargeDomain {
  success?: boolean;
  message?: string;
  data?: (
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE'
  )[];
  /** @format int32 */
  statusCode?: number;
}

export interface ContractChargeDetailDto {
  legType?: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  chargeCategory?:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
  chargeName?: string;
  chargeUnit?: 'KG' | 'CBM' | 'PKG';
  maximumChargeableWeight?: number;
  minimumChargeableWeight?: number;
  unitPrice?: number;
  currency?: string;
  containerType?: 'STANDARD' | 'HIGH_CUBE' | 'OPEN_TOP' | 'FLAT_RACK';
  unitPrice20?: number;
  unitPrice40?: number;
  chargeType?: 'SHIPMENT' | 'DECLARATION' | 'CONTAINER' | 'WEIGHT' | 'PERCENTAGE';
  minimumPrice?: number;
  percentageValue?: number;
  /** @format date */
  startDate?: string;
  /** @format date */
  endDate?: string;
}

export interface ResponseDtoSetContractChargeDetailDto {
  success?: boolean;
  message?: string;
  /** @uniqueItems true */
  data?: ContractChargeDetailDto[];
  /** @format int32 */
  statusCode?: number;
}

export interface GettingInvoiceMissingRateCardResponse {
  /** @format int32 */
  total?: number;
  /** @uniqueItems true */
  invoices?: string[];
}

export interface ResponseDtoGettingInvoiceMissingRateCardResponse {
  success?: boolean;
  message?: string;
  data?: GettingInvoiceMissingRateCardResponse;
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoListCurrencyEnum {
  success?: boolean;
  message?: string;
  data?: (
    | 'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ANG'
    | 'AOA'
    | 'ARS'
    | 'AUD'
    | 'AWG'
    | 'AZN'
    | 'BAM'
    | 'BBD'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BMD'
    | 'BND'
    | 'BOB'
    | 'BOV'
    | 'BRL'
    | 'BSD'
    | 'BTN'
    | 'BWP'
    | 'BYN'
    | 'BYR'
    | 'BZD'
    | 'CAD'
    | 'CDF'
    | 'CHE'
    | 'CHF'
    | 'CHW'
    | 'CLF'
    | 'CLP'
    | 'CNY'
    | 'COP'
    | 'COU'
    | 'CRC'
    | 'CUC'
    | 'CUP'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'EUR'
    | 'FJD'
    | 'FKP'
    | 'GBP'
    | 'GEL'
    | 'GHS'
    | 'GIP'
    | 'GMD'
    | 'GNF'
    | 'GTQ'
    | 'GYD'
    | 'HKD'
    | 'HNL'
    | 'HRK'
    | 'HTG'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KGS'
    | 'KHR'
    | 'KMF'
    | 'KPW'
    | 'KRW'
    | 'KWD'
    | 'KYD'
    | 'KZT'
    | 'LAK'
    | 'LBP'
    | 'LKR'
    | 'LRD'
    | 'LSL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MNT'
    | 'MOP'
    | 'MRO'
    | 'MUR'
    | 'MVR'
    | 'MWK'
    | 'MXN'
    | 'MXV'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NGN'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PGK'
    | 'PHP'
    | 'PKR'
    | 'PLN'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SBD'
    | 'SCR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SHP'
    | 'SLL'
    | 'SOS'
    | 'SRD'
    | 'SSP'
    | 'STD'
    | 'SYP'
    | 'SZL'
    | 'THB'
    | 'TJS'
    | 'TMT'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'USD'
    | 'USN'
    | 'UYI'
    | 'UYU'
    | 'UZS'
    | 'VEF'
    | 'VND'
    | 'VUV'
    | 'WST'
    | 'XAF'
    | 'XAG'
    | 'XAU'
    | 'XBA'
    | 'XBB'
    | 'XBC'
    | 'XBD'
    | 'XCD'
    | 'XDR'
    | 'XFU'
    | 'XOF'
    | 'XPD'
    | 'XPF'
    | 'XPT'
    | 'XSU'
    | 'XTS'
    | 'XUA'
    | 'XXX'
    | 'YER'
    | 'ZAR'
    | 'ZMW'
  )[];
  /** @format int32 */
  statusCode?: number;
}

export interface CourierInvoiceDisputeDto {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  invoiceId?: string;
  currency?: string;
  lumpSumAmount?: number;
  lumpSumAmountBeforeTax?: number;
  expectedTotalAmount?: Amount;
  creditNoteAmount?: Amount;
  resolutionDescription?: string;
  status?: 'DRAFT' | 'PENDING_DOCUMENT' | 'DONE';
  creditNoteFile?: CreditNoteFileDto;
  shipmentDeviations?: CourierShipmentDeviationsResolutionsDto[];
  nextWorkflowAction?:
    | 'SEND_TO_OPERATION'
    | 'SEND_TO_PROCUREMENT'
    | 'SEND_TO_FINANCE'
    | 'START_DISPUTE'
    | 'MARK_INVOICE_TO_REJECT'
    | 'MARK_INVOICE_TO_APPROVE'
    | 'ACCEPT_CREDIT_NOTE'
    | 'ACCEPT_APPROVAL'
    | 'READY_FOR_PAYMENT'
    | 'REJECT_INVOICE'
    | 'ACCEPT_INVOICE';
  agreementResolution?:
    | 'ACCEPT_INVOICE_AS_IS'
    | 'AMEND_INVOICE_CREDIT_NOTE'
    | 'REJECT_INVOICE'
    | 'COMMERCIAL_SETTLEMENT_CREDIT_NOTE'
    | 'ACCEPT_INVOICE_WITH_CREDIT_NOTE';
  deviationResolutionType?: 'BREAK_DOWN_PER_DEVIATION' | 'LUMP_SUM';
  amountToBePaid?: Amount;
}

export interface CourierShipmentDeviationsResolutionsDto {
  /** @format uuid */
  shipmentId?: string;
  shipmentNumber?: string;
  /** @format int32 */
  numberOfDeviations?: number;
  deviations?: DeviationDto[];
  deviationResolution?: DisputeDeviationResolutionDto[];
  status?: 'UNFILLED' | 'REJECTED' | 'APPROVED';
}

export interface ResponseDtoCourierInvoiceDisputeDto {
  success?: boolean;
  message?: string;
  data?: CourierInvoiceDisputeDto;
  /** @format int32 */
  statusCode?: number;
}

export interface InvoiceCourierShipmentDetailDto {
  /** @format uuid */
  shipmentId?: string;
  shipmentNumber?: string;
  status?: 'FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT';
  shipmentAmount?: Amount;
  deviationAmount?: Amount;
  shippingServiceType?: string;
  shipper?: string;
  placeToPickup?: string;
  /** @format date */
  departureDate?: string;
  consignee?: string;
  deliveryPlace?: string;
  /** @format date */
  arrivalDate?: string;
  totalChargeableWeight?: number;
  /** @format int32 */
  packageCount?: number;
  volume?: number;
  totalCo2Emission?: number;
  deviations?: DeviationDto[];
}

export interface ResponseDtoInvoiceCourierShipmentDetailDto {
  success?: boolean;
  message?: string;
  data?: InvoiceCourierShipmentDetailDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoListContractDto {
  success?: boolean;
  message?: string;
  data?: ContractDto[];
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoContractChargeListsDto {
  success?: boolean;
  message?: string;
  data?: ContractChargeListsDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoListSystemConfigurationDto {
  success?: boolean;
  message?: string;
  data?: SystemConfigurationDto[];
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoListChartReportDto {
  success?: boolean;
  message?: string;
  data?: ChartReportDto[];
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoJsonNode {
  success?: boolean;
  message?: string;
  data?: JsonNode;
  /** @format int32 */
  statusCode?: number;
}

export interface DeleteInvoiceDto {
  invoiceNumbers?: string[];
  batchIds?: string[];
  fileNames?: string[];
}

export interface ResponseDtoVoid {
  success?: boolean;
  message?: string;
  data?: object;
  /** @format int32 */
  statusCode?: number;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (securityData: SecurityDataType | null) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || 'http://9meth83ppf.ap-southeast-1.awsapprunner.com',
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({ secure, path, type, query, format, body, ...params }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams = ((typeof secure === 'boolean' ? secure : this.secure) && this.securityWorker && (await this.securityWorker(this.securityData))) || {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title OpenAPI definition
 * @version v0
 * @baseUrl http://9meth83ppf.ap-southeast-1.awsapprunner.com
 */
export class TFinancialApi<SecurityDataType extends unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  api = {
    /**
     * No description
     *
     * @tags test-endpoints-controller
     * @name TestImport
     * @request PUT:/api/test/invoices/test-import
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    testImport: (data: InvoiceMessageDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/test/invoices/test-import`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags shipping-service-controller
     * @name UpdateShippingService
     * @request PUT:/api/shipping-services/{id}
     * @response `200` `ResponseDtoShippingServiceDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    updateShippingService: (id: string, data: CreatingAndUpdatingShippingServiceReq, params: RequestParams = {}) =>
      this.http.request<ResponseDtoShippingServiceDto, ResponseDtoObject>({
        path: `/api/shipping-services/${id}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags shipping-service-controller
     * @name DeleteShippingService
     * @request DELETE:/api/shipping-services/{id}
     * @response `200` `ResponseDtoBoolean` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    deleteShippingService: (id: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoBoolean, ResponseDtoObject>({
        path: `/api/shipping-services/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags shipping-service-contract-migration-controller
     * @name CleanDuplicatedShippingServices
     * @request PUT:/api/shipping-service-contract-migration/shipping-services/duplicate-removal
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    cleanDuplicatedShippingServices: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/shipping-service-contract-migration/shipping-services/duplicate-removal`,
        method: 'PUT',
        ...params,
      }),

    /**
     * No description
     *
     * @tags shipping-service-contract-migration-controller
     * @name CleanDuplicatedContracts
     * @request PUT:/api/shipping-service-contract-migration/contracts/duplicate-removal
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    cleanDuplicatedContracts: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/shipping-service-contract-migration/contracts/duplicate-removal`,
        method: 'PUT',
        ...params,
      }),

    /**
     * @description Fetches a list of raw shipment DTOs based on the provided shipment number.
     *
     * @tags shipment-controller
     * @name GetShipmentByShipmentNumber
     * @summary Retrieve shipments by shipment number
     * @request GET:/api/shipments
     * @response `200` `ResponseDtoListRawShipmentDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getShipmentByShipmentNumber: (
      query: {
        shipmentNumber: string;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<ResponseDtoListRawShipmentDto, ResponseDtoObject>({
        path: `/api/shipments`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * @description Updates a shipment entity based on the provided RawShipmentDto request.
     *
     * @tags shipment-controller
     * @name UpdateShipment
     * @summary Update shipment
     * @request PUT:/api/shipments
     * @response `200` `ResponseDtoRawShipmentDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    updateShipment: (data: RawShipmentDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoRawShipmentDto, ResponseDtoObject>({
        path: `/api/shipments`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags shipment-controller
     * @name PerformDeviationWorkflowActionOnInvoiceShipment
     * @request PUT:/api/shipments/shipment-invoices/{shipmentInvoiceId}/{deviationWorkflowAction}
     * @response `200` `ResponseDtoShipmentInActionDetailDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    performDeviationWorkflowActionOnInvoiceShipment: (
      shipmentInvoiceId: string,
      deviationWorkflowAction:
        | 'SEND_TO_OPERATION'
        | 'SEND_TO_PROCUREMENT'
        | 'SEND_TO_FINANCE'
        | 'START_DISPUTE'
        | 'MARK_INVOICE_TO_REJECT'
        | 'MARK_INVOICE_TO_APPROVE'
        | 'ACCEPT_CREDIT_NOTE'
        | 'ACCEPT_APPROVAL'
        | 'READY_FOR_PAYMENT'
        | 'REJECT_INVOICE'
        | 'ACCEPT_INVOICE',
      data: DeviationWorkflowActionBodyDto,
      params: RequestParams = {}
    ) =>
      this.http.request<ResponseDtoShipmentInActionDetailDto, ResponseDtoObject>({
        path: `/api/shipments/shipment-invoices/${shipmentInvoiceId}/${deviationWorkflowAction}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rate-card-controller
     * @name UpsertWeightCharge
     * @request PUT:/api/rate-cards/contract-charge-unit/weight
     * @response `200` `ResponseDtoWeightChargeDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    upsertWeightCharge: (data: UpsertContractWeightChargeReq, params: RequestParams = {}) =>
      this.http.request<ResponseDtoWeightChargeDto, ResponseDtoObject>({
        path: `/api/rate-cards/contract-charge-unit/weight`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rate-card-controller
     * @name UpsertShipmentCharge
     * @request PUT:/api/rate-cards/contract-charge-unit/shipment
     * @response `200` `ResponseDtoShipmentChargeDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    upsertShipmentCharge: (data: UpsertContractShipmentChargeReq, params: RequestParams = {}) =>
      this.http.request<ResponseDtoShipmentChargeDto, ResponseDtoObject>({
        path: `/api/rate-cards/contract-charge-unit/shipment`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rate-card-controller
     * @name UpsertPercentageCharge
     * @request PUT:/api/rate-cards/contract-charge-unit/percentage
     * @response `200` `ResponseDtoPercentageChargeDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    upsertPercentageCharge: (data: UpsertContractPercentageChargeReq, params: RequestParams = {}) =>
      this.http.request<ResponseDtoPercentageChargeDto, ResponseDtoObject>({
        path: `/api/rate-cards/contract-charge-unit/percentage`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rate-card-controller
     * @name UpsertDeclarationCharge
     * @request PUT:/api/rate-cards/contract-charge-unit/declaration
     * @response `200` `ResponseDtoDeclarationChargeDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    upsertDeclarationCharge: (data: UpsertContractDeclarationChargeReq, params: RequestParams = {}) =>
      this.http.request<ResponseDtoDeclarationChargeDto, ResponseDtoObject>({
        path: `/api/rate-cards/contract-charge-unit/declaration`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rate-card-controller
     * @name UpsertContainerCharge
     * @request PUT:/api/rate-cards/contract-charge-unit/container
     * @response `200` `ResponseDtoContainerChargeDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    upsertContainerCharge: (data: UpsertContractContainerChargeReq, params: RequestParams = {}) =>
      this.http.request<ResponseDtoContainerChargeDto, ResponseDtoObject>({
        path: `/api/rate-cards/contract-charge-unit/container`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags notification-controller
     * @name MarkNotificationAsRead
     * @request PUT:/api/notifications/mark-as-read
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    markNotificationAsRead: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/notifications/mark-as-read`,
        method: 'PUT',
        ...params,
      }),

    /**
     * @description Migrates a legal entity from the specified old legal entity ID to the new legal entity ID.
     *
     * @tags migration-controller
     * @name MigrateALegalEntityToAnotherOne
     * @summary Migrate a legal entity to another one
     * @request PUT:/api/migrations/legal-entities
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    migrateALegalEntityToAnotherOne: (
      query: {
        /** @format int64 */
        oldLegalEntityId: number;
        /** @format int64 */
        newLegalEntityId: number;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/migrations/legal-entities`,
        method: 'PUT',
        query: query,
        ...params,
      }),

    /**
     * @description Creates new legal entities or updates existing ones based on the provided requests. if id is null, it means creation
     *
     * @tags legal-entity-controller
     * @name UpsertLegalEntities
     * @summary Upsert legal entities along with their addresses
     * @request PUT:/api/legal-entities
     * @response `200` `ResponseDtoListLegalEntityDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    upsertLegalEntities: (data: LegalEntityDto[], params: RequestParams = {}) =>
      this.http.request<ResponseDtoListLegalEntityDto, ResponseDtoObject>({
        path: `/api/legal-entities`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Creates a new legal entity alias or updates an existing one based on the provided request.
     *
     * @tags legal-entity-controller
     * @name UpsertLegalEntityAlias
     * @summary Upsert a legal entity alias
     * @request PUT:/api/legal-entities/aliases
     * @response `200` `ResponseDtoLegalEntityNameAliasDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    upsertLegalEntityAlias: (data: UpsertLegalEntityAliasRequest, params: RequestParams = {}) =>
      this.http.request<ResponseDtoLegalEntityNameAliasDto, ResponseDtoObject>({
        path: `/api/legal-entities/aliases`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Creates a new legal entity address or updates an existing one based on the provided request.
     *
     * @tags legal-entity-controller
     * @name UpsertLegalEntityAddress
     * @summary Upsert a legal entity address
     * @request PUT:/api/legal-entities/addresses
     * @response `200` `ResponseDtoLegalEntityAddressDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    upsertLegalEntityAddress: (data: LegalEntityAddressDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoLegalEntityAddressDto, ResponseDtoObject>({
        path: `/api/legal-entities/addresses`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Deletes a list of legal entity addresses based on their IDs.
     *
     * @tags legal-entity-controller
     * @name DeleteLegalEntityAddresses
     * @summary Delete legal entity addresses
     * @request DELETE:/api/legal-entities/addresses
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    deleteLegalEntityAddresses: (data: number[], params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/legal-entities/addresses`,
        method: 'DELETE',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Triggers the email reading task manually.
     *
     * @tags jobs, admin, demo
     * @name TriggerEmailReadingTask
     * @summary Trigger email reading task
     * @request PUT:/api/jobs/email-reading/trigger
     * @response `200` `ResponseDtoBoolean` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    triggerEmailReadingTask: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoBoolean, ResponseDtoObject>({
        path: `/api/jobs/email-reading/trigger`,
        method: 'PUT',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name PerformDeviationWorkflowAction
     * @request PUT:/api/invoices/{invoiceId}/{deviationWorkflowAction}
     * @response `200` `ResponseDtoInvoiceSummaryDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    performDeviationWorkflowAction: (
      invoiceId: string,
      deviationWorkflowAction:
        | 'SEND_TO_OPERATION'
        | 'SEND_TO_PROCUREMENT'
        | 'SEND_TO_FINANCE'
        | 'START_DISPUTE'
        | 'MARK_INVOICE_TO_REJECT'
        | 'MARK_INVOICE_TO_APPROVE'
        | 'ACCEPT_CREDIT_NOTE'
        | 'ACCEPT_APPROVAL'
        | 'READY_FOR_PAYMENT'
        | 'REJECT_INVOICE'
        | 'ACCEPT_INVOICE',
      data: DeviationWorkflowActionBodyDto,
      params: RequestParams = {}
    ) =>
      this.http.request<ResponseDtoInvoiceSummaryDto, ResponseDtoObject>({
        path: `/api/invoices/${invoiceId}/${deviationWorkflowAction}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name GetUserResponse
     * @request GET:/api/invoices/shipment-invoices/{shipmentInvoiceId}/approval-questionnaire-user-response
     * @response `200` `ResponseDtoMapDeviationWorkflowListDeviationQuestionUserResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getUserResponse: (shipmentInvoiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoMapDeviationWorkflowListDeviationQuestionUserResponseDto, ResponseDtoObject>({
        path: `/api/invoices/shipment-invoices/${shipmentInvoiceId}/approval-questionnaire-user-response`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name PostUserResponse
     * @request PUT:/api/invoices/shipment-invoices/{shipmentInvoiceId}/approval-questionnaire-user-response
     * @response `200` `ResponseDtoListDeviationQuestionUserResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    postUserResponse: (shipmentInvoiceId: string, data: DeviationQuestionUserResponseDto[], params: RequestParams = {}) =>
      this.http.request<ResponseDtoListDeviationQuestionUserResponseDto, ResponseDtoObject>({
        path: `/api/invoices/shipment-invoices/${shipmentInvoiceId}/approval-questionnaire-user-response`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-dispute-controller
     * @name GetInvoiceDisputeDetail
     * @request GET:/api/invoices/dispute/{invoiceDisputeId}
     * @response `200` `ResponseDtoInvoiceDisputeDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getInvoiceDisputeDetail: (invoiceDisputeId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoInvoiceDisputeDto, ResponseDtoObject>({
        path: `/api/invoices/dispute/${invoiceDisputeId}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-dispute-controller
     * @name UpdateInvoiceDispute
     * @request PUT:/api/invoices/dispute/{invoiceDisputeId}
     * @response `200` `ResponseDtoInvoiceDisputeDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    updateInvoiceDispute: (invoiceDisputeId: string, data: InvoiceDisputeUpdateRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoInvoiceDisputeDto, ResponseDtoObject>({
        path: `/api/invoices/dispute/${invoiceDisputeId}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-dispute-controller
     * @name PerformDeviationWorkflowAction1
     * @request PUT:/api/invoices/dispute/{invoiceDisputeId}/{deviationWorkflowAction}
     * @response `200` `ResponseDtoInvoiceDisputeDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    performDeviationWorkflowAction1: (
      invoiceDisputeId: string,
      deviationWorkflowAction:
        | 'SEND_TO_OPERATION'
        | 'SEND_TO_PROCUREMENT'
        | 'SEND_TO_FINANCE'
        | 'START_DISPUTE'
        | 'MARK_INVOICE_TO_REJECT'
        | 'MARK_INVOICE_TO_APPROVE'
        | 'ACCEPT_CREDIT_NOTE'
        | 'ACCEPT_APPROVAL'
        | 'READY_FOR_PAYMENT'
        | 'REJECT_INVOICE'
        | 'ACCEPT_INVOICE',
      data: DeviationWorkflowActionBodyDto,
      params: RequestParams = {}
    ) =>
      this.http.request<ResponseDtoInvoiceDisputeDto, ResponseDtoObject>({
        path: `/api/invoices/dispute/${invoiceDisputeId}/${deviationWorkflowAction}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-dispute-controller
     * @name GetInvoiceDisputeCreditNote
     * @request GET:/api/invoices/dispute/{invoiceDisputeId}/credit-note
     * @response `200` `ResponseDtoCreditNoteDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getInvoiceDisputeCreditNote: (invoiceDisputeId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoCreditNoteDto, ResponseDtoObject>({
        path: `/api/invoices/dispute/${invoiceDisputeId}/credit-note`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-dispute-controller
     * @name UpdateInvoiceDisputeCreditNote
     * @request PUT:/api/invoices/dispute/{invoiceDisputeId}/credit-note
     * @response `200` `ResponseDtoCreditNoteDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    updateInvoiceDisputeCreditNote: (invoiceDisputeId: string, data: CreditNoteDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoCreditNoteDto, ResponseDtoObject>({
        path: `/api/invoices/dispute/${invoiceDisputeId}/credit-note`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-dispute-controller
     * @name GetUploadCreditNoteUrl
     * @request PUT:/api/invoices/dispute/{invoiceDisputeId}/credit-note-upload-url
     * @response `200` `ResponseDtoCreditNoteFileDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getUploadCreditNoteUrl: (invoiceDisputeId: string, data: UploadCreditNoteUrlRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoCreditNoteFileDto, ResponseDtoObject>({
        path: `/api/invoices/dispute/${invoiceDisputeId}/credit-note-upload-url`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-dispute-controller
     * @name DeleteCreditNotes
     * @request DELETE:/api/invoices/dispute/{invoiceDisputeId}/credit-note-upload-url
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    deleteCreditNotes: (invoiceDisputeId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/invoices/dispute/${invoiceDisputeId}/credit-note-upload-url`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-ocr-message-controller
     * @name ReImport
     * @request PUT:/api/invoice-ocr-message
     * @response `200` `ResponseDtoBoolean` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    reImport: (data: InvoiceMessageDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoBoolean, ResponseDtoObject>({
        path: `/api/invoice-ocr-message`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-file-processing-controller
     * @name UpdateStatusInvoiceFileProcessing
     * @request PUT:/api/invoice-file-processing/{invoiceFileProcessingId}/{status}
     * @response `200` `ResponseDtoInvoiceFileProcessingDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    updateStatusInvoiceFileProcessing: (
      invoiceFileProcessingId: string,
      status:
        | 'BEING_UPLOADED'
        | 'COMPLETE'
        | 'OCR_PROCESSING'
        | 'OCR_COMPLETE'
        | 'OCR_ERROR'
        | 'TRANSFORM_ERROR'
        | 'TRANSFORM_COMPLETE'
        | 'MESSAGE_PROCESSING_FAILED'
        | 'UPLOAD_FROM_EMAIL_FAILED'
        | 'RESOLVED_AS_NOT_AN_INVOICE'
        | 'RESOLVED_AS_MISSING_REQUIRED_FIELD'
        | 'RESOLVED_AS_INCONSISTENT_SHIPMENT_DATA'
        | 'MARKED_MISSING_REQUIRED_FIELD_FOR_REVIEW'
        | 'MARKED_INCONSISTENT_SHIPMENT_DATA_FOR_REVIEW'
        | 'USER_MARK_AS_ERROR',
      data: InvoiceFileProcessingStatusUpdateReq,
      params: RequestParams = {}
    ) =>
      this.http.request<ResponseDtoInvoiceFileProcessingDto, ResponseDtoObject>({
        path: `/api/invoice-file-processing/${invoiceFileProcessingId}/${status}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-charge-domain-mapping-controller
     * @name UpsertInvoiceChargeDomainMapping
     * @request PUT:/api/invoice-charge-domain-mappings
     * @response `200` `ResponseDtoListInvoiceChargeDomainMappingDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    upsertInvoiceChargeDomainMapping: (data: UpsertInvoiceChargeDomainMappingReq[], params: RequestParams = {}) =>
      this.http.request<ResponseDtoListInvoiceChargeDomainMappingDto, ResponseDtoObject>({
        path: `/api/invoice-charge-domain-mappings`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-charge-domain-mapping-controller
     * @name DeleteInvoiceChargeDomainMappingByIds
     * @request DELETE:/api/invoice-charge-domain-mappings
     * @response `200` `ResponseDtoBoolean` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    deleteInvoiceChargeDomainMappingByIds: (data: number[], params: RequestParams = {}) =>
      this.http.request<ResponseDtoBoolean, ResponseDtoObject>({
        path: `/api/invoice-charge-domain-mappings`,
        method: 'DELETE',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags deviation-questionnaire-controller
     * @name GetUserResponse1
     * @request GET:/api/deviations/{id}/user-response
     * @response `200` `ResponseDtoMapDeviationWorkflowListDeviationQuestionUserResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getUserResponse1: (id: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoMapDeviationWorkflowListDeviationQuestionUserResponseDto, ResponseDtoObject>({
        path: `/api/deviations/${id}/user-response`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags deviation-questionnaire-controller
     * @name PostUserResponse1
     * @request PUT:/api/deviations/{id}/user-response
     * @response `200` `ResponseDtoListDeviationQuestionUserResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    postUserResponse1: (id: string, data: DeviationQuestionnaireQuestionUserResponseDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoListDeviationQuestionUserResponseDto, ResponseDtoObject>({
        path: `/api/deviations/${id}/user-response`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags deviation-migration-controller
     * @name UpdateMatchingContractIdOnDeviation
     * @request PUT:/api/deviation-migration/update-matching-contract-id
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    updateMatchingContractIdOnDeviation: (
      query: {
        invoiceStatus: 'FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT';
      },
      params: RequestParams = {}
    ) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/deviation-migration/update-matching-contract-id`,
        method: 'PUT',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags deviation-migration-controller
     * @name UpdateMatchingContractIdOnDeviationForShipmentInvoice
     * @request PUT:/api/deviation-migration/shipment-invoices/{shipmentInvoiceId}/update-matching-contract-id
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    updateMatchingContractIdOnDeviationForShipmentInvoice: (shipmentInvoiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/deviation-migration/shipment-invoices/${shipmentInvoiceId}/update-matching-contract-id`,
        method: 'PUT',
        ...params,
      }),

    /**
     * No description
     *
     * @tags deviation-migration-controller
     * @name ReTrigger
     * @request PUT:/api/deviation-migration/invoices/re-trigger
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    reTrigger: (data: InvoiceReTriggerRequest, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/deviation-migration/invoices/re-trigger`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract-controller
     * @name UpdateContract
     * @request PUT:/api/contracts/{id}
     * @response `200` `ResponseDtoContractDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    updateContract: (id: string, data: UpdatingContractReq, params: RequestParams = {}) =>
      this.http.request<ResponseDtoContractDto, ResponseDtoObject>({
        path: `/api/contracts/${id}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract-flow-controller
     * @name GetContractFlow
     * @request GET:/api/contract-flows/{contractFlowId}
     * @response `200` `ResponseDtoContractFlowInfoViewDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getContractFlow: (contractFlowId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoContractFlowInfoViewDto, ResponseDtoObject>({
        path: `/api/contract-flows/${contractFlowId}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract-flow-controller
     * @name UpdateContractFlow
     * @request PUT:/api/contract-flows/{contractFlowId}
     * @response `200` `ResponseDtoContractFlowInfoViewDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    updateContractFlow: (contractFlowId: string, data: CreatingAndUpdatingContractFlowReq, params: RequestParams = {}) =>
      this.http.request<ResponseDtoContractFlowInfoViewDto, ResponseDtoObject>({
        path: `/api/contract-flows/${contractFlowId}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract-flow-controller
     * @name RemoveRateCardsToContractFlow
     * @request PUT:/api/contract-flows/{contractFlowId}/rate-cards/removal
     * @response `200` `ResponseDtoContractFlowInfoViewDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    removeRateCardsToContractFlow: (contractFlowId: string, data: AddingOrRemovingRateCardToContractFlowReq[], params: RequestParams = {}) =>
      this.http.request<ResponseDtoContractFlowInfoViewDto, ResponseDtoObject>({
        path: `/api/contract-flows/${contractFlowId}/rate-cards/removal`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract-flow-controller
     * @name AddRateCardsToContractFlow
     * @request PUT:/api/contract-flows/{contractFlowId}/rate-cards/addition
     * @response `200` `ResponseDtoContractFlowInfoViewDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    addRateCardsToContractFlow: (contractFlowId: string, data: AddingOrRemovingRateCardToContractFlowReq[], params: RequestParams = {}) =>
      this.http.request<ResponseDtoContractFlowInfoViewDto, ResponseDtoObject>({
        path: `/api/contract-flows/${contractFlowId}/rate-cards/addition`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags chart-report-controller
     * @name GetChartReports
     * @request GET:/api/chart-report
     * @response `200` `ResponseDtoListChartReportDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getChartReports: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoListChartReportDto, ResponseDtoObject>({
        path: `/api/chart-report`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags chart-report-controller
     * @name UpdateChartReport
     * @request PUT:/api/chart-report
     * @response `200` `ResponseDtoChartReportDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    updateChartReport: (data: ChartReportRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoChartReportDto, ResponseDtoObject>({
        path: `/api/chart-report`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags chart-report-controller
     * @name CreateChartReport
     * @request POST:/api/chart-report
     * @response `200` `ResponseDtoChartReportDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    createChartReport: (data: ChartReportRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoChartReportDto, ResponseDtoObject>({
        path: `/api/chart-report`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Retrieves the OCR output for a given invoice file processing ID. Accessible by SYSTEM_ADMIN.
     *
     * @tags aws-controller
     * @name GetOcrOutput
     * @summary Retrieve OCR output
     * @request GET:/api/aws/s3/ocr-output/invoice-file-processing/{invoiceFileProcessingId}
     * @response `200` `ResponseDtoJsonNode` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getOcrOutput: (invoiceFileProcessingId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoJsonNode, ResponseDtoObject>({
        path: `/api/aws/s3/ocr-output/invoice-file-processing/${invoiceFileProcessingId}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Updates the OCR output for a given invoice file processing ID, then transformer will be triggered. Accessible by SYSTEM_ADMIN.
     *
     * @tags aws-controller
     * @name UpdateOcrOutput
     * @summary Update OCR output
     * @request PUT:/api/aws/s3/ocr-output/invoice-file-processing/{invoiceFileProcessingId}
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    updateOcrOutput: (invoiceFileProcessingId: string, data: JsonNode, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/aws/s3/ocr-output/invoice-file-processing/${invoiceFileProcessingId}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags exchange-rate-amounts-re-calculator
     * @name StopMigration
     * @request PUT:/api/admin/recalculate-exchange-price/migration/stop
     * @response `200` `string` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    stopMigration: (params: RequestParams = {}) =>
      this.http.request<string, ResponseDtoObject>({
        path: `/api/admin/recalculate-exchange-price/migration/stop`,
        method: 'PUT',
        ...params,
      }),

    /**
     * No description
     *
     * @tags exchange-rate-amounts-re-calculator
     * @name GetBatchSize
     * @request GET:/api/admin/recalculate-exchange-price/migration/batch-size
     * @response `200` `string` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getBatchSize: (params: RequestParams = {}) =>
      this.http.request<string, ResponseDtoObject>({
        path: `/api/admin/recalculate-exchange-price/migration/batch-size`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags exchange-rate-amounts-re-calculator
     * @name SetBatchSize
     * @request PUT:/api/admin/recalculate-exchange-price/migration/batch-size
     * @response `200` `string` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    setBatchSize: (
      query: {
        request: MigrationBatchSizeRequest;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<string, ResponseDtoObject>({
        path: `/api/admin/recalculate-exchange-price/migration/batch-size`,
        method: 'PUT',
        query: query,
        ...params,
      }),

    /**
     * @description Creates a new user with the provided details.
     *
     * @tags User, Create
     * @name CreateUser
     * @summary Create a new user
     * @request POST:/api/users
     * @response `200` `ResponseDtoUserDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    createUser: (data: UserCreateRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoUserDto, ResponseDtoObject>({
        path: `/api/users`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Retrieves the permissions of the logged-in user.
     *
     * @tags User, Permissions
     * @name GetUserPermissions
     * @summary Get user permissions
     * @request GET:/api/users/permissions
     * @response `200` `ResponseDtoListUserDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getUserPermissions: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoListUserDto, ResponseDtoObject>({
        path: `/api/users/permissions`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Creates or updates the permissions for a user.
     *
     * @tags User, Permissions, Create, Update
     * @name CreateUserPermission
     * @summary Create or update user permissions
     * @request POST:/api/users/permissions
     * @response `200` `ResponseDtoListUserPermissionDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    createUserPermission: (data: UserEntityPermissionDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoListUserPermissionDto, ResponseDtoObject>({
        path: `/api/users/permissions`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-issue-controller
     * @name CreateUserIssue
     * @request POST:/api/user-resolutions
     * @response `200` `ResponseDtoUserIssueDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    createUserIssue: (data: UserIssueRequest, params: RequestParams = {}) =>
      this.http.request<ResponseDtoUserIssueDto, ResponseDtoObject>({
        path: `/api/user-resolutions`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags test-endpoints-controller
     * @name CreateDeviation
     * @request POST:/api/test/charges/{chargeId}/create-deviation
     * @response `200` `ResponseDtoDeviation` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    createDeviation: (chargeId: string, data: DeviationCreateRequest, params: RequestParams = {}) =>
      this.http.request<ResponseDtoDeviation, ResponseDtoObject>({
        path: `/api/test/charges/${chargeId}/create-deviation`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags shipping-service-controller
     * @name GetShippingServiceByServiceProviderAndBillingParty
     * @request GET:/api/shipping-services
     * @response `200` `ResponseDtoListShippingServiceDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getShippingServiceByServiceProviderAndBillingParty: (
      query: {
        /** @format int64 */
        serviceProviderId: number;
        /** @format int64 */
        billingPartyId: number;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<ResponseDtoListShippingServiceDto, ResponseDtoObject>({
        path: `/api/shipping-services`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags shipping-service-controller
     * @name CreateShippingService
     * @request POST:/api/shipping-services
     * @response `200` `ResponseDtoShippingServiceDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    createShippingService: (data: CreatingAndUpdatingShippingServiceReq, params: RequestParams = {}) =>
      this.http.request<ResponseDtoShippingServiceDto, ResponseDtoObject>({
        path: `/api/shipping-services`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get the matching shipping service based on the provided criteria
     *
     * @tags admin
     * @name GetMatchingShippingService
     * @summary Get the matching shipping service
     * @request POST:/api/shipping-services/matching
     * @response `200` `ResponseDtoShippingServiceDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getMatchingShippingService: (data: GetMatchingShippingServiceRequest, params: RequestParams = {}) =>
      this.http.request<ResponseDtoShippingServiceDto, ResponseDtoObject>({
        path: `/api/shipping-services/matching`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags shipment-controller
     * @name CalculateShipmentCo2Emission
     * @request POST:/api/shipments/{shipmentId}/calculate-co2e
     * @response `200` `ResponseDtoSeaRoutesResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    calculateShipmentCo2Emission: (shipmentId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoSeaRoutesResponseDto, ResponseDtoObject>({
        path: `/api/shipments/${shipmentId}/calculate-co2e`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags shipment-controller
     * @name CreateShippingServiceAndContract
     * @request POST:/api/shipments/shipment-invoices/{shipmentInvoiceId}/new-service-contract
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    createShippingServiceAndContract: (shipmentInvoiceId: string, data: NewShippingServiceAndContractRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/shipments/shipment-invoices/${shipmentInvoiceId}/new-service-contract`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags shipment-invoice-deviation-controller
     * @name RecalculateInvoiceDeviations
     * @request POST:/api/shipment-invoices/{shipmentInvoiceId}/recalculate-new-charge
     * @response `200` `ResponseDtoListDeviation` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    recalculateInvoiceDeviations: (shipmentInvoiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoListDeviation, ResponseDtoObject>({
        path: `/api/shipment-invoices/${shipmentInvoiceId}/recalculate-new-charge`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags no-contract-draft-controller
     * @name GetActiveNoContractDraft
     * @request GET:/api/shipment-invoices/{shipmentInvoiceId}/no-contract-drafts
     * @response `200` `ResponseDtoNoContractDraft` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getActiveNoContractDraft: (shipmentInvoiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoNoContractDraft, ResponseDtoObject>({
        path: `/api/shipment-invoices/${shipmentInvoiceId}/no-contract-drafts`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags no-contract-draft-controller
     * @name CreateOrUpdateNoContractDraft
     * @request POST:/api/shipment-invoices/{shipmentInvoiceId}/no-contract-drafts
     * @response `200` `ResponseDtoNoContractDraft` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    createOrUpdateNoContractDraft: (shipmentInvoiceId: string, data: NoContractDraftRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoNoContractDraft, ResponseDtoObject>({
        path: `/api/shipment-invoices/${shipmentInvoiceId}/no-contract-drafts`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags no-contract-draft-controller
     * @name DiscardNoContractDraft
     * @request POST:/api/shipment-invoices/{shipmentInvoiceId}/no-contract-drafts/discard
     * @response `200` `ResponseDtoNoContractDraft` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    discardNoContractDraft: (shipmentInvoiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoNoContractDraft, ResponseDtoObject>({
        path: `/api/shipment-invoices/${shipmentInvoiceId}/no-contract-drafts/discard`,
        method: 'POST',
        ...params,
      }),

    /**
 * No description
 *
 * @tags universal-search-controller
 * @name PerformUniversalSearch
 * @summary Search invoice universe by invoice number and shipment number via searchKey.
If isApprovedInvoice is true, only approved invoices will be included in the search results.
If isApprovedInvoice is null, all invoices will be included in the search results.
The page number and page size are used for pagination.
The search key must be at least 3 characters long.
 * @request POST:/api/search
 * @response `200` `ResponseDtoUniversalSearchResponseDto` OK
 * @response `403` `ResponseDtoObject` Forbidden
 */
    performUniversalSearch: (data: UniversalSearchRequest, params: RequestParams = {}) =>
      this.http.request<ResponseDtoUniversalSearchResponseDto, ResponseDtoObject>({
        path: `/api/search`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Triggers the refresh of materialized views
     *
     * @tags admin
     * @name RefreshMaterializedViews
     * @summary Triggers the refresh of materialized views
     * @request POST:/api/refresh-materialized-views
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    refreshMaterializedViews: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/refresh-materialized-views`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags performance-assessment-controller
     * @name GetVolumetricTableDataByMonthWithFilter
     * @request POST:/api/performance-assessment/volumetric/per-months
     * @response `200` `ResponseDtoPerformanceVolumetricTableResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getVolumetricTableDataByMonthWithFilter: (data: PerformanceAssessmentVolumetricTableRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoPerformanceVolumetricTableResponseDto, ResponseDtoObject>({
        path: `/api/performance-assessment/volumetric/per-months`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags performance-assessment-controller
     * @name GetVolumetricTableDataDownload
     * @request POST:/api/performance-assessment/volumetric/per-months/download
     * @response `200` `void` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getVolumetricTableDataDownload: (
      query: {
        'file-name': string;
        'file-type': 'PDF' | 'CSV' | 'EXCEL';
      },
      data: PerformanceAssessmentVolumetricTableRequestDto,
      params: RequestParams = {}
    ) =>
      this.http.request<void, ResponseDtoObject>({
        path: `/api/performance-assessment/volumetric/per-months/download`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags performance-assessment-controller
     * @name GetKeyMetricsVolumesWithFilter
     * @request POST:/api/performance-assessment/volumes/metric
     * @response `200` `ResponseDtoVolumetricResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getKeyMetricsVolumesWithFilter: (data: PerformanceAssessmentKeyMetricRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoVolumetricResponseDto, ResponseDtoObject>({
        path: `/api/performance-assessment/volumes/metric`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags performance-assessment-controller
     * @name GetSavingsTableDataByMonthWithFilter
     * @request POST:/api/performance-assessment/savings/per-months
     * @response `200` `ResponseDtoPerformanceSavingsTableResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getSavingsTableDataByMonthWithFilter: (data: PerformanceAssessmentSavingsTableRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoPerformanceSavingsTableResponseDto, ResponseDtoObject>({
        path: `/api/performance-assessment/savings/per-months`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags performance-assessment-controller
     * @name GetSavingsTableDataDownload
     * @request POST:/api/performance-assessment/savings/per-months/download
     * @response `200` `void` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getSavingsTableDataDownload: (
      query: {
        'file-name': string;
        'file-type': 'PDF' | 'CSV' | 'EXCEL';
      },
      data: PerformanceAssessmentSavingsTableRequestDto,
      params: RequestParams = {}
    ) =>
      this.http.request<void, ResponseDtoObject>({
        path: `/api/performance-assessment/savings/per-months/download`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags performance-assessment-controller
     * @name GetKeyMetricsSavingsWithFilter
     * @request POST:/api/performance-assessment/savings/metric
     * @response `200` `ResponseDtoSavingsMetricResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getKeyMetricsSavingsWithFilter: (data: PerformanceAssessmentKeyMetricRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoSavingsMetricResponseDto, ResponseDtoObject>({
        path: `/api/performance-assessment/savings/metric`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags key-metrics-controller
     * @name GetKeyMetricsWithFilter
     * @request POST:/api/key-metrics/{useType}
     * @response `200` `ResponseDtoKeyMetricsResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getKeyMetricsWithFilter: (useType: 'DASHBOARD' | 'REPORT' | 'PERFORMANCE_ASSESSMENT', data: FiltersRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoKeyMetricsResponseDto, ResponseDtoObject>({
        path: `/api/key-metrics/${useType}`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name IsValidityPeriodValid
     * @request POST:/api/invoices/{invoiceId}/verify-validity-period
     * @response `200` `ResponseDtoMapStringBoolean` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    isValidityPeriodValid: (invoiceId: string, data: ExistingShippingServiceDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoMapStringBoolean, ResponseDtoObject>({
        path: `/api/invoices/${invoiceId}/verify-validity-period`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name GetInvoiceList
     * @request POST:/api/invoices/search
     * @response `200` `ResponseDtoInvoiceSearchResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getInvoiceList: (data: InvoiceSearchRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoInvoiceSearchResponseDto, ResponseDtoObject>({
        path: `/api/invoices/search`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-upload-controller
     * @name GetInvoicePreSignedUrLs
     * @request POST:/api/invoices/invoice-upload-pre-signed-urls
     * @response `200` `ResponseDtoInvoiceUploadSignedUrlResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getInvoicePreSignedUrLs: (data: InvoiceUploadSignedUrlRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoInvoiceUploadSignedUrlResponseDto, ResponseDtoObject>({
        path: `/api/invoices/invoice-upload-pre-signed-urls`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-upload-controller
     * @name GetAttachmentPreSignedUrLs
     * @request POST:/api/invoices/invoice-attachment-pre-signed-urls
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getAttachmentPreSignedUrLs: (data: InvoiceAttachmentUploadSignedUrlRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/invoices/invoice-attachment-pre-signed-urls`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name AssignFapRef
     * @request POST:/api/invoices/fap-ref
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    assignFapRef: (data: SetFapRefRequest, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/invoices/fap-ref`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-file-processing-controller
     * @name Query
     * @request POST:/api/invoice-file-processing/query
     * @response `200` `ResponseDtoInternalDashboardTableResponseDtoInvoiceFileProcessingDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    query: (data: SearchingInvoiceFileProcessingRequest, params: RequestParams = {}) =>
      this.http.request<ResponseDtoInternalDashboardTableResponseDtoInvoiceFileProcessingDto, ResponseDtoObject>({
        path: `/api/invoice-file-processing/query`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Retrieves all invoice data pipeline configurations based on the provided filters.
     *
     * @tags Invoice Data Pipeline Config, Get
     * @name GetAllInvoiceDataPipelineConfigs
     * @summary Get all invoice data pipeline configs
     * @request GET:/api/invoice-data-pipeline-configs
     * @response `200` `ResponseDtoListInvoiceDataPipelineConfigDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getAllInvoiceDataPipelineConfigs: (
      query?: {
        dataPipelineConfigId?: string;
        modelId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<ResponseDtoListInvoiceDataPipelineConfigDto, ResponseDtoObject>({
        path: `/api/invoice-data-pipeline-configs`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * @description Creates a new invoice data pipeline config with the provided details.
     *
     * @tags Invoice Data Pipeline Config, Create
     * @name CreateInvoiceDataPipelineConfig
     * @summary Create a new invoice data pipeline config
     * @request POST:/api/invoice-data-pipeline-configs
     * @response `200` `ResponseDtoInvoiceDataPipelineConfigDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    createInvoiceDataPipelineConfig: (data: InvoiceDataPipelineConfigDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoInvoiceDataPipelineConfigDto, ResponseDtoObject>({
        path: `/api/invoice-data-pipeline-configs`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Retrieves a list of invoice data pipeline configurations based on the provided filters.
     *
     * @tags Invoice Data Pipeline Config, Get
     * @name GetInvoiceDataPipelineConfigs
     * @summary Get invoice data pipeline configs
     * @request POST:/api/invoice-data-pipeline-configs/query
     * @response `200` `ResponseDtoListInvoiceDataPipelineConfigDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getInvoiceDataPipelineConfigs: (data: InvoiceDataPipelineConfigRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoListInvoiceDataPipelineConfigDto, ResponseDtoObject>({
        path: `/api/invoice-data-pipeline-configs/query`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-charge-domain-mapping-controller
     * @name GetInvoiceChargeDomainMappings
     * @request POST:/api/invoice-charge-domain-mappings/query
     * @response `200` `ResponseDtoPageInvoiceChargeDomainMappingDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getInvoiceChargeDomainMappings: (data: FilterInvoiceChargeDomainMappingReq, params: RequestParams = {}) =>
      this.http.request<ResponseDtoPageInvoiceChargeDomainMappingDto, ResponseDtoObject>({
        path: `/api/invoice-charge-domain-mappings/query`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags filter-controller
     * @name GetFilters
     * @request POST:/api/filters/{useType}
     * @response `200` `ResponseDtoFiltersDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getFilters: (useType: 'DASHBOARD' | 'REPORT' | 'PERFORMANCE_ASSESSMENT', data: FiltersRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoFiltersDto, ResponseDtoObject>({
        path: `/api/filters/${useType}`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags file-resolution-controller
     * @name GetFileResolutions
     * @request POST:/api/file-resolutions/query
     * @response `200` `ResponseDtoInternalDashboardTableResponseDtoGettingFileResolutionDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getFileResolutions: (data: FilterFileResolutionRequest, params: RequestParams = {}) =>
      this.http.request<ResponseDtoInternalDashboardTableResponseDtoGettingFileResolutionDto, ResponseDtoObject>({
        path: `/api/file-resolutions/query`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Retrieves a list of exchange rates.
     *
     * @tags exchange-rates-controller
     * @name GetExchangeRates1
     * @summary Get exchange rates
     * @request GET:/api/exchange-rates
     * @response `200` `ResponseDtoListExchangeRateDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getExchangeRates1: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoListExchangeRateDto, ResponseDtoObject>({
        path: `/api/exchange-rates`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Saves or updates an exchange rate.
     *
     * @tags exchange-rates-controller
     * @name GetExchangeRates
     * @summary Save or update exchange rate
     * @request POST:/api/exchange-rates
     * @response `200` `ResponseDtoExchangeRateDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getExchangeRates: (data: ExchangeRateDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoExchangeRateDto, ResponseDtoObject>({
        path: `/api/exchange-rates`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Generates an invoice as a PDF from the provided invoice message DTO and writes it to the HTTP response.
     *
     * @tags demo-controller
     * @name GenerateInvoiceFile
     * @summary Get Invoice
     * @request POST:/api/demo/generate-invoice-file
     * @response `200` `void` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    generateInvoiceFile: (data: InvoiceMessageDto, params: RequestParams = {}) =>
      this.http.request<void, ResponseDtoObject>({
        path: `/api/demo/generate-invoice-file`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Generates an invoice as a PDF from the provided invoice message DTO and uploads in to s3 bucket to trigger ocr processing.
     *
     * @tags demo-controller
     * @name GenerateAndUploadDemoInvoice
     * @summary Generate and Upload Invoice
     * @request POST:/api/demo/generate-and-upload-invoice-file
     * @response `200` `void` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    generateAndUploadDemoInvoice: (
      data: InvoiceMessageDto,
      query?: {
        /** @default false */
        doCreateNotification?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<void, ResponseDtoObject>({
        path: `/api/demo/generate-and-upload-invoice-file`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Generates an invoice as a PDF from the provided invoice message DTO, and uploads it to s3 bucket without trigger ocr processing then imports ocr message directly to our system.
     *
     * @tags demo-controller
     * @name GenerateAndImportInvoice
     * @summary Generate and Import Invoice
     * @request POST:/api/demo/generate-and-import-invoice-file
     * @response `200` `void` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    generateAndImportInvoice: (
      data: InvoiceMessageDto,
      query?: {
        /** @default false */
        doCreateNotification?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<void, ResponseDtoObject>({
        path: `/api/demo/generate-and-import-invoice-file`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract-controller
     * @name CreateContract
     * @request POST:/api/contracts
     * @response `200` `ResponseDtoContractDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    createContract: (data: CreatingContractReq, params: RequestParams = {}) =>
      this.http.request<ResponseDtoContractDto, ResponseDtoObject>({
        path: `/api/contracts`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract-flow-controller
     * @name CreateContractFlow
     * @request POST:/api/contract-flows
     * @response `200` `ResponseDtoContractFlowInfoViewDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    createContractFlow: (data: CreatingAndUpdatingContractFlowReq, params: RequestParams = {}) =>
      this.http.request<ResponseDtoContractFlowInfoViewDto, ResponseDtoObject>({
        path: `/api/contract-flows`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get the system configurations
     *
     * @tags admin
     * @name GetSystemConfigurations
     * @summary Get the system configurations
     * @request GET:/api/config
     * @response `200` `ResponseDtoListSystemConfigurationDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getSystemConfigurations: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoListSystemConfigurationDto, ResponseDtoObject>({
        path: `/api/config`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Set a system configuration value based on the provided type and value, overwriting old value if it already exists.
     *
     * @tags admin
     * @name SetSystemConfiguration
     * @summary Set a system configuration value
     * @request POST:/api/config
     * @response `200` `ResponseDtoBoolean` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    setSystemConfiguration: (data: SystemConfigurationDto[], params: RequestParams = {}) =>
      this.http.request<ResponseDtoBoolean, ResponseDtoObject>({
        path: `/api/config`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Migrates files to AWS S3. Accessible by SYSTEM_ADMIN.
     *
     * @tags aws-controller
     * @name Migration
     * @summary Trigger file migration to AWS S3
     * @request POST:/api/aws/s3/migration
     * @response `200` `ResponseDtoListString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    migration: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoListString, ResponseDtoObject>({
        path: `/api/aws/s3/migration`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytic-controller
     * @name FetchShipmentData
     * @request POST:/api/analytics/shipments-data
     * @response `200` `ResponseDtoShipmentSearchResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    fetchShipmentData: (data: ShipmentSearchRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoShipmentSearchResponseDto, ResponseDtoObject>({
        path: `/api/analytics/shipments-data`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytic-controller
     * @name DownloadShipmentData
     * @request POST:/api/analytics/shipments-data/download
     * @response `200` `void` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    downloadShipmentData: (
      query: {
        'file-name': string;
        'file-type': 'PDF' | 'CSV' | 'EXCEL';
      },
      data: ShipmentSearchRequestDto,
      params: RequestParams = {}
    ) =>
      this.http.request<void, ResponseDtoObject>({
        path: `/api/analytics/shipments-data/download`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytic-controller
     * @name FetchInvoiceColumns
     * @request POST:/api/analytics/invoice-data
     * @response `200` `ResponseDtoInvoiceAnalyticsSearchResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    fetchInvoiceColumns: (data: InvoiceAnalyticSearchRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoInvoiceAnalyticsSearchResponseDto, ResponseDtoObject>({
        path: `/api/analytics/invoice-data`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytic-controller
     * @name DownloadInvoiceData
     * @request POST:/api/analytics/invoice-data/download
     * @response `200` `void` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    downloadInvoiceData: (
      query: {
        'file-name': string;
        'file-type': 'PDF' | 'CSV' | 'EXCEL';
      },
      data: InvoiceAnalyticSearchRequestDto,
      params: RequestParams = {}
    ) =>
      this.http.request<void, ResponseDtoObject>({
        path: `/api/analytics/invoice-data/download`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytic-controller
     * @name FetchInvoiceChargeColumns
     * @request POST:/api/analytics/invoice-charges-data
     * @response `200` `ResponseDtoInvoiceChargeSearchResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    fetchInvoiceChargeColumns: (data: InvoiceChargeSearchRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoInvoiceChargeSearchResponseDto, ResponseDtoObject>({
        path: `/api/analytics/invoice-charges-data`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytic-controller
     * @name DownloadInvoiceChargesData
     * @request POST:/api/analytics/invoice-charges-data/download
     * @response `200` `void` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    downloadInvoiceChargesData: (
      query: {
        'file-name': string;
        'file-type': 'PDF' | 'CSV' | 'EXCEL';
      },
      data: InvoiceChargeSearchRequestDto,
      params: RequestParams = {}
    ) =>
      this.http.request<void, ResponseDtoObject>({
        path: `/api/analytics/invoice-charges-data/download`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytic-controller
     * @name SearchChartData
     * @request POST:/api/analytics/chart-data
     * @response `200` `ResponseDtoChartResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    searchChartData: (data: AnalyticSearchRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoChartResponseDto, ResponseDtoObject>({
        path: `/api/analytics/chart-data`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags exchange-rate-amounts-re-calculator
     * @name StartMigration
     * @request POST:/api/admin/recalculate-exchange-price
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    startMigration: (data: ExchangeRateAmountReCalculationRequest, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/admin/recalculate-exchange-price`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name UpdateNrcfInvoices
     * @request PATCH:/api/invoices/nrcf-update
     * @response `200` `ResponseDtoBackgroundShipmentPerformResultDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    updateNrcfInvoices: (
      query: {
        /** @format uuid */
        shippingServiceId: string;
        /** @format int64 */
        serviceProviderId: number;
        modality: string;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<ResponseDtoBackgroundShipmentPerformResultDto, ResponseDtoObject>({
        path: `/api/invoices/nrcf-update`,
        method: 'PATCH',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-file-processing-controller
     * @name UpdateMetadataOfFile
     * @request PATCH:/api/invoice-file-processing/metadata
     * @response `200` `ResponseDtoBoolean` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    updateMetadataOfFile: (data: UpdatingS3FileMetadataReq, params: RequestParams = {}) =>
      this.http.request<ResponseDtoBoolean, ResponseDtoObject>({
        path: `/api/invoice-file-processing/metadata`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags file-resolution-controller
     * @name UpdateStatus
     * @request PATCH:/api/file-resolutions/{id}/status
     * @response `200` `ResponseDtoGettingFileResolutionDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    updateStatus: (id: number, data: UpdatingResolutionStatusRequest, params: RequestParams = {}) =>
      this.http.request<ResponseDtoGettingFileResolutionDto, ResponseDtoObject>({
        path: `/api/file-resolutions/${id}/status`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags file-resolution-controller
     * @name UpdateStatus1
     * @request PATCH:/api/file-resolutions/{id}/assign/me
     * @response `200` `ResponseDtoBoolean` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    updateStatus1: (id: number, params: RequestParams = {}) =>
      this.http.request<ResponseDtoBoolean, ResponseDtoObject>({
        path: `/api/file-resolutions/${id}/assign/me`,
        method: 'PATCH',
        ...params,
      }),

    /**
     * No description
     *
     * @tags shipment-controller
     * @name GetShipment
     * @request GET:/api/shipments/shipment-invoices/{shipmentInvoiceId}
     * @response `200` `ResponseDtoShipmentInActionDetailDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getShipment: (shipmentInvoiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoShipmentInActionDetailDto, ResponseDtoObject>({
        path: `/api/shipments/shipment-invoices/${shipmentInvoiceId}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags shipment-controller
     * @name GetMatchingServicesForNoContractInvoice
     * @request GET:/api/shipments/shipment-invoices/{shipmentInvoiceId}/matching-services
     * @response `200` `ResponseDtoNoContractResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getMatchingServicesForNoContractInvoice: (shipmentInvoiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoNoContractResponseDto, ResponseDtoObject>({
        path: `/api/shipments/shipment-invoices/${shipmentInvoiceId}/matching-services`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags shipment-controller
     * @name IsShipmentAsyncProcessing
     * @request GET:/api/shipments/shipment-invoices/{shipmentInvoiceId}/async-processing
     * @response `200` `ResponseDtoBoolean` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    isShipmentAsyncProcessing: (shipmentInvoiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoBoolean, ResponseDtoObject>({
        path: `/api/shipments/shipment-invoices/${shipmentInvoiceId}/async-processing`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags shipment-controller
     * @name GetApprovalQuestionnaire
     * @request GET:/api/shipments/shipment-invoices/{shipmentInvoiceId}/approval-questionnaire
     * @response `200` `ResponseDtoMapDeviationWorkflowListDeviationQuestionDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getApprovalQuestionnaire: (shipmentInvoiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoMapDeviationWorkflowListDeviationQuestionDto, ResponseDtoObject>({
        path: `/api/shipments/shipment-invoices/${shipmentInvoiceId}/approval-questionnaire`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags shipment-invoice-deviation-controller
     * @name GetInvoiceDeviations
     * @request GET:/api/shipment-invoices/{shipmentInvoiceId}/deviations
     * @response `200` `ResponseDtoListDeviation` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getInvoiceDeviations: (shipmentInvoiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoListDeviation, ResponseDtoObject>({
        path: `/api/shipment-invoices/${shipmentInvoiceId}/deviations`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Generates a configured rate card as a PDF and writes it to the HTTP response.
     *
     * @tags report-controller
     * @name GetConfiguredRateCard
     * @summary Get Configured Rate Card
     * @request GET:/api/shipment-invoices/{shipmentInvoiceId}/configured-rate-card
     * @response `200` `void` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getConfiguredRateCard: (shipmentInvoiceId: string, params: RequestParams = {}) =>
      this.http.request<void, ResponseDtoObject>({
        path: `/api/shipment-invoices/${shipmentInvoiceId}/configured-rate-card`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Fetches a list of service provider details.
     *
     * @tags service-provider-controller
     * @name GetServiceProviderDetails
     * @summary Retrieves the details of all service providers
     * @request GET:/api/service-providers
     * @response `200` `ResponseDtoListServiceProviderDetailsDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getServiceProviderDetails: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoListServiceProviderDetailsDto, ResponseDtoObject>({
        path: `/api/service-providers`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Fetches a list of service provider overviews.
     *
     * @tags service-provider-controller
     * @name GetAllServiceProvider
     * @summary Retrieves an overview of all service providers
     * @request GET:/api/service-providers/overview
     * @response `200` `ResponseDtoListServiceProviderOverViewDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getAllServiceProvider: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoListServiceProviderOverViewDto, ResponseDtoObject>({
        path: `/api/service-providers/overview`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Retrieves the list of RouteDto objects for the given service provider ID.
     *
     * @tags routes, rate-card-management, admin, service-provider, list-zone
     * @name GetContractRoute
     * @summary Get list route by service provider ID
     * @request GET:/api/routes
     * @response `200` `ResponseDtoListRouteDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getContractRoute: (
      query: {
        /** @format int64 */
        serviceProviderId: number;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<ResponseDtoListRouteDto, ResponseDtoObject>({
        path: `/api/routes`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rate-card-controller
     * @name GetRateCards
     * @request GET:/api/rate-cards
     * @response `200` `ResponseDtoListContractChargeDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getRateCards: (
      query: {
        contractChargeUnit: 'SHIPMENT' | 'DECLARATION' | 'CONTAINER' | 'WEIGHT' | 'PERCENTAGE';
      },
      params: RequestParams = {}
    ) =>
      this.http.request<ResponseDtoListContractChargeDto, ResponseDtoObject>({
        path: `/api/rate-cards`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags notification-controller
     * @name GetNotifications
     * @request GET:/api/notifications
     * @response `200` `ResponseDtoNotificationResponse` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getNotifications: (
      query?: {
        /** @format date */
        viewDate?: string;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<ResponseDtoNotificationResponse, ResponseDtoObject>({
        path: `/api/notifications`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags metric-controller
     * @name GetAdminDashboardMetrics
     * @request GET:/api/metrics/admin-dashboard
     * @response `200` `ResponseDtoAdminDashboardMetricDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getAdminDashboardMetrics: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoAdminDashboardMetricDto, ResponseDtoObject>({
        path: `/api/metrics/admin-dashboard`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-permission-controller
     * @name GetLoggedInUserDetails
     * @request GET:/api/logged-in-user
     * @response `200` `ResponseDtoLoggedInUserDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getLoggedInUserDetails: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoLoggedInUserDto, ResponseDtoObject>({
        path: `/api/logged-in-user`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-permission-controller
     * @name GetLoggedInUserPermissions
     * @request GET:/api/logged-in-user/permissions
     * @response `200` `ResponseDtoSetPermission` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getLoggedInUserPermissions: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoSetPermission, ResponseDtoObject>({
        path: `/api/logged-in-user/permissions`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-permission-controller
     * @name GetLoggedInUserPermissionTypes
     * @request GET:/api/logged-in-user/permission-types
     * @response `200` `ResponseDtoSetPermissionType` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getLoggedInUserPermissionTypes: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoSetPermissionType, ResponseDtoObject>({
        path: `/api/logged-in-user/permission-types`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Fetches the legal entity tree for the logged-in user and maps it to a LegalEntityDto object.
     *
     * @tags legal-entity-controller
     * @name GetLegalEntityTreeOfCurrentUser
     * @summary Retrieve the legal entity tree for the current user
     * @request GET:/api/legal-entities/tree
     * @response `200` `ResponseDtoLegalEntityDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getLegalEntityTreeOfCurrentUser: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoLegalEntityDto, ResponseDtoObject>({
        path: `/api/legal-entities/tree`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Fetches a legal entity based on the provided ID or code and maps it to a LegalEntityDto object.
     *
     * @tags legal-entity-controller
     * @name GetLegalEntityByIdOrCode
     * @summary Retrieve a legal entity by ID or code
     * @request GET:/api/legal-entities/search
     * @response `200` `ResponseDtoLegalEntityDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getLegalEntityByIdOrCode: (
      query?: {
        /** @format int64 */
        id?: number;
        code?: string;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<ResponseDtoLegalEntityDto, ResponseDtoObject>({
        path: `/api/legal-entities/search`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * @description Fetches all legal entities that are marked as billed entities and maps them to LegalEntityDto objects.
     *
     * @tags legal-entity-controller
     * @name GetListBilledEntity
     * @summary Retrieve billed legal entities
     * @request GET:/api/legal-entities/billed-entities
     * @response `200` `ResponseDtoListLegalEntityDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getListBilledEntity: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoListLegalEntityDto, ResponseDtoObject>({
        path: `/api/legal-entities/billed-entities`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Fetches all legal entities from the repository and maps them to LegalEntityDto objects.
     *
     * @tags legal-entity-controller
     * @name GetAllLegalEntities
     * @summary Retrieve all legal entities
     * @request GET:/api/legal-entities/all
     * @response `200` `ResponseDtoPageLegalEntityDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getAllLegalEntities: (
      query: {
        keySearch?: string;
        /** @format int32 */
        page: number;
        /** @format int32 */
        size: number;
        sortingField?: string;
        sortingDirection?: 'ASC' | 'DESC';
      },
      params: RequestParams = {}
    ) =>
      this.http.request<ResponseDtoPageLegalEntityDto, ResponseDtoObject>({
        path: `/api/legal-entities/all`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name GetVersion
     * @request GET:/api/invoices/{invoiceId}/version
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getVersion: (invoiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/invoices/${invoiceId}/version`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name GetInvoiceShipment
     * @request GET:/api/invoices/{invoiceId}/shipment
     * @response `200` `ResponseDtoShipment` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getInvoiceShipment: (invoiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoShipment, ResponseDtoObject>({
        path: `/api/invoices/${invoiceId}/shipment`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name GetInvoiceIssueSummary
     * @request GET:/api/invoices/{invoiceId}/invoice-issue-summary
     * @response `200` `ResponseDtoInvoiceIssueSummaryDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getInvoiceIssueSummary: (invoiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoInvoiceIssueSummaryDto, ResponseDtoObject>({
        path: `/api/invoices/${invoiceId}/invoice-issue-summary`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name GetFileUrl
     * @request GET:/api/invoices/{invoiceId}/file-url
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getFileUrl: (invoiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/invoices/${invoiceId}/file-url`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name GetFileUrlAttachment
     * @request GET:/api/invoices/{invoiceId}/file-url/{attachmentId}
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getFileUrlAttachment: (invoiceId: string, attachmentId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/invoices/${invoiceId}/file-url/${attachmentId}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-activity-log-controller
     * @name GetInvoiceActivityLogs
     * @request GET:/api/invoices/{invoiceId}/activity-logs
     * @response `200` `ResponseDtoListEntityActivityLogDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getInvoiceActivityLogs: (invoiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoListEntityActivityLogDto, ResponseDtoObject>({
        path: `/api/invoices/${invoiceId}/activity-logs`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Generates an invoice report as a PDF and writes it to the HTTP response.
     *
     * @tags report-controller
     * @name GenerateInvoiceReport
     * @summary Generate Invoice Report
     * @request GET:/api/invoices/{id}/reports/download
     * @response `200` `void` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    generateInvoiceReport: (id: string, params: RequestParams = {}) =>
      this.http.request<void, ResponseDtoObject>({
        path: `/api/invoices/${id}/reports/download`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name FindNrcfInvoicesMatchingShippingService
     * @request GET:/api/invoices/match-shipping-service
     * @response `200` `ResponseDtoListInvoice` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    findNrcfInvoicesMatchingShippingService: (
      query: {
        /** @format uuid */
        shippingServiceId: string;
        /** @format int64 */
        serviceProviderId: number;
        modality: string;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<ResponseDtoListInvoice, ResponseDtoObject>({
        path: `/api/invoices/match-shipping-service`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name GetInvoiceSummary
     * @request GET:/api/invoices/invoice-summary/{invoiceId}
     * @response `200` `ResponseDtoInvoiceSummaryDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getInvoiceSummary: (invoiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoInvoiceSummaryDto, ResponseDtoObject>({
        path: `/api/invoices/invoice-summary/${invoiceId}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-dispute-controller
     * @name GetUploadCreditNoteUrl1
     * @request GET:/api/invoices/dispute/{invoiceDisputeId}/credit-note-upload-url/{filename}
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getUploadCreditNoteUrl1: (invoiceDisputeId: string, filename: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/invoices/dispute/${invoiceDisputeId}/credit-note-upload-url/${filename}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-dispute-controller
     * @name GetCreditNoteFileUrl
     * @request GET:/api/invoices/dispute/{invoiceDisputeId}/credit-note-file-url
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getCreditNoteFileUrl: (invoiceDisputeId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/invoices/dispute/${invoiceDisputeId}/credit-note-file-url`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-ocr-message-controller
     * @name GetOcrMessageByInvoiceFileProcessingId
     * @request GET:/api/invoice-ocr-message/file-processing/{invoiceFileProcessingId}
     * @response `200` `ResponseDtoInvoiceOcrMessageDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getOcrMessageByInvoiceFileProcessingId: (invoiceFileProcessingId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoInvoiceOcrMessageDto, ResponseDtoObject>({
        path: `/api/invoice-ocr-message/file-processing/${invoiceFileProcessingId}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-file-processing-controller
     * @name GetListInvoiceFileProcessing
     * @request GET:/api/invoice-file-processing
     * @response `200` `ResponseDtoListInvoiceFileProcessingStatusDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getListInvoiceFileProcessing: (
      query?: {
        invoiceIds?: string[];
        batchId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<ResponseDtoListInvoiceFileProcessingStatusDto, ResponseDtoObject>({
        path: `/api/invoice-file-processing`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-file-processing-controller
     * @name GetMetadata
     * @request GET:/api/invoice-file-processing/{invoiceFileProcessingId}/metadata
     * @response `200` `ResponseDtoMapStringString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getMetadata: (invoiceFileProcessingId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoMapStringString, ResponseDtoObject>({
        path: `/api/invoice-file-processing/${invoiceFileProcessingId}/metadata`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-file-processing-controller
     * @name GetFileUrl1
     * @request GET:/api/invoice-file-processing/{invoiceFileProcessingId}/file-url
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getFileUrl1: (invoiceFileProcessingId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/invoice-file-processing/${invoiceFileProcessingId}/file-url`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-file-processing-controller
     * @name GetAllInvoiceDataConfigId
     * @request GET:/api/invoice-file-processing/pipeline-config/all
     * @response `200` `ResponseDtoListString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getAllInvoiceDataConfigId: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoListString, ResponseDtoObject>({
        path: `/api/invoice-file-processing/pipeline-config/all`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Retrieves metadata for invoice data pipeline configurations.
     *
     * @tags Invoice Data Pipeline Config, Get
     * @name GetInvoiceDataPipelineConfigMetaData
     * @summary Get invoice data pipeline config metadata
     * @request GET:/api/invoice-data-pipeline-configs/meta-data
     * @response `200` `ResponseDtoInvoiceDataPipelineConfigMetadataDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getInvoiceDataPipelineConfigMetaData: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoInvoiceDataPipelineConfigMetadataDto, ResponseDtoObject>({
        path: `/api/invoice-data-pipeline-configs/meta-data`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags domain-meta-data-controller
     * @name GetDomainMetaData
     * @request GET:/api/domain-meta-data
     * @response `200` `ResponseDtoDomainMetaDataDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getDomainMetaData: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoDomainMetaDataDto, ResponseDtoObject>({
        path: `/api/domain-meta-data`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags domain-meta-data-controller
     * @name GetChargeDomainOptions
     * @request GET:/api/domain-meta-data/charge-domain-option
     * @response `200` `ResponseDtoListChargeDomain` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getChargeDomainOptions: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoListChargeDomain, ResponseDtoObject>({
        path: `/api/domain-meta-data/charge-domain-option`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags deviation-questionnaire-controller
     * @name GetQuestionnaire
     * @request GET:/api/deviations/{id}/questionnaire
     * @response `200` `ResponseDtoMapDeviationWorkflowListDeviationQuestionDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getQuestionnaire: (id: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoMapDeviationWorkflowListDeviationQuestionDto, ResponseDtoObject>({
        path: `/api/deviations/${id}/questionnaire`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags deviation-questionnaire-controller
     * @name CalculateSubChargeDomain
     * @request GET:/api/deviations/{id}/calculate-sub-charge-domain
     * @response `200` `ResponseDtoSetContractChargeDetailDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    calculateSubChargeDomain: (
      id: string,
      query: {
        'leg-type': 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
        'charge-domain':
          | 'COLLECTION'
          | 'CONTAINER_CLEARANCE'
          | 'CONTAINER_MAINTENANCE'
          | 'CUSTOMS_CLEARANCE'
          | 'DELIVERY'
          | 'DOCUMENTATION'
          | 'FREIGHT'
          | 'FUEL_SURCHARGE'
          | 'HANDLING_CHARGE'
          | 'INSPECTION'
          | 'OVERTIME'
          | 'PICK_UP'
          | 'PICK_UP_AND_DROP_OFF'
          | 'PORT_CHARGES'
          | 'SEAL_FEE'
          | 'SPECIAL_HANDLING'
          | 'STORAGE'
          | 'THC'
          | 'TOTAL_ORIGIN_CHARGES'
          | 'VGM_FEE'
          | 'DEFAULT_CHARGE_DOMAIN'
          | 'SCREE';
      },
      params: RequestParams = {}
    ) =>
      this.http.request<ResponseDtoSetContractChargeDetailDto, ResponseDtoObject>({
        path: `/api/deviations/${id}/calculate-sub-charge-domain`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags deviation-migration-controller
     * @name GetInvoiceMissionMatchingRateCard
     * @request GET:/api/deviation-migration/invoices/missing-rate-card
     * @response `200` `ResponseDtoGettingInvoiceMissingRateCardResponse` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getInvoiceMissionMatchingRateCard: (
      query: {
        invoiceStatus: 'FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT';
      },
      params: RequestParams = {}
    ) =>
      this.http.request<ResponseDtoGettingInvoiceMissingRateCardResponse, ResponseDtoObject>({
        path: `/api/deviation-migration/invoices/missing-rate-card`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags currency-controller
     * @name GetCurrencies
     * @request GET:/api/currencies/{useType}
     * @deprecated
     * @response `200` `ResponseDtoListCurrencyEnum` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getCurrencies: (useType: 'DASHBOARD' | 'REPORT' | 'PERFORMANCE_ASSESSMENT', params: RequestParams = {}) =>
      this.http.request<ResponseDtoListCurrencyEnum, ResponseDtoObject>({
        path: `/api/currencies/${useType}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags courier-invoice-dispute-controller
     * @name GetCourierInvoiceDisputeDetail
     * @request GET:/api/courier-invoice/dispute/{invoiceDisputeId}
     * @response `200` `ResponseDtoCourierInvoiceDisputeDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getCourierInvoiceDisputeDetail: (invoiceDisputeId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoCourierInvoiceDisputeDto, ResponseDtoObject>({
        path: `/api/courier-invoice/dispute/${invoiceDisputeId}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags courier-shipment-controller
     * @name GetInvoiceCourierShipmentDetail
     * @request GET:/api/courier-invoice-shipment-detail/{shipmentId}
     * @response `200` `ResponseDtoInvoiceCourierShipmentDetailDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getInvoiceCourierShipmentDetail: (shipmentId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoInvoiceCourierShipmentDetailDto, ResponseDtoObject>({
        path: `/api/courier-invoice-shipment-detail/${shipmentId}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract-controller
     * @name GetContractByShippingService
     * @request GET:/api/contracts/shipping-service/{shippingServiceId}
     * @response `200` `ResponseDtoListContractDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getContractByShippingService: (shippingServiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoListContractDto, ResponseDtoObject>({
        path: `/api/contracts/shipping-service/${shippingServiceId}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract-flow-controller
     * @name GetContractChargesDetailsForShippingService
     * @request GET:/api/contract-flows/{contractFlowId}/contract-detail
     * @response `200` `ResponseDtoContractChargeListsDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getContractChargesDetailsForShippingService: (contractFlowId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoContractChargeListsDto, ResponseDtoObject>({
        path: `/api/contract-flows/${contractFlowId}/contract-detail`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract-flow-controller
     * @name GetContractFlowByContractIdAndShippingServiceId
     * @request GET:/api/contract-flows/contract/{contractId}/shipping-service/{shippingServiceId}
     * @response `200` `ResponseDtoContractFlowInfoViewDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getContractFlowByContractIdAndShippingServiceId: (contractId: string, shippingServiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoContractFlowInfoViewDto, ResponseDtoObject>({
        path: `/api/contract-flows/contract/${contractId}/shipping-service/${shippingServiceId}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Checks for missing files in AWS S3. Accessible by SYSTEM_ADMIN.
     *
     * @tags aws-controller
     * @name CheckMissingFiles
     * @summary Check for missing files in AWS S3
     * @request GET:/api/aws/s3/check-missing-files
     * @response `200` `ResponseDtoListString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    checkMissingFiles: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoListString, ResponseDtoObject>({
        path: `/api/aws/s3/check-missing-files`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags exchange-rate-amounts-re-calculator
     * @name GetStatus
     * @request GET:/api/admin/recalculate-exchange-price/migration-status
     * @response `200` `string` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getStatus: (params: RequestParams = {}) =>
      this.http.request<string, ResponseDtoObject>({
        path: `/api/admin/recalculate-exchange-price/migration-status`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Deletes the specified user permission.
     *
     * @tags User, Permissions, Delete
     * @name DeleteUserPermission
     * @summary Delete user permission
     * @request DELETE:/api/users/permissions/{userPermissionId}
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    deleteUserPermission: (userPermissionId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/users/permissions/${userPermissionId}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Deletes a legal entity alias based on the provided ID.
     *
     * @tags legal-entity-controller
     * @name DeleteLegalEntityAlias
     * @summary Delete a legal entity alias
     * @request DELETE:/api/legal-entities/aliases/{id}
     * @response `200` `ResponseDtoBoolean` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    deleteLegalEntityAlias: (id: number, params: RequestParams = {}) =>
      this.http.request<ResponseDtoBoolean, ResponseDtoObject>({
        path: `/api/legal-entities/aliases/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Deletes invoices based on the provided invoice numbers, batch IDs, and file names.
     *
     * @tags invoices, admin, demo
     * @name DeleteInvoices
     * @summary Delete invoices
     * @request DELETE:/api/invoices
     * @response `200` `ResponseDtoBoolean` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    deleteInvoices: (data: DeleteInvoiceDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoBoolean, ResponseDtoObject>({
        path: `/api/invoices`,
        method: 'DELETE',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-upload-controller
     * @name DeleteAttachment
     * @request DELETE:/api/invoices/attachment/{attachmentId}
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    deleteAttachment: (attachmentId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/invoices/attachment/${attachmentId}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Deletes an invoice data pipeline config by its ID.
     *
     * @tags Invoice Data Pipeline Config, Delete
     * @name DeleteInvoiceDataPipelineConfig
     * @summary Delete an invoice data pipeline config
     * @request DELETE:/api/invoice-data-pipeline-configs/{configId}
     * @response `200` `ResponseDtoVoid` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    deleteInvoiceDataPipelineConfig: (configId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoVoid, ResponseDtoObject>({
        path: `/api/invoice-data-pipeline-configs/${configId}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags chart-report-controller
     * @name DeleteChartReport
     * @request DELETE:/api/chart-report/{chartReportId}
     * @response `200` `ResponseDtoChartReportDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    deleteChartReport: (chartReportId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoChartReportDto, ResponseDtoObject>({
        path: `/api/chart-report/${chartReportId}`,
        method: 'DELETE',
        ...params,
      }),
  };
}
