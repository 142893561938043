import React, { useState, useCallback } from 'react';
import { SettingsMain } from '../SettingsMain';
import { InvoiceChargeTable } from './InvoiceChargeTable';
import { InvoiceChargeActions } from './InvoiceChargeActions';
import { InvoiceChargeServiceProvider } from './ServiceProvider';

export const InvoiceChargeMain: React.FC = () => {
  const [serviceProviderCode, setServiceProviderCode] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const handleSearch = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  return (
    <SettingsMain title='Invoice to Charge' subtitle='Manage invoice charge domain mappings' topActions={<InvoiceChargeActions onSearch={handleSearch} />}>
      <div className='h-full flex flex-col'>
        <div className='flex flex-row h-full'>
          <InvoiceChargeServiceProvider serviceProviderCode={serviceProviderCode} setServiceProviderCode={setServiceProviderCode} />
          <div className='flex-grow overflow-auto bg-white'>
            <InvoiceChargeTable serviceProviderCode={serviceProviderCode} searchQuery={searchQuery} />
          </div>
        </div>
      </div>
    </SettingsMain>
  );
};
