import React from 'react';
import { ICustomIconProps } from '../types';

const IconUser: React.FC<ICustomIconProps> = ({ size = 20, color = 'currentColor', className = '' }) => (
  <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M9.99998 9.99996C12.3012 9.99996 14.1666 8.13448 14.1666 5.83329C14.1666 3.53211 12.3012 1.66663 9.99998 1.66663C7.69879 1.66663 5.83331 3.53211 5.83331 5.83329C5.83331 8.13448 7.69879 9.99996 9.99998 9.99996Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.1583 18.3333C17.1583 15.1083 13.95 12.5 10 12.5C6.05001 12.5 2.84167 15.1083 2.84167 18.3333'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default IconUser;
