import React from 'react';
import { SearchInput } from 'src/presentations/components/molecules/searchInput/SearchInput';

interface InvoiceChargeActionsProps {
  onSearch?: (query: string) => void;
}

export const InvoiceChargeActions: React.FC<InvoiceChargeActionsProps> = ({ onSearch }) => (
  <div className='flex justify-between items-center w-full mb-6 gap-24'>
    <SearchInput placeholder='Search by name' onSearch={onSearch} className='w-[320px]' />
  </div>
);
