import React from 'react';
import { SettingsSidebar } from './SettingsSidebar';
import { SettingsWrapperProps } from './types';

export const SettingsWrapper: React.FC<SettingsWrapperProps> = ({ children }) => (
  <div className='w-full flex-1 flex gap-60 bg-neutral-10 p-24'>
    <SettingsSidebar />
    <div className='flex-1'>{children}</div>
  </div>
);
