import React, { useMemo } from 'react';
import { MRT_ColumnDef, useMaterialReactTable } from 'material-react-table';
import { LegalEntityNameAliasDto } from 'src/generated/services/TFinancialApi';
import { CustomTable } from 'src/presentations/components/molecules/table/Table';

interface LegalEntityAliasTableProps {
  aliases: LegalEntityNameAliasDto[];
}

export const LegalEntityAliasTable: React.FC<LegalEntityAliasTableProps> = ({ aliases }) => {
  // Define columns for the Alias table
  const aliasColumns = useMemo<MRT_ColumnDef<LegalEntityNameAliasDto>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        size: 100,
      },
      {
        accessorKey: 'name',
        header: 'Alias',
        size: 300,
      },
    ],
    []
  );

  // Configure the alias table
  const aliasTable = useMaterialReactTable({
    columns: aliasColumns,
    data: aliases || [],
    enableSorting: false,
    enablePagination: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    muiTableHeadCellProps: {
      sx: {
        fontWeight: 'bold',
      },
    },
    renderEmptyRowsFallback: () => <p className='text-center py-4'>No aliases found</p>,
  });

  return (
    <div className='overflow-x-auto'>
      <CustomTable table={aliasTable} />
    </div>
  );
};
