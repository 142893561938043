import { LandingTopWidget } from '../../organisms/landing';
import { SettingsWrapper } from '../../organisms/settings/SettingsWrapper';
import { ProfileMain } from '../../organisms/settings/profile/ProfileMain';

/**
 * presentations/components/pages/SettingsPage.tsx
 */
const SettingsPage: React.FC = () => (
  <>
    <div className='h-full flex flex-col bg-white'>
      <LandingTopWidget title='Settings' hideSearchBar hideUploadButton />

      <SettingsWrapper>
        <ProfileMain />
      </SettingsWrapper>
    </div>
  </>
);

export default SettingsPage;
