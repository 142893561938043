import React from 'react';
import { ICustomIconProps } from '../types';

const IconLegal: React.FC<ICustomIconProps> = ({ size = 20, color = 'currentColor', className = '' }) => (
  <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M17.4583 12.125L12.1333 17.45C10.9667 18.6166 9.05 18.6166 7.875 17.45L2.54999 12.125C1.38332 10.9583 1.38332 9.04164 2.54999 7.86664L7.875 2.54163C9.04167 1.37496 10.9583 1.37496 12.1333 2.54163L17.4583 7.86664C18.625 9.04164 18.625 10.9583 17.4583 12.125Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default IconLegal;
