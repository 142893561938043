import React, { useMemo, useState, useCallback } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { MRT_ColumnDef, MRT_Row, MRT_TableOptions, useMaterialReactTable, MRT_SortingState } from 'material-react-table';
import { LegalEntityAddressDto } from 'src/generated/services/TFinancialApi';
import { CustomTable } from 'src/presentations/components/molecules/table/Table';
import EditIcon from '@mui/icons-material/Edit';
import { openToast, openToastError } from 'src/use-cases/toast/useToast';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useUpsertLegalEntityAddress } from 'src/use-cases/settings/useLegalEntities';

interface LegalEntityAddressTableProps {
  addresses: LegalEntityAddressDto[];
  onEditAddress?: (address: LegalEntityAddressDto) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const LegalEntityAddressTable: React.FC<LegalEntityAddressTableProps> = ({ addresses, onEditAddress }) => {
  const [validationErrors, setValidationErrors] = useState<Record<string, string | undefined>>({});
  // Default sort by ID in ascending order
  const [sorting, setSorting] = useState<MRT_SortingState>([
    {
      id: 'id',
      desc: false,
    },
  ]);
  const { mutateAsync: upsertAddress } = useUpsertLegalEntityAddress();

  // Validate address fields
  const onValidateAddress = useCallback((address: Record<string, any>) => {
    const validationResult = {
      address: !address.address || address.address === '' ? 'Required' : '',
      city: !address.city || address.city === '' ? 'Required' : '',
      state: !address.state || address.state === '' ? 'Required' : '',
      zipcode: !address.zipcode || address.zipcode === '' ? 'Required' : '',
    };

    return validationResult;
  }, []);

  // CREATE action
  const handleCreateAddress: MRT_TableOptions<LegalEntityAddressDto>['onCreatingRowSave'] = async ({ values, exitCreatingMode }) => {
    const newValidationErrors = onValidateAddress(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }

    try {
      // Get the legal entity ID from the first address in the list
      const legalEntityId = addresses.length > 0 ? addresses[0].legalEntityId : null;
      if (!legalEntityId) {
        throw new Error('Legal entity ID is required');
      }

      const reqBody: LegalEntityAddressDto = {
        legalEntityId,
        address: values.address,
        city: values.city,
        state: values.state,
        zipcode: values.zipcode,
        country: values.country || 'SG',
        type: values.type,
        active: values.active,
      };

      await upsertAddress(reqBody);
      openToast({
        toastType: 'success',
        title: 'Success',
        description: 'Address created successfully!',
      });
    } catch (error) {
      openToastError(new Error('Failed to create address'));
    } finally {
      exitCreatingMode();
    }
  };

  // UPDATE action
  const handleUpdateAddress: MRT_TableOptions<LegalEntityAddressDto>['onEditingRowSave'] = async ({ row, values, exitEditingMode }) => {
    const newValidationErrors = onValidateAddress(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }

    try {
      // Clean up values - convert '-' to null
      Object.keys(values).forEach((key) => {
        if (values[key] === '-') {
          values[key] = null;
        }
      });

      const reqBody: LegalEntityAddressDto = {
        id: row.original.id,
        legalEntityId: row.original.legalEntityId,
        address: values.address,
        city: values.city,
        state: values.state,
        zipcode: values.zipcode,
        country: values.country || row.original.country || 'SG',
        type: row?.original?.type,
        active: row?.original?.active,
      };

      await upsertAddress(reqBody);
      openToast({
        toastType: 'success',
        title: 'Success',
        description: 'Address updated successfully!',
      });
    } catch (error) {
      openToastError(new Error('Failed to update address'));
    } finally {
      exitEditingMode();
    }
  };

  // Define columns for the Address table
  const addressColumns = useMemo<MRT_ColumnDef<LegalEntityAddressDto>[]>(
    () => [
      {
        enableEditing: false,
        accessorKey: 'id',
        header: 'ID',
      },
      {
        accessorKey: 'address',
        header: 'Address',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.address,
          helperText: validationErrors?.address,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              address: undefined,
            }),
        },
      },
      {
        accessorKey: 'city',
        header: 'City',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.city,
          helperText: validationErrors?.city,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              city: undefined,
            }),
        },
      },
      {
        accessorKey: 'state',
        header: 'State',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.state,
          helperText: validationErrors?.state,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              state: undefined,
            }),
        },
      },
      {
        accessorKey: 'zipcode',
        header: 'Zipcode',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.zipcode,
          helperText: validationErrors?.zipcode,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              zipcode: undefined,
            }),
        },
      },
    ],
    [validationErrors]
  );

  // Configure the address table
  const addressTable = useMaterialReactTable({
    columns: addressColumns,
    data: addresses || [],
    enableSorting: true,
    enableColumnFilters: false,
    enableGlobalFilter: false,
    enableMultiSort: false,
    enablePagination: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    positionActionsColumn: 'first',
    enableEditing: true,
    createDisplayMode: 'row',
    editDisplayMode: 'row',
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateAddress,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleUpdateAddress,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: '#F9FAFB',
        fontWeight: 'bold',
        '& .Mui-active': {
          color: '#00A651', // Green color for active sort
        },
      },
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 80,
        muiTableHeadCellProps: {
          align: 'center',
        },
      },
    },
    renderEmptyRowsFallback: () => <p className='text-center py-4'>No addresses found</p>,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title='Edit'>
          <IconButton onClick={() => table.setEditingRow(row)} aria-label={`Edit address ${row.original.id}`}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  });

  return (
    <div className='overflow-x-auto'>
      <CustomTable table={addressTable} />
    </div>
  );
};
