import React from 'react';
import { LandingTopWidget } from '../../organisms/landing';
import { SettingsWrapper } from '../../organisms/settings/SettingsWrapper';
import { InvoiceChargeMain } from '../../organisms/settings/invoiceCharge/InvoiceChargeMain';

/**
 * presentations/components/pages/settings/InvoiceChargePage.tsx
 */
const InvoiceChargePage: React.FC = () => (
  <>
    <div className='h-full flex flex-col bg-white'>
      <LandingTopWidget title='Settings' hideSearchBar hideUploadButton />

      <SettingsWrapper>
        <InvoiceChargeMain />
      </SettingsWrapper>
    </div>
  </>
);

export default InvoiceChargePage;
