import { useState } from 'react';

/**
 * A reusable hook for handling table row validation logic
 * @param validateFn The validation function that takes an item and returns validation errors
 */
export const useTableValidation = <T extends Record<string, any>, E extends Record<string, string | undefined>>(validateFn: (item: T) => E) => {
  const [validationErrors, setValidationErrors] = useState<E>({} as E);

  const clearValidationErrors = () => setValidationErrors({} as E);

  const getTextFieldProps = (fieldName: keyof E) => ({
    required: true,
    error: !!validationErrors?.[fieldName],
    helperText: validationErrors?.[fieldName],
    onFocus: () => {
      setValidationErrors((prev) => ({
        ...prev,
        [fieldName]: undefined,
      }));
    },
  });

  const validateItem = (item: T): boolean => {
    const newValidationErrors = validateFn(item);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return false;
    }
    return true;
  };

  return {
    validationErrors,
    setValidationErrors,
    clearValidationErrors,
    getTextFieldProps,
    validateItem,
  };
};

/**
 * Validates if a field is required
 * @param value The value to check
 * @param errorMessage Optional custom error message
 */
export const validateRequired = (value: any, errorMessage = 'Required'): string => (!value || value === '' ? errorMessage : '');

/**
 * Helper to create a validation function that checks required fields
 * @param fields Array of field names to validate as required
 */
export const createRequiredValidator =
  <T extends Record<string, any>>(fields: (keyof T)[]) =>
  (item: T): Record<keyof T, string> => {
    const errors = {} as Record<keyof T, string>;

    fields.forEach((field) => {
      errors[field] = validateRequired(item[field]);
    });

    return errors;
  };
