import React from 'react';
import { CircularProgress, Box } from '@mui/material';
import { LegalEntityAddressDto } from 'src/generated/services/TFinancialApi';
import { useLegalEntityDetail } from 'src/use-cases/settings/useLegalEntities';
import { openToast } from 'src/use-cases/toast/useToast';
import { LegalEntityAliasTable } from './tables/LegalEntityAliasTable';
import { LegalEntityAddressTable } from './tables/LegalEntityAddressTable';

interface LegalEntityDetailProps {
  legalEntityId: number | null;
}

export const LegalEntityDetail: React.FC<LegalEntityDetailProps> = ({ legalEntityId }) => {
  const { data, isLoading } = useLegalEntityDetail(legalEntityId || undefined);
  const legalEntity = data?.data;

  // Handle edit address
  const handleEditAddress = (address: LegalEntityAddressDto) => {
    openToast({
      toastType: 'info',
      title: 'Edit Address',
      description: `Editing address in ${address.city} (ID: ${address.id})`,
    });
    // Here you would typically open a modal or navigate to an edit page
    // Implementation for editing address would go here
  };

  if (!legalEntityId) {
    return null;
  }

  if (isLoading) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' p={4}>
        <CircularProgress color='primary' />
      </Box>
    );
  }

  if (!legalEntity) {
    return (
      <div className='p-4'>
        <p className='text-base'>No legal entity information found.</p>
      </div>
    );
  }

  return (
    <div className='px-24 pt-36 pb-48 border border-t-0 border-neutral-20 rounded-b-16'>
      <div className='p-48 border border-neutral-20 rounded-16 bg-white flex flex-col gap-60'>
        {/* Alias Section */}
        <div className='relative'>
          <h3 className='text-20 font-bold mb-2'>Alias</h3>
          <p className='text-14 text-neutral-80 mb-24'>subheader text plachceolder</p>
          <LegalEntityAliasTable aliases={legalEntity.nameAliases || []} />
        </div>

        {/* Address Section */}
        <div className='relative'>
          <h3 className='text-20 font-bold mb-2'>Address</h3>
          <p className='text-14 text-neutral-80 mb-24'>subheader text plachceolder</p>
          <LegalEntityAddressTable addresses={legalEntity.addresses || []} onEditAddress={handleEditAddress} />
        </div>
      </div>
    </div>
  );
};
