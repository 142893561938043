import { MRT_ColumnDef, MRT_Row, MRT_TableOptions, useMaterialReactTable } from 'material-react-table';
import { Box, Tooltip, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useCallback, useEffect, useMemo, useState, FC } from 'react';
import { InvoiceChargeDomainMappingDto, UpsertInvoiceChargeDomainMappingReq } from 'src/generated/services/TFinancialApi';
import { openToast, openToastError } from 'src/use-cases/toast/useToast';
import {
  useInvoiceChargeDomainMappings,
  useUpsertInvoiceChargeDomainMapping,
  useDeleteInvoiceChargeDomainMappings,
} from 'src/use-cases/settings/useInvoiceToCharge';
import { CustomTable } from 'src/presentations/components/molecules/table/Table';
import TablePagination from 'src/presentations/components/molecules/table/Pagination';
import { useTableValidation, createRequiredValidator } from 'src/presentations/components/molecules/table/TableValidation';

interface InvoiceChargeTableProps {
  serviceProviderCode?: string;
  searchQuery?: string;
}

export const InvoiceChargeTable: FC<InvoiceChargeTableProps> = (props): JSX.Element => {
  const { serviceProviderCode, searchQuery = '' } = props;

  // Use our reusable validation hook with proper typing
  const { setValidationErrors, getTextFieldProps, validateItem } = useTableValidation<
    InvoiceChargeDomainMappingDto,
    Record<keyof InvoiceChargeDomainMappingDto, string>
  >(createRequiredValidator<InvoiceChargeDomainMappingDto>(['invoiceChargeName', 'chargeDomain', 'chargeSubDomain', 'legType', 'contractChargeUnit']));

  // Use hooks from React
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [sorting, setSorting] = useState<{ id: string; desc: boolean }[]>([]);

  // API hooks
  const { mutateAsync: upsertMapping } = useUpsertInvoiceChargeDomainMapping();
  const { mutateAsync: deleteMapping } = useDeleteInvoiceChargeDomainMappings();

  // Create pagination params for the API call
  const paginationParams = useMemo(() => {
    // Determine sort order without nested ternary
    let sortDirection;
    if (sorting.length > 0) {
      sortDirection = sorting[0].desc ? 'desc' : 'asc';
    }

    return {
      page: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
      sortBy: sorting.length > 0 ? sorting[0].id : undefined,
      sortOrder: sorting.length > 0 ? sortDirection : undefined,
    };
  }, [pagination.pageIndex, pagination.pageSize, sorting]);

  // Fetch invoice charge domain mappings data with pagination and sorting
  const { data, isLoading } = useInvoiceChargeDomainMappings(serviceProviderCode, paginationParams);

  // Filter mappings based on search query
  const filteredMappings = useMemo(() => {
    // Get all mappings from the API response
    const allMappings = data?.data || [];

    if (!searchQuery) return allMappings;

    return allMappings.filter((mapping) => {
      const searchLower = searchQuery.toLowerCase();
      // Search in multiple fields
      return (
        (mapping.invoiceChargeName?.toLowerCase().includes(searchLower) ?? false) ||
        (mapping.chargeDomain?.toLowerCase().includes(searchLower) ?? false) ||
        (mapping.chargeSubDomain?.toLowerCase().includes(searchLower) ?? false) ||
        (mapping.legType?.toLowerCase().includes(searchLower) ?? false) ||
        (mapping.contractChargeUnit?.toLowerCase().includes(searchLower) ?? false)
      );
    });
  }, [data?.data, searchQuery]);

  // Reset to page 1 when search query changes
  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: 0, // Reset to first page when search query changes
    }));
  }, [searchQuery]);

  // Use the filtered mappings for display
  const mappings = filteredMappings;

  // Use the total count from meta if available, otherwise fall back to the length of the filtered mappings
  const totalMappings = searchQuery ? filteredMappings.length : data?.meta?.totalItems || data?.data?.length || 0;

  const columns = useMemo(
    (): MRT_ColumnDef<InvoiceChargeDomainMappingDto>[] => [
      {
        enableEditing: false,
        accessorKey: 'id',
        header: 'No.',
      },
      {
        accessorKey: 'invoiceChargeName',
        header: 'Invoice Charge Name',
        muiEditTextFieldProps: {
          ...getTextFieldProps('invoiceChargeName'),
        },
      },
      {
        accessorKey: 'chargeDomain',
        header: 'Charge Domain',
        muiEditTextFieldProps: {
          ...getTextFieldProps('chargeDomain'),
        },
      },
      {
        accessorKey: 'chargeSubDomain',
        header: 'Charge Sub Domain',
        muiEditTextFieldProps: {
          ...getTextFieldProps('chargeSubDomain'),
        },
      },
      {
        accessorKey: 'legType',
        header: 'Leg Type',
        muiEditTextFieldProps: {
          ...getTextFieldProps('legType'),
        },
      },
      {
        accessorKey: 'contractChargeUnit',
        header: 'Charge Unit',
        muiEditTextFieldProps: {
          ...getTextFieldProps('contractChargeUnit'),
        },
      },
    ],
    [getTextFieldProps]
  );

  // No need for a wrapper, validateItem already handles setting errors internally
  const validateMapping = useCallback(
    (mapping: Record<string, any>): boolean =>
      // validateItem returns true if valid, false if invalid
      validateItem(mapping as InvoiceChargeDomainMappingDto),
    [validateItem]
  );

  // CREATE action
  const handleCreateMapping: MRT_TableOptions<InvoiceChargeDomainMappingDto>['onCreatingRowSave'] = ({ values, exitCreatingMode }) => {
    // For create validation
    const isValid = validateMapping(values);

    if (!isValid) {
      // Validation errors are already set internally by validateItem
      return;
    }

    const reqBody: UpsertInvoiceChargeDomainMappingReq = {
      invoiceChargeName: values.invoiceChargeName,
      chargeDomain: values.chargeDomain,
      chargeSubDomain: values.chargeSubDomain,
      legType: values.legType,
      contractChargeUnit: values.contractChargeUnit,
      serviceProviderId: Number(values.serviceProviderId) || 0,
    };

    upsertMapping(reqBody)
      .then(() => {
        openToast({
          toastType: 'success',
          title: 'Success',
          description: 'Invoice charge mapping created!',
        });
      })
      .catch(() => {
        openToastError(new Error('Invoice charge mapping creation failed'));
      })
      .finally(() => {
        exitCreatingMode();
      });
  };

  // UPDATE action
  const handleUpdateMapping: MRT_TableOptions<InvoiceChargeDomainMappingDto>['onEditingRowSave'] = async ({ row, values, exitEditingMode }) => {
    // For create validation
    const isValid = validateMapping(values);

    if (!isValid) {
      // Validation errors are already set internally by validateItem
      return;
    }

    try {
      const reqBody: UpsertInvoiceChargeDomainMappingReq = {
        id: row.original?.id,
        invoiceChargeName: values.invoiceChargeName,
        chargeDomain: values.chargeDomain,
        chargeSubDomain: values.chargeSubDomain,
        legType: values.legType,
        contractChargeUnit: values.contractChargeUnit,
        serviceProviderId: row.original?.serviceProviderId || 0,
      };
      await upsertMapping(reqBody);
      openToast({
        toastType: 'success',
        title: 'Success',
        description: 'Invoice charge mapping updated!',
      });
    } catch (error) {
      openToastError(new Error('Invoice charge mapping update failed'));
    } finally {
      exitEditingMode();
    }
  };

  // Delete action
  const openDeleteConfirmModal = async (row: MRT_Row<InvoiceChargeDomainMappingDto>) => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure to delete this charge domain?')) {
      try {
        await deleteMapping({
          ids: [row.original.id],
          serviceProviderCode,
        });

        openToast({
          toastType: 'success',
          title: 'Success',
          description: 'Rate card charge deleted!',
        });
      } catch (error) {
        openToastError(new Error('Rate card charge delete failed'));
      }
    }
  };

  const table = useMaterialReactTable({
    enableSorting: true,
    enablePinning: false,
    enableRowDragging: false,
    enableColumnActions: false,
    enablePagination: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableStickyHeader: true,
    muiTableContainerProps: { sx: { height: '100%' } },
    muiTablePaperProps: { sx: { height: '100%' } },
    enableEditing: true,
    createDisplayMode: 'row',
    editDisplayMode: 'row',
    onCreatingRowCancel: () => setValidationErrors({} as Record<keyof InvoiceChargeDomainMappingDto, string>),
    onCreatingRowSave: handleCreateMapping,
    onEditingRowCancel: () => setValidationErrors({} as Record<keyof InvoiceChargeDomainMappingDto, string>),
    onEditingRowSave: handleUpdateMapping,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    positionActionsColumn: 'first',
    data: mappings as InvoiceChargeDomainMappingDto[],
    columns,
    state: {
      isLoading,
      pagination,
      sorting,
    },
    manualPagination: true,
    manualSorting: true,
    rowCount: totalMappings,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title='Edit'>
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title='Delete'>
          <IconButton color='error' onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    muiTableBodyRowProps: () => ({
      sx: {
        cursor: 'pointer',
      },
    }),
  });

  const params = useMemo(
    () => ({
      page: pagination.pageIndex + 1,
    }),
    [pagination.pageIndex]
  );

  const onChangePage = useCallback((page: number) => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: page - 1,
    }));
  }, []);

  // Calculate pagination display values
  const startItem = totalMappings > 0 ? pagination.pageIndex * pagination.pageSize + 1 : 0;
  const endItem = Math.min((pagination.pageIndex + 1) * pagination.pageSize, totalMappings);

  return (
    <div className='w-full h-[calc(100%_-_65px)]'>
      <CustomTable table={table} tableContainerProps={{ sx: { height: '100%' } }} />
      <div className='p-16 bg-neutral-10 flex items-center justify-between border border-t-0 border-neutral-20'>
        <div>
          Showing {startItem}-{endItem} of {totalMappings}
        </div>
        <TablePagination pageSize={pagination.pageSize} total={totalMappings} onChangePage={onChangePage} params={params} />
      </div>
    </div>
  );
};
