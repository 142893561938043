import React from 'react';
import { Link, useLocation } from 'react-router-dom';

// Import icon components
import { IconUser, IconLegal, IconExchange, IconInvoice } from '../../atom/icons/settings';

// Import types
import { MenuItem } from './types';

// Define menu items array
const menuItems: MenuItem[] = [
  {
    label: 'User Profile',
    path: '/settings',
    icon: 'user',
  },
  {
    label: 'Legal Entities',
    path: '/settings/legal-entities',
    icon: 'legal',
  },
  {
    label: 'Exchange Rate',
    path: '/settings/exchange-rate',
    icon: 'exchange',
  },
  {
    label: 'Invoice to Charge',
    path: '/settings/invoice-charge',
    icon: 'invoice',
  },
];

export const SettingsSidebar: React.FC = () => {
  const location = useLocation();

  // Function to determine if a menu item is active based on the current path
  const isMenuItemActive = (itemPath: string, currentPath: string): boolean => {
    // For the root settings path, only be active if exactly at /settings
    if (itemPath === '/settings') {
      return currentPath === '/settings';
    }
    // For other paths, be active if the current path exactly matches the item path
    return currentPath === itemPath;
  };

  return (
    <div className='relative w-[250px] flex flex-col gap-10 bg-gray-100 p-4 rounded-lg'>
      {menuItems.map((item) => {
        const isActive = isMenuItemActive(item.path, location.pathname);

        return (
          <Link
            key={`setting-page-${item.path}`}
            to={item.path}
            className={`flex items-center gap-3 py-10 px-12 rounded-lg transition-all ${
              isActive ? 'bg-[#EBECF0] text-neutral-90' : 'hover:bg-[#EBECF0] text-neutral-60'
            }`}
          >
            {/* Icon */}
            <div className='w-24 h-24 flex items-center justify-center gap-8'>
              {item.icon === 'user' && <IconUser size={24} />}
              {item.icon === 'legal' && <IconLegal size={24} />}
              {item.icon === 'exchange' && <IconExchange size={24} />}
              {item.icon === 'invoice' && <IconInvoice size={24} />}
            </div>
            <div className={`flex-1 text-lg ${isActive ? 'font-medium' : ''}`}>{item.label}</div>
          </Link>
        );
      })}
    </div>
  );
};
