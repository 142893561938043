import { useParams } from 'react-router-dom';
import { useGetConfiguredRateCard, useGetShipment } from 'src/use-cases/invoice/useInvoices';
import { PageLoader } from 'src/presentations/components/molecules/pageLoader/PageLoader';
import PdfViewerComponent from 'src/presentations/components/molecules/pdfViewer/PdfViewerComponent';
import PdfErrorComponent from 'src/presentations/components/molecules/pdfViewer/PdfErrorComponent';
import { usePdfProcessor } from 'src/hooks/usePdfProcessor';

const PdfViewer = () => {
  const { shipmentInvoiceId } = useParams();
  const { data: shipment, isLoading: isLoadingShipment } = useGetShipment(shipmentInvoiceId);
  const { refetch: refetchConfiguredRateCard } = useGetConfiguredRateCard(shipmentInvoiceId);

  const { pdfUrl, error, isLoading } = usePdfProcessor({
    id: shipmentInvoiceId,
    idName: 'Shipment invoice ID',
    fetchFunction: refetchConfiguredRateCard,
    errorPrefix: 'configured rate card',
    pdfTitle: 'Configured Rate Card',
    processData: true,
  });

  if (error) {
    return <PdfErrorComponent errorMessage={error} />;
  }

  if (isLoading || isLoadingShipment || !pdfUrl) {
    return <PageLoader />;
  }

  return (
    <PdfViewerComponent
      pdfUrl={pdfUrl}
      onError={(errorMessage) => console.error(errorMessage)}
      downloadFileName={`configured-ratecard-${shipment?.shipmentDetail?.shipmentNumber}`}
    />
  );
};

export default PdfViewer;
