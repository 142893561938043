import React from 'react';
import { SettingsMainProps } from './types';

export const SettingsMain: React.FC<SettingsMainProps> = ({ title, subtitle, topActions, children }) => (
  <div className='flex-1 relative h-full flex flex-col'>
    {/* Header section with title, subtitle, and actions */}
    {(title || subtitle || topActions) && (
      <div className='p-24 rounded-t-16 border border-neutral-20 border-b-0 bg-white'>
        <div className='flex justify-between items-center'>
          <div>
            {title && <h2 className='text-2xl font-semibold text-neutral-90'>{title}</h2>}
            {subtitle && <p className='mt-2 text-neutral-60'>{subtitle}</p>}
          </div>
          {topActions && <div className='flex items-center gap-12'>{topActions}</div>}
        </div>
      </div>
    )}

    {/* Main content */}
    <div className='relative flex-1 overflow-y-auto'>{children}</div>
  </div>
);
