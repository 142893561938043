import React, { useState, useRef } from 'react';
import { SettingsMain } from '../SettingsMain';
import { LegalEntitiesActions } from './LegalEntitiesActions';
import { LegalEntitiesTable } from './LegalEntitiesTable';
import { LegalEntityDetail } from './LegalEntityDetail';

export const LegalEntitiesMain: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedLegalEntityId, setSelectedLegalEntityId] = useState<number | null>(null);
  // Ref to access table's resetPage function
  const tableRef = useRef<{ resetPage: () => void }>({ resetPage: () => {} });

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    // Reset to page 0 whenever a search is performed
    tableRef.current.resetPage();
  };

  return (
    <SettingsMain title='Legal Entities' topActions={<LegalEntitiesActions onSearch={handleSearch} />}>
      <LegalEntitiesTable
        ref={tableRef}
        searchQuery={searchQuery}
        selectedLegalEntityId={selectedLegalEntityId}
        setSelectedLegalEntityId={setSelectedLegalEntityId}
      />
      {selectedLegalEntityId && <LegalEntityDetail legalEntityId={selectedLegalEntityId} />}
    </SettingsMain>
  );
};
