import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AnalyticSearchRequestDto } from 'src/generated/services/TFinancialApi';
import { Area, Bar, Line, YAxis } from 'recharts';
import { Box, CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormContainer } from 'react-hook-form-mui';
import { createFileName, useScreenshot } from 'use-react-screenshot';

import IconChartLine from 'src/presentations/components/atom/icons/ChartLine';
import IconChartBar from 'src/presentations/components/atom/icons/ChartBar';
import { ChipChart } from 'src/presentations/components/atom/chip/ChipChart';
import { beautifyEnum } from 'src/utils/utility';
import { LandingTopWidget } from '../landing';
import { GeneralOverview } from './generalOverview';
import { colorMapper, filterChartGranularityMapper, formatNumberChart, keyMetricsMapper } from './analyticsMapper';
import { sortAggregatedData, useChart, useFilters, useKeyMetrics } from './useAnalytics';
import { AnalyticSidebar } from './sidebar/Sidebar';
import { ChartViewType, ComponentOptionDto } from './sidebar/types';
import MyComposedChart from './chart/CustomComposedChart';
import { GranularityTab } from './GranularityTab';
import { ICardAnalyticItemProps } from '../../molecules/card/CardSelector';
import { AnalyticsHeader } from './filter/AnalyticsHeader';
import { CardId } from './generalOverview/types';
import { AnalyticsTable } from './table/AnalyticsTable';
import { IAnalyticMainProps } from './types';
import { safeFormatDateString } from '../../../../utils/date.ts';

const granularityTab: Record<'TREND' | 'SERVICE_PROVIDER' | 'TRADE_LANE', Array<AnalyticSearchRequestDto['granularity']>> = {
  TREND: ['YEARLY', 'QUARTERLY', 'MONTHLY', 'WEEKLY', 'DAILY'],
  SERVICE_PROVIDER: ['SERVICE_PROVIDER_CODE', 'MODALITY', 'VEHICLE_ID'],
  TRADE_LANE: ['COUNTRY', 'CITY_HUB', 'COMPANY'],
};

export const AnalyticsContainerMain: FC<IAnalyticMainProps> = (props) => {
  const { hashedFilter, setHashedFilter } = props;

  const [selectedChartView, setSelectedChartView] = useState<ChartViewType>(hashedFilter ? hashedFilter.filters?.selectedChartView : ChartViewType.TREND);
  const formContext = useForm({
    defaultValues: { ...hashedFilter.filters.formValues, name: hashedFilter.name, description: hashedFilter.description },
  });

  const { watch, setValue } = formContext;

  const { name, description, ...formValues } = watch();
  const { data: chartData, isLoading: isChartLoading, isError: isChartError } = useChart(formValues);

  const { data: chartFilters } = useFilters('REPORT', formValues);

  const { data: keyMetrics, isLoading } = useKeyMetrics('REPORT', formValues);
  const items = useMemo(() => keyMetricsMapper(keyMetrics), [keyMetrics]);

  const [selectedCards, setSelectedCards] = useState<ICardAnalyticItemProps[]>(
    [CardId.NO_SHIPMENTS, CardId.TOTAL_AMOUNT, CardId.CHARGABLE_WEIGHT].map((defaultItemId) => items.find((item) => item.id === defaultItemId))
  );

  const contentRef = useRef<HTMLDivElement>();
  const [, takeScreenShot] = useScreenshot({
    type: 'image/jpeg',
    quality: 1.0,
  });

  useEffect(() => {
    setSelectedCards((currVal) => currVal.map((item) => items.find((v) => v.id === item.id)));
  }, [items]);

  useEffect(() => {
    switch (selectedChartView) {
      case ChartViewType.TREND:
        setValue('granularity', 'DAILY');
        break;
      case ChartViewType.TRADE_LANE:
        setValue('granularity', 'COUNTRY');
        break;
      case ChartViewType.SERVICE_PROVIDER:
        setValue('granularity', 'SERVICE_PROVIDER_CODE');
        break;
      default:
        setValue('granularity', 'DAILY');
    }
  }, [selectedChartView, setValue]);

  const handleChangeChartType = (value: ChartViewType) => {
    switch (value) {
      case ChartViewType.SERVICE_PROVIDER: {
        setSelectedChartView(value);
        break;
      }
      case ChartViewType.CORRELATIONS:
      case ChartViewType.TRADE_LANE:
      case ChartViewType.BILLING_REFERENCE:
      case ChartViewType.TREND:
      default: {
        setSelectedChartView(value);
      }
    }
  };

  const updateFiltersOnChartClick = useCallback(
    (payload: any) => {
      const filterField = filterChartGranularityMapper(formValues.granularity) as keyof AnalyticSearchRequestDto;
      setValue(filterField, [payload?.aggregatedUnit]);
    },
    [formValues.granularity, setValue]
  );

  const onScreenShot = () => {
    takeScreenShot(contentRef.current).then((image: string, imageProps: Record<string, string>) => {
      const extension = imageProps?.extension || 'jpg';
      const fileName = `preview-${name}`;
      const a = document.createElement('a');
      a.href = image;
      a.download = createFileName(extension, fileName);
      a.click();
    });
  };

  const ListMetrics = useCallback(
    ({ options }) => (
      <div className='flex items-center gap-8 flex-wrap'>
        {options.slice(0, 3).map((metric) => (
          <ChipChart
            key={`chip-metric-${metric?.name}`}
            label={metric?.name}
            icon={
              <div className='w-22 h-22 rounded-5 flex items-center justify-center' style={{ backgroundColor: metric?.color }}>
                {metric?.type === 'line' ? <IconChartLine color='#ffffff' /> : <IconChartBar color='#ffffff' />}
              </div>
            }
          />
        ))}
        {options.length > 3 && <span className='text-14 font-medium text-neutral-60'>...</span>}
      </div>
    ),
    []
  );

  const Chart = useCallback(() => {
    const metricOptions = selectedCards?.map(
      (v, i): ComponentOptionDto => ({
        dataKey: v.id,
        color: colorMapper(v.theme),
        name: v.title,
        type: v.chartType,
        unit: v.unit,
        orientation: new Set(selectedCards.slice(0, i).map((v) => v.unit)).size > 1 ? 'right' : 'left',
        mirror: new Set(selectedCards.slice(0, i).map((v) => v.unit)).size === 1,
      })
    );

    const tooltipTitle = selectedChartView === ChartViewType.TREND ? `Time` : beautifyEnum(formValues?.granularity);

    return (
      <div className='flex flex-col items-center'>
        <div className='w-full flex items-center gap-14 h-[4rem] border-b border-neutral-20 px-20'>
          <div className='flex items-center gap-8 text-14 font-medium text-neutral-90'>
            <span className='capitalize text-neutral-60'>
              {selectedChartView === ChartViewType.TREND ? `${beautifyEnum(formValues?.granularity)} Trend of` : 'Distribution of'}
            </span>
            <ListMetrics options={metricOptions} />
            {selectedChartView !== ChartViewType.TREND && <span className='capitalize text-neutral-60'>by {beautifyEnum(formValues?.granularity)}</span>}
            <div className='flex items-center gap-8 text-neutral-60'>
              from
              <span>{chartData?.dateRange?.from}</span>
              to
              <span>{chartData?.dateRange?.to}</span>
            </div>
          </div>
        </div>

        <MyComposedChart
          data={selectedChartView !== ChartViewType.TREND ? sortAggregatedData(chartData?.aggregated, selectedCards?.map((v) => v.id)) : chartData?.aggregated}
          xAxisKey='aggregatedUnit'
          formatter={(() => {
            if (formValues?.granularity === 'DAILY') {
              return safeFormatDateString;
            }
            if (formValues?.granularity === 'SERVICE_PROVIDER_CODE') {
              return beautifyEnum;
            }
            return undefined;
          })()}
          tooltipTitle={tooltipTitle}
        >
          {metricOptions
            .slice()
            ?.sort((a, b) => {
              if (a.type === b.type) {
                return 0;
              }
              if (a.type === 'bar') {
                return -1;
              }
              if (b.type === 'bar') {
                return 1;
              }
              return 0;
            })
            .map((option) => {
              const mainComponent = () => {
                switch (option.type) {
                  case 'line':
                    return (
                      <Line
                        connectNulls
                        name={option.name}
                        yAxisId={option.unit}
                        dataKey={option.dataKey}
                        type='linear'
                        stroke={option.color}
                        strokeWidth={3}
                        dot={false}
                        isAnimationActive={false}
                        style={{
                          filter: `drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.25))`,
                        }}
                        activeDot={{ onClick: (props, event) => updateFiltersOnChartClick((event as any).payload) }}
                      />
                    );
                  case 'area':
                    return (
                      <Area
                        connectNulls
                        name={option.name}
                        yAxisId={option.unit}
                        dataKey={option.dataKey}
                        stroke={option.color}
                        fill={option.color}
                        isAnimationActive={false}
                        activeDot={{ onClick: (props, event) => updateFiltersOnChartClick((event as any).payload) }}
                      />
                    );
                  case 'bar':
                  default:
                    return (
                      <Bar
                        unit={option.unit}
                        yAxisId={option.unit}
                        dataKey={option.dataKey}
                        barSize={40}
                        fill={option.color}
                        radius={[5, 5, 0, 0]}
                        isAnimationActive={false}
                        onClick={(event) => updateFiltersOnChartClick(event.payload)}
                      />
                    );
                }
              };

              return (
                <React.Fragment key={option.dataKey}>
                  {mainComponent()}
                  <YAxis
                    name={option.name}
                    label={{ value: option.unit, angle: 0, position: 'top', offset: 20 }}
                    yAxisId={option.unit}
                    orientation={option.orientation}
                    mirror={option.mirror}
                    tickFormatter={(value) => formatNumberChart(value)}
                  />
                </React.Fragment>
              );
            })}
        </MyComposedChart>
      </div>
    );
  }, [
    ListMetrics,
    chartData?.aggregated,
    chartData?.dateRange?.from,
    chartData?.dateRange?.to,
    formValues?.granularity,
    selectedCards,
    selectedChartView,
    updateFiltersOnChartClick,
  ]);

  return (
    <FormContainer formContext={formContext}>
      <div id='my-superset-container' className='w-full h-full bg-white relative'>
        <LandingTopWidget title='Reports & Analytics' />
        <div className='bg-neutral-10 h-full relative'>
          <AnalyticsHeader setDefaultFilters={setHashedFilter} data={chartFilters} selectedChartView={selectedChartView} onScreenShot={onScreenShot} />
          {/* <iframe title='Analytics' src={decodeURIComponent(data.analyticsIframe)} width='100%' height='100%' /> */}
          <div ref={contentRef} className='pt-20'>
            <Box className='bg-neutral-10' sx={{ width: '100%' }}>
              <GeneralOverview selectedCards={selectedCards} setSelectedCards={setSelectedCards} items={items} isLoading={isLoading} />

              <div className='w-full pt-24 px-24 pb-40'>
                <div className='flex border border-neutral-20 rounded-16 shadow-[0_10px_24px_0_rgba(0,0,0,0.1)]'>
                  <AnalyticSidebar selected={selectedChartView} onChange={handleChangeChartType} />
                  <div className='flex-1 bg-white rounded-r-16  border-neutral-20 border-l-1'>
                    {isChartError || isChartLoading ? (
                      <div className='p-24 flex justify-center'>
                        <div className='w-[1000px] h-[700px] flex justify-center items-center bg-blue_grey-10 rounded-xl'>
                          <CircularProgress color='primary' />
                        </div>
                      </div>
                    ) : (
                      <Chart />
                    )}
                    <div className='py-20 px-[8rem] border-t border-neutral-20 flex justify-center'>
                      {selectedChartView === ChartViewType.TREND && <GranularityTab granularityTab={granularityTab.TREND} />}
                      {selectedChartView === ChartViewType.SERVICE_PROVIDER && <GranularityTab granularityTab={granularityTab.SERVICE_PROVIDER} />}
                      {selectedChartView === ChartViewType.TRADE_LANE && <GranularityTab granularityTab={granularityTab.TRADE_LANE} />}
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full border-t border-neutral-20 '>
                <AnalyticsTable chartData={chartData} />
              </div>
            </Box>
          </div>
        </div>
      </div>
    </FormContainer>
  );
};
