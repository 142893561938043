import React from 'react';
import { Navigate } from 'react-router-dom';
import BgImage from 'src/assets/images/auth-bg.jpg';
import FormBgImage from 'src/assets/images/auth-form-bg.png';
import Logo from 'src/assets/images/logo-main.svg';
import { Authenticator, useAuthenticator, translations } from '@aws-amplify/ui-react';

import { makeStyles } from 'tss-react/mui';
import IconUserTag from 'src/assets/images/icons/user-tag.svg';
import IconLock from 'src/assets/images/icons/lock.svg';

import { I18n } from 'aws-amplify/utils';

import '@aws-amplify/ui-react/styles.css';
import { signInWithRedirect } from 'aws-amplify/auth';

const formFields = {
  signIn: {
    username: {
      placeholder: 'Input Email',
      label: 'Email Address',
    },
    password: {
      placeholder: 'Input Password',
      label: 'Password',
    },
  },
  forgotPassword: {
    username: {
      placeholder: 'Input Email',
      label: 'Email Address',
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: 'Verification Code',
      label: 'Input Verification Code',
    },
    password: {
      placeholder: 'Input Password',
      label: 'Password',
    },
    confirm_password: {
      placeholder: 'Reinput Password',
    },
  },
};
const useStyles = makeStyles()((theme) => ({
  root: {
    '& [data-amplify-authenticator]': {
      fontWeight: '600',
    },
    '& .amplify-alert--error': {
      backgroundColor: '#FCE5E9',
      color: '#E72547',
    },
    '& [data-amplify-router]': {
      borderRadius: '1.875rem',
      boxShadow: '0 0.625rem 1.5rem 0 rgba(0,0,0,0.05)',
      borderColor: '#DFDFDF',
    },
    '& .amplify-button[type="submit"]': {
      backgroundColor: theme.palette.primary.main,
      marginTop: '15px',
      border: 'none',
      borderRadius: '0.4375rem',
      color: '#ffff',
      fontWeight: '600!important',
      boxShadow: 'none',
    },
    '& .amplify-textfield': {
      marginBottom: '0.8125rem',
    },
    '& .amplify-input': {
      backgroundColor: '#F5F6FA',
      borderColor: '#BFBFBF',
      marginTop: '0',
      borderRadius: '10px',
      '&:focus': {
        boxShadow: 'none',
      },
    },
    '& .amplify-field__show-password': {
      backgroundColor: '#F5F6FA',
      borderRadius: '0 10px 10px 0',
      borderColor: '#BFBFBF',
      color: '#9F9F9F',
      fontSize: '20px',
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    '& .amplify-passwordfield .amplify-input': {
      borderRight: 'none',
    },
    '& .amplify-passwordfield .amplify-field__show-password': {
      borderLeft: 'none',
    },
    '& .amplify-alert': {
      borderRadius: '8px',
    },
    '& .amplify-alert .amplify-alert__dismiss': {
      padding: 0,
    },
    '.amplify-button--small': {
      fontSize: '1rem',
      color: '#404040',
      border: 'none',
      boxShadow: 'none',
    },
    '.amplify-field__show-password': {
      boxShadow: 'none',
    },
    '[data-amplify-footer]': {
      paddingBottom: '2.25rem',
    },
  },
}));

I18n.putVocabularies(translations);
I18n.setLanguage('en');
I18n.putVocabulariesForLanguage('en', {
  'Sign in': 'Login',
  'Send code': 'Reset Password',
  'Back to Sign In': 'Back to Login',
});

const LoginPage: React.FC = () => {
  const { authStatus, toForgotPassword } = useAuthenticator((context) => [context.authStatus]);

  const { classes } = useStyles();

  if (authStatus === 'authenticated') {
    return <Navigate to='/' replace />;
  }

  return (
    <div className='w-screen h-screen flex items-center bg-neutral-10'>
      <div className='w-1/2 h-full relative'>
        <div className='absolute inset-0'>
          <img src={BgImage} alt='' className='w-full h-full object-cover object-center' />
        </div>
        <div className='relative z-10 pt-46 pl-48'>
          <img src={Logo} alt='Tetrixx' className='h-48' />
        </div>
      </div>
      <div className='w-1/2 h-full relative'>
        <div className='w-full h-full relative z-10 flex flex-col items-center justify-center'>
          <div className='w-full flex-1 flex items-center justify-center'>
            <Authenticator
              hideSignUp
              className={classes.root}
              formFields={formFields}
              components={{
                SignIn: {
                  Header() {
                    return (
                      <div className='px-30 pt-36'>
                        <div className='pb-24 border-b border-neutral-40 flex flex-col items-center'>
                          <div className='w-80 h-80 rounded-full mb-8 bg-[#F0F9F2] flex items-center justify-center'>
                            <img src={IconUserTag} alt='' />
                          </div>
                          <h2 className='text-24 font-bold mb-4'>Login to your account</h2>
                          <p className='text-14 text-neutral-70'>Enter your details to login</p>
                        </div>
                      </div>
                    );
                  },
                  Footer() {
                    return (
                      <div className='flex items-center px-32 pb-30'>
                        <button
                          type='button'
                          className='text-14 underline text-primary'
                          onClick={async () => {
                            try {
                              await signInWithRedirect({ provider: { custom: 'OktaOIDP' } });
                            } catch (error) {
                              // Handle any errors that may occur during the SSO process
                              console.error('Error during federated sign-in:', error);
                            }
                          }}
                        >
                          Login via SSO
                        </button>

                        <div className='ml-auto pl-16'>
                          <button type='button' className='text-14 underline' onClick={() => toForgotPassword()}>
                            Forgot password?
                          </button>
                        </div>
                      </div>
                    );
                  },
                },
                ForgotPassword: {
                  Header() {
                    return (
                      <div className='px-30 pt-16'>
                        <div className='pb-24 border-b border-neutral-40 flex flex-col items-center'>
                          <div className='w-80 h-80 rounded-full mb-8 bg-[#F0F9F2] flex items-center justify-center'>
                            <img src={IconLock} alt='' />
                          </div>
                          <h2 className='text-24 font-bold mb-4'>Reset Password</h2>
                          <p className='text-14 text-neutral-70'>We’ll send you the reset password link to your email</p>
                        </div>
                      </div>
                    );
                  },
                },
                ConfirmResetPassword: {
                  Header() {
                    return (
                      <div className='px-10 pt-16'>
                        <div className='pb-24 border-b border-neutral-40 flex flex-col items-center'>
                          <div className='w-80 h-80 rounded-full mb-8 bg-[#F0F9F2] flex items-center justify-center'>
                            <img src={IconLock} alt='' />
                          </div>
                          <h2 className='text-24 font-bold mb-4'>Reset Password</h2>
                          <p className='text-14 text-neutral-70 text-center'>Please check your email and input the verification code below</p>
                        </div>
                      </div>
                    );
                  },
                },
              }}
            />
          </div>

          <div className='py-18 border-t border-neutral-40 w-full'>
            <p className='text-neutral-60 text-14 text-center'>&copy; Tetrixx {new Date().getFullYear()}. All Rights Reserved</p>
          </div>
        </div>

        <div className='absolute inset-0 flex items-center justify-center'>
          <img src={FormBgImage} alt='' className='w-[94%] h-[94%] object-cover object-center' />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
