import React from 'react';
import { ICustomIconProps } from '../types';

const IconInvoice: React.FC<ICustomIconProps> = ({ size = 20, color = 'currentColor', className = '' }) => (
  <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M5.60835 16.4166C6.29169 15.6833 7.33335 15.7416 7.93335 16.5416L8.77502 17.6666C9.45002 18.5583 10.5417 18.5583 11.2167 17.6666L12.0584 16.5416C12.6584 15.7416 13.7 15.6833 14.3834 16.4166C15.8667 18 17.075 17.475 17.075 15.2583V5.86663C17.0834 2.50829 16.3 1.66663 13.15 1.66663H6.85002C3.70002 1.66663 2.91669 2.50829 2.91669 5.86663V15.25C2.91669 17.475 4.13335 17.9916 5.60835 16.4166Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M6.74675 9.16667H6.75423' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M9.08203 9.16663H13.6654' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M6.74675 5.83329H6.75423' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M9.08203 5.83337H13.6654' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

export default IconInvoice;
