/* eslint-disable react-refresh/only-export-components */
import { lazy } from 'react';
import { createRoutesFromElements, Route } from 'react-router-dom';

import LayoutMain from 'src/presentations/components/templates/Main';
import LayoutRoot from 'src/presentations/components/templates/Root';
import LoginPage from 'src/presentations/components/pages/loginPage';
import Analytics from 'src/presentations/components/pages/analytics';
import LandingPage from 'src/presentations/components/pages/landing';
import { PerformanceAnalysis } from 'src/presentations/components/pages/performance/PerformanceAnalysis';
import PdfViewer from 'src/presentations/components/pages/pdfViewer/PdfViewer';
import InvoiceReportViewer from 'src/presentations/components/pages/pdfViewer/InvoiceReportViewer';

import NotFound from 'src/containers/NotFound';
import Forbidden from 'src/containers/Forbidden';
import UnderConstructionPage from 'src/presentations/components/pages/underConstruction/UnderConstructionPage';
import { PermissionType } from 'src/types/permission';
import SettingsPage from 'src/presentations/components/pages/settings/SettingsPage.tsx';
import LegalEntitiesPage from 'src/presentations/components/pages/settings/LegalEntitiesPage';
import ExchangeRatePage from 'src/presentations/components/pages/settings/ExchangeRatePage';
import InvoiceChargePage from 'src/presentations/components/pages/settings/InvoiceChargePage';
import RequireAuth from './RequireAuth';
import RequirePermission from './RequirePermission';
import ModelConfiguration from '../../containers/modelConfiguration/ModelConfiguration.tsx';

const Dashboard = lazy(() => import('src/containers/Dashboard'));
const InternalDashboardPage = lazy(() => import('src/presentations/components/pages/internalDashboard/InternalDashboardPage'));
const ActionDetailsPage = lazy(() => import('src/presentations/components/pages/actionDetails/ActionDetails'));
const ContractManager = lazy(() => import('src/containers/contractManager/ContractManager'));

export const routesConfig = createRoutesFromElements(
  <>
    <Route path='/login' element={<LoginPage />} />
    <Route path='/forbidden' element={<Forbidden />} />
    <Route
      element={
        <RequireAuth>
          <LayoutRoot>
            <LayoutMain />
          </LayoutRoot>
        </RequireAuth>
      }
    >
      <Route path='/' element={<LandingPage />} />
      {/* Protected routes with specific permissions */}
      <Route
        path='/dashboard'
        element={
          <RequirePermission permission={PermissionType.AUDIT}>
            <Dashboard />
          </RequirePermission>
        }
      />

      <Route
        path='/action-details/:id'
        element={
          <RequirePermission permission={PermissionType.AUDIT}>
            <ActionDetailsPage />
          </RequirePermission>
        }
      />

      <Route
        path='/performance'
        element={
          <RequirePermission permission={PermissionType.PERFORMANCE}>
            <PerformanceAnalysis />
          </RequirePermission>
        }
      />

      <Route
        path='/analytics'
        element={
          <RequirePermission permission={PermissionType.REPORT}>
            <Analytics />
          </RequirePermission>
        }
      />

      {/* Routes without specific permission requirements */}
      <Route path='/internal-dashboard' element={<InternalDashboardPage />} />
      <Route path='/ratecard-manager' element={<ContractManager />} />
      <Route path='/under-construction' element={<UnderConstructionPage />} />
      <Route path='/configured-rate-card/:shipmentInvoiceId' element={<PdfViewer />} />
      <Route path='/invoice-report/:invoiceId' element={<InvoiceReportViewer />} />
      <Route path='/model-configuration' element={<ModelConfiguration />} />
      <Route path='/settings' element={<SettingsPage />} />
      <Route path='/settings/legal-entities' element={<LegalEntitiesPage />} />
      <Route path='/settings/exchange-rate' element={<ExchangeRatePage />} />
      <Route path='/settings/invoice-charge' element={<InvoiceChargePage />} />
    </Route>
    <Route path='*' element={<NotFound />} />
  </>
);
