import { MRT_ColumnDef, MRT_Row, MRT_TableOptions, useMaterialReactTable, MRT_SortingState } from 'material-react-table';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useMemo, useState, useCallback, forwardRef, useImperativeHandle, ForwardRefRenderFunction } from 'react';
import { LegalEntityDto } from 'src/generated/services/TFinancialApi';
import { openToast, openToastError } from 'src/use-cases/toast/useToast';
import { useLegalEntityList, useUpsertLegalEntity } from 'src/use-cases/settings/useLegalEntities.ts';
import { CustomTable } from 'src/presentations/components/molecules/table/Table';
import TablePagination from 'src/presentations/components/molecules/table/Pagination';
import IconMerge from 'src/assets/images/icons/grid-9.svg';
import MergeLegalEntitiesModal from './modals/MergeLegalEntitiesModal';

interface LegalEntitiesTableProps {
  searchQuery?: string;
  selectedLegalEntityId?: number | null;
  setSelectedLegalEntityId?: (id: number | null) => void;
}

const LegalEntitiesTableComponent: ForwardRefRenderFunction<{ resetPage: () => void }, LegalEntitiesTableProps> = (props, ref) => {
  const { searchQuery, selectedLegalEntityId, setSelectedLegalEntityId } = props;

  const [validationErrors, setValidationErrors] = useState<Record<string, string | undefined>>({});
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  // Expose resetPage method to parent component
  useImperativeHandle(ref, () => ({
    resetPage: () => {
      setPagination((prev) => ({
        ...prev,
        pageIndex: 0,
      }));
    },
  }));
  // Default sort by ID in ascending order
  const [sorting, setSorting] = useState<MRT_SortingState>([
    {
      id: 'id',
      desc: false,
    },
  ]);
  const { mutateAsync: upsertLegalEntity } = useUpsertLegalEntity();

  // Fetch legal entities data
  const { data, isLoading } = useLegalEntityList({
    keySearch: searchQuery,
    page: pagination.pageIndex,
    size: pagination.pageSize,
    // Add sorting parameters if available
    sort: sorting.length > 0 ? `${sorting[0].id},${sorting[0].desc ? 'desc' : 'asc'}` : undefined,
  });

  const legalEntities = data?.data?.content || [];
  const legalEntitiesTotal = data?.data?.totalElements || 0;

  const columns = useMemo(
    (): MRT_ColumnDef<LegalEntityDto>[] => [
      {
        enableEditing: false,
        accessorKey: 'id',
        header: 'ID',
      },
      {
        accessorKey: 'name',
        header: 'Name',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              name: undefined,
            }),
        },
      },
      {
        accessorKey: 'code',
        header: 'Code',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.code,
          helperText: validationErrors?.code,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              code: undefined,
            }),
        },
      },
      {
        accessorKey: 'nationalCurrency',
        header: 'National Currency',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.nationalCurrency,
          helperText: validationErrors?.nationalCurrency,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              nationalCurrency: undefined,
            }),
        },
      },
      {
        accessorKey: 'isBilledEntity',
        header: 'Billed Entity',
        // Use accessorFn to convert boolean to 'Yes'/'No' for display
        accessorFn: (row) => (row.isBilledEntity ? 'Yes' : 'No'),
        editVariant: 'select',
        editSelectOptions: ['Yes', 'No'],
        muiEditTextFieldProps: {
          select: true,
          error: !!validationErrors?.isBilledEntity,
          helperText: validationErrors?.isBilledEntity,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              isBilledEntity: undefined,
            }),
        },
      },
      {
        accessorKey: 'parentId',
        header: 'Parent Legal Entity',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.parentId,
          helperText: validationErrors?.parentId,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              parentId: undefined,
            }),
        },
      },
    ],
    [validationErrors]
  );

  const onValidateCharge = useCallback((charge: Record<string, any>) => {
    const validationResult = {
      name: !charge.name || charge.name === '' ? 'Required' : '',
      code: !charge.code || charge.code === '' ? 'Required' : '',
      nationalCurrency: !charge.nationalCurrency || charge.nationalCurrency === '' ? 'Required' : '',
      isBilledEntity: !charge.isBilledEntity || charge.isBilledEntity === '' ? 'Required' : '',
      // parentId: !charge.parentId || charge.parentId === '' ? 'Required' : '',
    };

    return validationResult;
  }, []);

  // CREATE action
  const handleCreateLegalEntity: MRT_TableOptions<LegalEntityDto>['onCreatingRowSave'] = async ({ values, exitCreatingMode }) => {
    const newValidationErrors = onValidateCharge(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }

    try {
      const reqBody: LegalEntityDto = {
        name: values.name,
        code: values.code,
        nationalCurrency: values.nationalCurrency,
        isBilledEntity: values.isBilledEntity?.toLowerCase() === 'true',
        parentId: values.parentId,
      };
      await upsertLegalEntity(reqBody);
      openToast({
        toastType: 'success',
        title: 'Success',
        description: 'Legal entity created!',
      });
    } catch (error) {
      openToastError(new Error('Legal entity creation failed'));
    } finally {
      exitCreatingMode();
    }
  };

  // UPDATE action
  const handleUpdateLegalEntity: MRT_TableOptions<LegalEntityDto>['onEditingRowSave'] = async ({ row, values, exitEditingMode }) => {
    const newValidationErrors = onValidateCharge(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }

    try {
      const reqBody: LegalEntityDto = {
        id: row.original?.id,
        name: values.name,
        code: values.code,
        nationalCurrency: values.nationalCurrency,
        isBilledEntity: values.isBilledEntity?.toLowerCase() === 'true',
        parentId: values.parentId,
      };
      await upsertLegalEntity(reqBody);
      openToast({
        toastType: 'success',
        title: 'Success',
        description: 'Legal entity updated!',
      });
    } catch (error) {
      openToastError(new Error('Legal entity update failed'));
    } finally {
      exitEditingMode();
    }
  };

  // Merge action
  const [mergeModalOpen, setMergeModalOpen] = useState(false);
  const [selectedEntityToMerge, setSelectedEntityToMerge] = useState<LegalEntityDto | undefined>();

  const openMergeModal = (row: MRT_Row<LegalEntityDto>) => {
    setSelectedEntityToMerge(row.original);
    setMergeModalOpen(true);
  };

  const table = useMaterialReactTable({
    enableSorting: true,
    enableColumnFilters: false,
    enableGlobalFilter: false,
    enableMultiSort: false,
    enablePinning: false,
    enableRowDragging: false,
    enableColumnActions: false,
    enablePagination: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableStickyHeader: true,
    muiTableContainerProps: { sx: { height: '100%' } },
    muiTablePaperProps: { sx: { height: '100%' } },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: '#F9FAFB',
        '& .Mui-active': {
          color: '#00A651', // Green color for active sort
        },
      },
    },
    enableEditing: true,
    createDisplayMode: 'row',
    editDisplayMode: 'row',
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateLegalEntity,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleUpdateLegalEntity,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    positionActionsColumn: 'first',
    data: legalEntities as LegalEntityDto[],
    columns,
    state: {
      sorting,
      isLoading,
    },
    onSortingChange: setSorting,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title='Edit'>
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title='Delete'>
          <IconButton color='error' onClick={() => openMergeModal(row)}>
            <img src={IconMerge} alt='Merge' />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    muiTableBodyRowProps: ({ row }) => ({
      selected: selectedLegalEntityId && row.original.id === selectedLegalEntityId,
      onClick: () => {
        setSelectedLegalEntityId(row.original.id);
      },
      sx: {
        cursor: 'pointer',
      },
    }),
  });

  const params = useMemo(
    () => ({
      page: pagination.pageIndex + 1,
    }),
    [pagination.pageIndex]
  );

  const onChangePage = useCallback((page: number) => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: page - 1,
    }));
  }, []);

  // Calculate pagination display values
  const startItem = legalEntitiesTotal > 0 ? pagination.pageIndex * pagination.pageSize + 1 : 0;
  const endItem = Math.min((pagination.pageIndex + 1) * pagination.pageSize, legalEntitiesTotal);

  return (
    <div className='w-full'>
      <CustomTable table={table} tableContainerProps={{ sx: { height: '100%' } }} />
      <div className='p-16 bg-neutral-10 flex items-center justify-between border border-t-0 border-neutral-20'>
        <div>
          Showing {startItem}-{endItem} of {legalEntitiesTotal}
        </div>
        <TablePagination total={legalEntitiesTotal} onChangePage={onChangePage} params={params} pageSize={pagination.pageSize} />
      </div>

      {/* Merge Modal */}
      <MergeLegalEntitiesModal open={mergeModalOpen} onClose={() => setMergeModalOpen(false)} selectedEntity={selectedEntityToMerge} />
    </div>
  );
};

// Use explicit type annotation to ensure correct TypeScript exports
export const LegalEntitiesTable = forwardRef<{ resetPage: () => void }, LegalEntitiesTableProps>(LegalEntitiesTableComponent);
