import React from 'react';
import { LandingTopWidget } from '../../organisms/landing';
import { SettingsWrapper } from '../../organisms/settings/SettingsWrapper';
import { LegalEntitiesMain } from '../../organisms/settings/legalEntities/LegalEntitiesMain';

/**
 * presentations/components/pages/settings/LegalEntitiesPage.tsx
 */
const LegalEntitiesPage: React.FC = () => (
  <>
    <div className='h-full flex flex-col bg-white'>
      <LandingTopWidget title='Settings' hideSearchBar hideUploadButton />

      <SettingsWrapper>
        <LegalEntitiesMain />
      </SettingsWrapper>
    </div>
  </>
);

export default LegalEntitiesPage;
