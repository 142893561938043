import React, { useState, useEffect } from 'react';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from 'src/assets/images/icons/search-settings.svg';

// Styled MUI components
const StyledSearchInput = styled(InputBase)({
  flex: 1,
  fontSize: '16px',
  marginLeft: '16px',
  height: '42px',
  '& input': {
    padding: '8px 0',
    '&::placeholder': {
      color: '#9E9E9E',
      opacity: 1,
    },
  },
});

export interface SearchInputProps {
  placeholder?: string;
  onSearch?: (query: string) => void;
  initialValue?: string;
  className?: string;
  iconSrc?: string;
}

export const SearchInput: React.FC<SearchInputProps> = ({ placeholder = 'Search', onSearch, initialValue = '', className = '', iconSrc = SearchIcon }) => {
  const [searchQuery, setSearchQuery] = useState(initialValue);

  // Update search query if initialValue changes externally
  useEffect(() => {
    setSearchQuery(initialValue);
  }, [initialValue]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchValue = event.target.value;
    setSearchQuery(newSearchValue);

    // Automatically search as the user types
    if (onSearch) {
      onSearch(newSearchValue);
    }
  };

  const handleClearSearch = () => {
    setSearchQuery('');
    if (onSearch) {
      onSearch('');
    }
  };

  return (
    <div className={`flex items-center w-full h-[44px] bg-white rounded-[8px] border border-neutral-20 px-10 ${className}`}>
      <div className='flex items-center justify-center w-20'>
        <img src={iconSrc} alt='Search' className='w-20' />
      </div>
      <StyledSearchInput placeholder={placeholder} value={searchQuery} onChange={handleSearchChange} fullWidth />
      {searchQuery && (
        <IconButton aria-label='clear search' onClick={handleClearSearch} size='small' edge='end' sx={{ mr: 1 }}>
          <ClearIcon fontSize='small' />
        </IconButton>
      )}
    </div>
  );
};
