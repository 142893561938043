import React from 'react';
import clsx from 'clsx';

// Navigation item interface
export interface INavigationItem {
  id: any;
  title: string;
  code?: string;
  [key: string]: any; // Allow for additional properties
}

export interface NavigationSidebarProps {
  title?: string;
  items: INavigationItem[];
  onItemClick: (item: INavigationItem) => void;
  className?: string;
  selectedItemId?: any;
  maxHeight?: string;
  bgColor?: string;
  titleBgColor?: string;
}

export const NavigationSidebar: React.FC<NavigationSidebarProps> = ({
  title,
  items,
  onItemClick,
  className = '',
  selectedItemId,
  maxHeight = '700px',
  bgColor = '#13191C',
  titleBgColor = '#1C2429',
}) => (
  <div className={clsx('overflow-hidden w-[198px] rounded-bl-16', className)} style={{ backgroundColor: bgColor }}>
    <div className='flex flex-col'>
      {title && (
        <div className='flex items-center h-[4rem] px-20' style={{ backgroundColor: titleBgColor }}>
          <h3 className='flex-1 text-14 text-neutral-50 font-medium overflow-hidden'>{title}</h3>
        </div>
      )}
      <div className='flex-1 py-16 px-12 overflow-auto' style={{ maxHeight }}>
        <div className='flex flex-col gap-2'>
          {items.map((item) => (
            <div key={`menu-${item?.id}`} className='relative'>
              <button
                type='button'
                onClick={() => onItemClick(item)}
                className={clsx(
                  'px-10 py-8 w-full hover:bg-[#EBECF0] hover:bg-opacity-10 hover:text-white rounded-10 text-14 transition-all duration-300 font-medium text-left text-neutral-60 whitespace-nowrap uppercase',
                  item.id === selectedItemId && 'bg-[#EBECF0] bg-opacity-10 text-white'
                )}
              >
                {item?.title}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);
