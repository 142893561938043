import React, { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetAllServiceProviders } from 'src/use-cases/serviceProvider/useServiceProvider';
import { beautifyEnum } from 'src/utils/utility';

import { INavigationItem } from 'src/presentations/components/molecules/navigationSidebar/NavigationSidebar';

// Extending INavigationItem interface to ensure compatibility
export interface ServiceProviderItem extends INavigationItem {
  code: string; // Make code required for our internal use
}

export interface ServiceProviderSelectorProps<T extends string | number = string | number> {
  /**
   * Current service provider identifier (can be ID or code based on idType)
   */
  currentValue: T;

  /**
   * Callback when service provider is selected
   */
  onSelect: (value: T) => void;

  /**
   * The URL parameter name to read from for initial selection
   * @default 'service-provider'
   */
  urlParamName?: string;

  /**
   * Whether to use ID or code as the identifier
   * @default 'code'
   */
  idType?: 'id' | 'code';

  /**
   * Render function to display the service provider selection UI
   */
  renderUI: (props: {
    providers: ServiceProviderItem[];
    selectedId: string | number | null;
    onItemClick: (item: ServiceProviderItem) => void;
  }) => React.ReactNode;
}

/**
 * A reusable component for service provider selection logic
 */
export const ServiceProviderSelector = <T extends string | number = string>({
  currentValue,
  onSelect,
  urlParamName = 'service-provider',
  idType = 'code',
  renderUI,
}: ServiceProviderSelectorProps<T>) => {
  const { data } = useGetAllServiceProviders();
  const [searchParams] = useSearchParams();

  // Format provider data into a consistent format
  const providers: ServiceProviderItem[] = useMemo(() => {
    if (data?.data && data?.data?.length > 0) {
      return data.data
        .filter((value, index, array) => array.findIndex((item) => item?.id === value?.id) === index)
        .sort((a, b) => {
          if (a?.code > b.code) return 1;
          if (b.code > a?.code) return -1;
          return 0;
        })
        .map((item) => ({
          id: item?.id,
          title: beautifyEnum(item?.code),
          code: item?.code,
        }));
    }
    return [];
  }, [data]);

  // Handle initial selection and URL params
  useEffect(() => {
    if (providers?.length > 0 && data?.data?.length > 0) {
      const serviceProviderParam = searchParams.get(urlParamName);

      if (serviceProviderParam) {
        // Try to find provider by code in the original data
        const providerByCode = data.data.find((provider) => provider?.code?.toLowerCase() === serviceProviderParam.toLowerCase());

        if (providerByCode) {
          onSelect(idType === 'code' ? (providerByCode.code as T) : (providerByCode.id as T));
        } else {
          // If not found, use first provider
          onSelect(idType === 'code' ? (providers[0]?.code as T) : (providers[0]?.id as T));
        }
      } else if (!currentValue && providers[0]) {
        // If no current value and we have providers, use first provider
        onSelect(idType === 'code' ? providers[0]?.code : providers[0]?.id);
      }
    }
  }, [providers, data, onSelect, searchParams, urlParamName, currentValue, idType]);

  // Calculate selected ID for UI
  const selectedId = useMemo(() => {
    if (data?.data && currentValue) {
      if (idType === 'code') {
        const provider = data.data.find((p) => p.code === currentValue);
        return provider?.id || null;
      }
      return currentValue;
    }
    return null;
  }, [data, currentValue, idType]);

  // Handle item click
  const handleItemClick = (item: ServiceProviderItem) => {
    onSelect(idType === 'code' ? (item.code as T) : (item.id as T));
  };

  return <>{renderUI({ providers, selectedId, onItemClick: handleItemClick })}</>;
};
