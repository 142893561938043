import React, { useState, useMemo, forwardRef, useImperativeHandle, ForwardRefRenderFunction } from 'react';
import { MRT_ColumnDef, useMaterialReactTable } from 'material-react-table';
import { CustomTable } from 'src/presentations/components/molecules/table/Table';
import TablePagination from 'src/presentations/components/molecules/table/Pagination';
import { LegalEntityDto } from 'src/generated/services/TFinancialApi';
import { useLegalEntityList } from 'src/use-cases/settings/useLegalEntities';

interface SelectableTableProps {
  selectedIds?: number[];
  onSelectionChange?: (selectedId: number | null) => void;
  searchQuery?: string;
}

const SelectableTableComponent: ForwardRefRenderFunction<{ resetPage: () => void }, SelectableTableProps> = (
  { selectedIds = [], onSelectionChange, searchQuery = '' },
  ref
) => {
  // Pagination state
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  // Expose resetPage method to parent component
  useImperativeHandle(ref, () => ({
    resetPage: () => {
      setPagination((prev) => ({
        ...prev,
        pageIndex: 0,
      }));
    },
  }));

  // Fetch legal entities data
  const { data: legalEntitiesData, isLoading } = useLegalEntityList({
    keySearch: searchQuery,
    page: pagination.pageIndex,
    size: pagination.pageSize,
  });

  // Extract entities from the response
  const entities = legalEntitiesData?.data?.content || [];
  const totalItems = legalEntitiesData?.data?.totalElements || 0;

  // Define columns for the table
  const columns = useMemo<MRT_ColumnDef<LegalEntityDto>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 60,
      },
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'code',
        header: 'Code',
      },
      {
        accessorKey: 'nationalCurrency',
        header: 'Currency',
      },
      {
        accessorKey: 'isBilledEntity',
        header: 'Billed Entity',
        accessorFn: (row) => (row.isBilledEntity ? 'Yes' : 'No'),
      },
      {
        accessorKey: 'parentId',
        header: 'Parent Legal Entity',
      },
    ],
    []
  );

  // We're now using Material React Table's built-in selection handling

  // Configure the table
  const table = useMaterialReactTable({
    enableSorting: true,
    enablePinning: false,
    enableRowDragging: false,
    enableColumnActions: false,
    enablePagination: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableStickyHeader: true,
    muiTableContainerProps: { sx: { height: '100%' } },
    muiTablePaperProps: { sx: { height: '100%' } },
    enableEditing: false,
    // Enable row selection features
    enableRowSelection: true,
    enableMultiRowSelection: false, // Only allow single selection
    enableSelectAll: false,
    enableSubRowSelection: false,
    // Position the selection checkbox column first
    positionActionsColumn: 'last',
    // Use the MRT selection column def
    displayColumnDefOptions: {
      'mrt-row-select': {
        header: 'Select',
        size: 60,
        enableColumnActions: false,
      },
    },
    data: entities,
    columns,
    state: {
      rowSelection: selectedIds.reduce(
        (acc, id) => {
          acc[id.toString()] = true;
          return acc;
        },
        {} as Record<string, boolean>
      ),
    },
    onRowSelectionChange: (updater) => {
      if (!onSelectionChange) return;

      if (typeof updater === 'function') {
        // Pass the current selection to the updater function
        const newSelection = updater({});

        const newItems = Object.entries(newSelection)
          .filter(([, selected]) => selected)
          .map(([id]) => parseInt(id, 10));

        // Since we only allow single selection, pass the first selected ID or null
        onSelectionChange(newItems.length > 0 ? newItems[0] : null);
      }
    },
    getRowId: (row) => row.id?.toString() || '',
    muiTableBodyRowProps: () => ({
      // Don't use onClick for the entire row as it conflicts with checkbox clicks
      sx: {
        cursor: 'pointer',
        '&.Mui-selected': {
          backgroundColor: 'rgba(0, 166, 81, 0.08)',
        },
        '&.Mui-selected:hover': {
          backgroundColor: 'rgba(0, 166, 81, 0.12)',
        },
      },
    }),
    // Configure the checkbox cell
    muiSelectCheckboxProps: {
      sx: {
        color: '#00A651',
        '&.Mui-checked': {
          color: '#00A651',
        },
      },
    },
  });

  const handleChangePage = (page: number) => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: page - 1,
    }));
  };

  // Calculate pagination display values
  const startItem = totalItems > 0 ? pagination.pageIndex * pagination.pageSize + 1 : 0;
  const endItem = Math.min((pagination.pageIndex + 1) * pagination.pageSize, totalItems);

  return isLoading ? (
    <div className='flex justify-center items-center h-[calc(100vh_-_266px)]'>
      <p>Loading...</p>
    </div>
  ) : (
    <div className='pb-24 flex flex-col h-[calc(100vh_-_266px)]'>
      <CustomTable table={table} tableContainerProps={{ sx: { height: '90%' } }} />
      <div className='p-16 bg-neutral-10 flex items-center justify-between border border-t-0 border-neutral-20'>
        <div>
          Showing {startItem}-{endItem} of {totalItems}
        </div>
        <TablePagination total={totalItems} onChangePage={handleChangePage} params={{ page: pagination.pageIndex + 1 }} pageSize={pagination.pageSize} />
      </div>
    </div>
  );
};

const SelectableTable = forwardRef(SelectableTableComponent);

export { SelectableTable };
