import React from 'react';
import { LandingTopWidget } from '../../organisms/landing';
import { SettingsWrapper } from '../../organisms/settings/SettingsWrapper';
import { ExchangeRateMain } from '../../organisms/settings/exchangeRate/ExchangeRateMain';

/**
 * presentations/components/pages/settings/ExchangeRatePage.tsx
 */
const ExchangeRatePage: React.FC = () => (
  <>
    <div className='h-full flex flex-col bg-white'>
      <LandingTopWidget title='Settings' hideSearchBar hideUploadButton />

      <SettingsWrapper>
        <ExchangeRateMain />
      </SettingsWrapper>
    </div>
  </>
);

export default ExchangeRatePage;
