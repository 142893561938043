import React from 'react';
import { SettingsMain } from '../SettingsMain';

export const ProfileMain: React.FC = () => {
  // Example of a save button that could be passed as topActions
  const saveButton = <button className='px-16 py-8 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors'>Save Update</button>;

  return (
    <SettingsMain title='User Profile' topActions={saveButton}>
      <div />
    </SettingsMain>
  );
};
