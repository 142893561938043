import React from 'react';
import { NavigationSidebar, INavigationItem } from 'src/presentations/components/molecules/navigationSidebar/NavigationSidebar';
import { ServiceProviderSelector, ServiceProviderItem } from 'src/presentations/components/molecules/serviceProvider/ServiceProviderSelector';

// Service Provider component for Invoice Charge
export const InvoiceChargeServiceProvider: React.FC<{
  serviceProviderCode: string;
  setServiceProviderCode: (value: string) => void;
}> = ({ serviceProviderCode, setServiceProviderCode }) => (
  <ServiceProviderSelector<string>
    currentValue={serviceProviderCode}
    onSelect={setServiceProviderCode}
    idType='code'
    renderUI={({ providers, selectedId, onItemClick }) => (
      <NavigationSidebar
        title='SERVICE PROVIDER'
        items={providers as INavigationItem[]}
        selectedItemId={selectedId}
        onItemClick={(item: INavigationItem) => {
          // Safe cast since we know ServiceProviderItem extends INavigationItem
          onItemClick(item as ServiceProviderItem);
        }}
      />
    )}
  />
);
