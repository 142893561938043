import { useParams } from 'react-router-dom';
import { useGetInvoiceReport, useInvoiceSummary } from 'src/use-cases/invoice/useInvoices';
import { PageLoader } from 'src/presentations/components/molecules/pageLoader/PageLoader';
import PdfViewerComponent from 'src/presentations/components/molecules/pdfViewer/PdfViewerComponent';
import PdfErrorComponent from 'src/presentations/components/molecules/pdfViewer/PdfErrorComponent';
import { usePdfProcessor } from 'src/hooks/usePdfProcessor';

const InvoiceReportViewer = () => {
  const { invoiceId } = useParams();
  const { data: invoiceSummary, isLoading: isLoadingSummary } = useInvoiceSummary(invoiceId);
  const { refetch: refetchInvoiceReport } = useGetInvoiceReport(invoiceId);

  const { pdfUrl, error, isLoading } = usePdfProcessor({
    id: invoiceId,
    idName: 'Invoice ID',
    fetchFunction: refetchInvoiceReport,
    errorPrefix: 'invoice report',
  });

  if (error) {
    return <PdfErrorComponent errorMessage={error} />;
  }

  if (isLoading || isLoadingSummary || !pdfUrl) {
    return <PageLoader />;
  }

  return (
    <PdfViewerComponent
      pdfUrl={pdfUrl}
      onError={(errorMessage) => console.error(errorMessage)}
      downloadFileName={`invoice-report-${invoiceSummary?.invoiceNumber}`}
    />
  );
};

export default InvoiceReportViewer;
