import { UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ResponseDtoBoolean, ResponseDtoListInvoiceChargeDomainMappingDto, UpsertInvoiceChargeDomainMappingReq } from 'src/generated/services/TFinancialApi';
import { tFinancialService } from 'src/infra/services/tFinancialService';

/**
 * Interface for pagination parameters
 */
export interface PaginationParams {
  page: number;
  pageSize: number;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
}

/**
 * Interface for pagination metadata
 */
export interface PaginationMeta {
  totalItems: number;
  totalPages: number;
  currentPage: number;
  pageSize: number;
}

/**
 * Extended response type that includes pagination metadata
 */
export interface ExtendedResponseDtoListInvoiceChargeDomainMappingDto extends ResponseDtoListInvoiceChargeDomainMappingDto {
  meta?: PaginationMeta;
}

/**
 * Hook to fetch invoice charge domain mappings by service provider code with pagination and sorting
 * @param serviceProviderCode The code of the service provider
 * @param paginationParams Pagination and sorting parameters
 * @returns Query result with invoice charge domain mappings data
 */
export const useInvoiceChargeDomainMappings = (
  serviceProviderCode?: string,
  paginationParams?: PaginationParams
): UseQueryResult<ExtendedResponseDtoListInvoiceChargeDomainMappingDto> =>
  useQuery(
    ['invoiceChargeDomainMappings', serviceProviderCode, paginationParams],
    async () => {
      // Fetch all data from the API
      const response = await tFinancialService.api.getInvoiceChargeDomainMappings({
        serviceProviderCode,
        paginationRequest: {
          page: 0, // Get all data from first page
          size: 1000, // Set a large size to get all data
          sortingColum: paginationParams?.sortBy,
          sortingDirection: paginationParams?.sortOrder === 'desc' ? 'DESC' : 'ASC',
        },
      });

      const { data } = response;

      // If no pagination params or no data, return the original response
      if (!paginationParams || !data?.data || !data.data.content || data.data.content.length === 0) {
        return data;
      }

      // Apply sorting if specified
      const sortedData = [...(data.data.content || [])];
      if (paginationParams.sortBy) {
        sortedData.sort((a, b) => {
          const aValue = a[paginationParams.sortBy as keyof typeof a];
          const bValue = b[paginationParams.sortBy as keyof typeof b];

          // Handle different data types
          if (typeof aValue === 'string' && typeof bValue === 'string') {
            return paginationParams.sortOrder === 'desc' ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue);
          }

          // For numbers or other types
          if (aValue < bValue) return paginationParams.sortOrder === 'desc' ? 1 : -1;
          if (aValue > bValue) return paginationParams.sortOrder === 'desc' ? -1 : 1;
          return 0;
        });
      }

      // Apply pagination
      const startIndex = (paginationParams.page - 1) * paginationParams.pageSize;
      const endIndex = startIndex + paginationParams.pageSize;
      const paginatedData = sortedData.slice(startIndex, endIndex);

      // Return the paginated and sorted data
      return {
        ...data,
        data: paginatedData,
        // Add metadata for client-side pagination
        meta: {
          totalItems: data.data.content?.length || 0,
          totalPages: Math.ceil((data.data.content?.length || 0) / paginationParams.pageSize),
          currentPage: paginationParams.page,
          pageSize: paginationParams.pageSize,
        },
      };
    },
    {
      enabled: !!serviceProviderCode, // Only run the query if serviceProviderCode is provided
    }
  );

/**
 * Hook to create or update invoice charge domain mappings
 * @returns Mutation function for upserting invoice charge domain mappings
 */
export const useUpsertInvoiceChargeDomainMapping = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (mapping: UpsertInvoiceChargeDomainMappingReq | UpsertInvoiceChargeDomainMappingReq[]): Promise<ResponseDtoListInvoiceChargeDomainMappingDto> => {
      // Convert single mapping to array if needed
      const mappings = Array.isArray(mapping) ? mapping : [mapping];
      const response = await tFinancialService.api.upsertInvoiceChargeDomainMapping(mappings);
      return response.data;
    },
    {
      onSuccess: (_, variables) => {
        // Determine the service provider ID from the variables
        const serviceProviderId = Array.isArray(variables) ? variables[0]?.serviceProviderId : variables.serviceProviderId;

        // Invalidate relevant queries to refetch data
        if (serviceProviderId) {
          queryClient.invalidateQueries(['invoiceChargeDomainMappings']);
        } else {
          // If no specific service provider ID, invalidate all invoice charge domain mapping queries
          queryClient.invalidateQueries({
            predicate: (query) => {
              const queryKey = query.queryKey[0];
              return queryKey === 'invoiceChargeDomainMappings';
            },
          });
        }
      },
    }
  );
};

/**
 * Hook to delete invoice charge domain mappings by IDs
 * @returns Mutation function for deleting invoice charge domain mappings
 */
export const useDeleteInvoiceChargeDomainMappings = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: { ids: number[]; serviceProviderCode?: string }): Promise<ResponseDtoBoolean> => {
      const response = await tFinancialService.api.deleteInvoiceChargeDomainMappingByIds(params.ids);
      return response.data;
    },
    {
      onSuccess: (_, variables) => {
        // Invalidate relevant queries to refetch data
        if (variables.serviceProviderCode) {
          queryClient.invalidateQueries(['invoiceChargeDomainMappings', variables.serviceProviderCode]);
        } else {
          // If no specific service provider code, invalidate all invoice charge domain mapping queries
          queryClient.invalidateQueries({
            predicate: (query) => {
              const queryKey = query.queryKey[0];
              return queryKey === 'invoiceChargeDomainMappings';
            },
          });
        }
      },
    }
  );
};
