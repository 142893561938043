import { FC, useState, useRef } from 'react';
import { Button, CircularProgress, IconButton, InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from 'src/assets/images/icons/search-settings.svg';
import { useSnackbar } from 'notistack';

import { Modal } from 'src/presentations/components/molecules/modal/Modal';
import { LegalEntityDto } from 'src/generated/services/TFinancialApi';
import { beautifyEnum } from 'src/utils/utility';
import { useMigrateLegalEntity } from 'src/use-cases/settings/useLegalEntities';
import { SelectableTable } from './SelectableTable';

interface MergeLegalEntitiesModalProps {
  open: boolean;
  onClose: () => void;
  selectedEntity?: LegalEntityDto;
}

const StyledModal = styled(Modal)({
  '& .MuiDialog-paper': {
    maxWidth: '1000px',
    height: 'calc(100% - 64px)',
    borderRadius: '16px',

    '& .MuiDialogContent-root': {
      padding: '0',
      backgroundColor: '#ffffff',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
  },
});

// Styled search input similar to the main page search
const SearchInput = styled(InputBase)({
  flex: 1,
  fontSize: '16px',
  marginLeft: '16px',
  height: '42px',
  '& input': {
    padding: '8px 0',
    '&::placeholder': {
      color: '#9E9E9E',
      opacity: 1,
    },
  },
});

const MergeLegalEntitiesModal: FC<MergeLegalEntitiesModalProps> = ({ open, onClose, selectedEntity }) => {
  const [selectedEntityId, setSelectedEntityId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState('');

  // Reference to the table component for resetting pagination when searching
  const tableRef = useRef<{ resetPage: () => void }>({ resetPage: () => {} });

  // Get the migration mutation and notistack
  const { mutate: migrateLegalEntity } = useMigrateLegalEntity();
  const { enqueueSnackbar } = useSnackbar();

  // Handle search input changes - automatically triggers search as user types
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchValue = event.target.value;
    setSearchQuery(newSearchValue);
    // Reset pagination to first page when searching
    tableRef.current.resetPage();
  };

  // Handle clearing the search
  const handleClearSearch = () => {
    setSearchQuery('');
    // Also reset pagination when clearing search
    tableRef.current.resetPage();
  };

  const handleMerge = () => {
    if (!selectedEntity || !selectedEntityId) {
      enqueueSnackbar('Please select an entity to merge', {
        variant: 'error',
        invoiceId: '',
        workflowStatus: '',
        invoiceNumber: '',
      });
      return;
    }

    // Set loading state to true
    setIsLoading(true);

    // Migrate the selected entity to the main selected entity
    migrateLegalEntity(
      {
        oldLegalEntityId: selectedEntityId,
        newLegalEntityId: selectedEntity.id as number,
      },
      {
        onSuccess: () => {
          // Migration successful
          enqueueSnackbar('Entity merged successfully', {
            variant: 'success',
            invoiceId: '',
            workflowStatus: '',
            invoiceNumber: '',
          });
          setIsLoading(false);
          onClose();
        },
        onError: () => {
          // Migration failed
          enqueueSnackbar('Failed to merge entity', {
            variant: 'error',
            invoiceId: '',
            workflowStatus: '',
            invoiceNumber: '',
          });
          setIsLoading(false);
        },
      }
    );
  };

  return (
    <StyledModal open={open} maxWidth='md' fullWidth>
      <>
        <div className='p-24'>
          {/* Header with title and search in the same row */}
          <div className='flex justify-between items-center mb-6'>
            <div>
              {selectedEntity && (
                <div className='flex items-center gap-10 mb-1'>
                  <h2 className='text-14 font-bold text-primary'>{beautifyEnum(selectedEntity.name)}</h2>
                  <div className='w-1 h-14 bg-[#D9D9D9]' />
                  <div className='text-primary text-14 font-bold'>{selectedEntity.id}</div>
                  <div className='w-1 h-14 bg-[#D9D9D9]' />
                  <div className='text-primary text-14 font-bold'>{selectedEntity.code}</div>
                </div>
              )}
              <h3 className='text-24 font-bold mt-0'>Merge Billed Entities Into</h3>
            </div>
            {/* Search input */}
            <div className='flex items-center w-[320px]'>
              <div className='flex-1 flex items-center w-full h-[44px] bg-white rounded-[8px] border border-neutral-20 px-10'>
                <div className='flex items-center justify-center w-20'>
                  <img src={SearchIcon} alt='Search' className='w-20' />
                </div>
                <SearchInput placeholder='Search by name' value={searchQuery} onChange={handleSearchChange} fullWidth />
                {searchQuery && (
                  <IconButton aria-label='clear search' onClick={handleClearSearch} size='small' edge='end' sx={{ mr: 1 }}>
                    <ClearIcon fontSize='small' />
                  </IconButton>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-col'>
          <SelectableTable
            ref={tableRef}
            selectedIds={selectedEntityId ? [selectedEntityId] : []}
            onSelectionChange={(id) => setSelectedEntityId(id)}
            searchQuery={searchQuery}
          />

          {/* Action buttons */}
          <div className='border-t border-neutral-20 bg-neutral-05 p-24 rounded-b-16 flex items-center justify-between'>
            <Button
              variant='outlined'
              onClick={onClose}
              disabled={isLoading}
              sx={{
                borderColor: '#E5E7EB',
                color: '#000',
                '&:hover': {
                  borderColor: '#D1D5DB',
                  backgroundColor: '#F9FAFB',
                },
                '&.Mui-disabled': {
                  borderColor: '#E5E7EB',
                  color: '#9CA3AF',
                },
              }}
            >
              Close
            </Button>
            <Button
              variant='contained'
              onClick={handleMerge}
              disabled={!selectedEntityId || isLoading}
              sx={{
                backgroundColor: '#00A651',
                '&:hover': {
                  backgroundColor: '#009048',
                },
                '&.Mui-disabled': {
                  backgroundColor: '#E5E7EB',
                  color: '#9CA3AF',
                },
              }}
            >
              {isLoading ? (
                <>
                  <CircularProgress size={20} color='inherit' sx={{ mr: 1 }} />
                  Merging...
                </>
              ) : (
                'Merge'
              )}
            </Button>
          </div>
        </div>
      </>
    </StyledModal>
  );
};

export default MergeLegalEntitiesModal;
