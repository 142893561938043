import { UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  LegalEntityAddressDto,
  LegalEntityDto,
  ResponseDtoBoolean,
  ResponseDtoLegalEntityAddressDto,
  ResponseDtoLegalEntityDto,
  ResponseDtoLegalEntityNameAliasDto,
  ResponseDtoListLegalEntityDto,
  ResponseDtoPageLegalEntityDto,
  ResponseDtoString,
  UpsertLegalEntityAliasRequest,
} from 'src/generated/services/TFinancialApi';
import { tFinancialService } from 'src/infra/services/tFinancialService';

// Interface for pagination and search parameters
interface LegalEntityListParams {
  keySearch?: string;
  page: number;
  size: number;
  sort?: string; // Format: 'field,direction' (e.g., 'name,asc')
}

/**
 * Hook to fetch a paginated list of legal entities with optional search
 * @param params Pagination and search parameters
 * @returns Query result with paginated legal entities data
 */
export const useLegalEntityList = (params: LegalEntityListParams): UseQueryResult<ResponseDtoPageLegalEntityDto> =>
  useQuery(
    ['legalEntities', params],
    async () => {
      // Create query parameters object
      const queryParams: any = {
        keySearch: params.keySearch,
        page: params.page,
        size: params.size,
      };

      // Add sort parameter if provided
      if (params.sort) {
        queryParams.sort = params.sort;
      }

      const response = await tFinancialService.api.getAllLegalEntities(queryParams);
      return response.data;
    },
    {
      keepPreviousData: true,
    }
  );

/**
 * Hook to fetch a specific legal entity by ID or code
 * @param id Optional legal entity ID
 * @param code Optional legal entity code
 * @returns Query result with legal entity data
 */
export const useLegalEntityDetail = (id?: number, code?: string): UseQueryResult<ResponseDtoLegalEntityDto> =>
  useQuery(
    ['legalEntity', { id, code }],
    async () => {
      const response = await tFinancialService.api.getLegalEntityByIdOrCode({
        id,
        code,
      });
      return response.data;
    },
    {
      enabled: !!(id || code), // Only run the query if either id or code is provided
    }
  );

/**
 * Hook to create or update legal entities
 * @returns Mutation function for upserting legal entities
 */
export const useUpsertLegalEntity = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (legalEntity: LegalEntityDto | LegalEntityDto[]): Promise<ResponseDtoListLegalEntityDto> => {
      // Convert single entity to array if needed
      const entities = Array.isArray(legalEntity) ? legalEntity : [legalEntity];
      const response = await tFinancialService.api.upsertLegalEntities(entities);
      return response.data;
    },
    {
      onSuccess: () => {
        // Invalidate relevant queries to refetch data
        queryClient.invalidateQueries(['legalEntities']);
        queryClient.invalidateQueries({
          predicate: (query) => {
            const queryKey = query.queryKey[0];
            return queryKey === 'legalEntity';
          },
        });
      },
    }
  );
};

/**
 * Hook to create or update a legal entity address
 * @returns Mutation function for upserting a legal entity address
 */
export const useUpsertLegalEntityAddress = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (address: LegalEntityAddressDto): Promise<ResponseDtoLegalEntityAddressDto> => {
      const response = await tFinancialService.api.upsertLegalEntityAddress(address);
      return response.data;
    },
    {
      onSuccess: (_, variables) => {
        // Invalidate relevant queries to refetch data
        if (variables.legalEntityId) {
          queryClient.invalidateQueries(['legalEntity', { id: variables.legalEntityId }]);
        }
      },
    }
  );
};

/**
 * Hook to delete legal entity addresses
 * @returns Mutation function for deleting legal entity addresses
 */
export const useDeleteLegalEntityAddresses = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (addressIds: number[]): Promise<ResponseDtoString> => {
      const response = await tFinancialService.api.deleteLegalEntityAddresses(addressIds);
      return response.data;
    },
    {
      onSuccess: () => {
        // Since we don't know which legal entity these addresses belong to,
        // we invalidate all legal entity queries
        queryClient.invalidateQueries(['legalEntity']);
      },
    }
  );
};

/**
 * Hook to create or update a legal entity alias
 * @returns Mutation function for upserting a legal entity alias
 */
export const useUpsertLegalEntityAlias = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (alias: UpsertLegalEntityAliasRequest): Promise<ResponseDtoLegalEntityNameAliasDto> => {
      const response = await tFinancialService.api.upsertLegalEntityAlias(alias);
      return response.data;
    },
    {
      onSuccess: (_, variables) => {
        // Invalidate relevant queries to refetch data
        queryClient.invalidateQueries(['legalEntity', { id: variables.legalEntityId }]);
      },
    }
  );
};

/**
 * Hook to delete a legal entity alias
 * @returns Mutation function for deleting a legal entity alias
 */
export const useDeleteLegalEntityAlias = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (aliasId: number): Promise<ResponseDtoBoolean> => {
      const response = await tFinancialService.api.deleteLegalEntityAlias(aliasId);
      return response.data;
    },
    {
      onSuccess: () => {
        // Since we don't know which legal entity this alias belongs to,
        // we invalidate all legal entity queries
        queryClient.invalidateQueries(['legalEntity']);
      },
    }
  );
};

/**
 * Hook to migrate a legal entity to another one (merge legal entities)
 * @returns Mutation function for migrating legal entities
 */
export const useMigrateLegalEntity = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ oldLegalEntityId, newLegalEntityId }: { oldLegalEntityId: number; newLegalEntityId: number }): Promise<ResponseDtoString> => {
      const response = await tFinancialService.api.migrateALegalEntityToAnotherOne({
        oldLegalEntityId,
        newLegalEntityId,
      });
      return response.data;
    },
    {
      onSuccess: () => {
        // Invalidate relevant queries after successful migration
        queryClient.invalidateQueries(['legalEntities']);
        queryClient.invalidateQueries(['legalEntity']);
      },
    }
  );
};
